import React from "react";
import styled from "styled-components";


const AuthenticationContainer = ({ color, img, slogan, children }) => {
  return (
    <Container color={color}>
      <div className="bg" />
      <div className="wrp wrp-img">
        <h2>{slogan}</h2>
        <img src={img} alt="car" className="car" />
      </div>
      <div className="wrp wrp-form">{children}</div>
    </Container>
  );
};

export default AuthenticationContainer;

const Container = styled.div`
  display: grid;
  grid-template-columns: 66% 33%;
  min-height: 100vh;
  .wrp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
    h2 {
      align-self: flex-start;
      font-size: 40px;
      font-weight: 500;
      color: #fff;
    }
  }
  .wrp-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 2em 0 auto;
    z-index: 1;
  }
  .bg {
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 65vh;
    //z-index: -1;
  }
  .car {
    margin-top: 2em;
    max-width: 900px;
    width: 100%;
  }
  @media only screen and (max-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
    .wrp-img {
      display: none;
    }
    .wrp-form {
      margin: 0 0.5em;
    }
  }
`;
