import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import MesVoitures from "./pages/MesVoitures";
import EspaceFidelite from "./pages/EspaceFidelite";
import Contact from "./pages/Contact";
import Parametres from "./pages/Parametres";
import Login from "./pages/Login";
import ActiverCompte from "./pages/ActiverCompte";
import ActiverCompteChoix from "./pages/ActiverCompteChoix";
import CarChoice from "./pages/CarChoice";
import VehiculeDetails from "./pages/VehiculeDetails";
import HistoriqueDocuments from "./pages/HistoriqueDocuments";
import HistoriqueRdv from "./pages/HistoriqueRdv";
import Forfait from "./pages/Forfait";
import ResetPasswordNew from "./pages/ResetPasswordNew";
import ResetPassword from "./pages/ResetPassword";
import NewPassword from "./pages/ActiverCompteNewPassword";
import Rv from "./pages/Rv";
import EditRv from "./pages/EditRv";
import Complaints from "./pages/Complaints";
import AddComplaint from "./pages/AddComplaint";
import Notifications from "./pages/Notifications";
import Archive from "./pages/Archive";
import Historique from "./pages/Historique";
import AddArchive from "./pages/AddArchive";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider } from "./contexts/AuthContext";

const App = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <Switch>
            <Route
              exact
              path={[
                "/password/reset/confirm",
                "/password/reset/confirm/:token",
              ]}
              component={ResetPasswordNew}
            />
            <Route exact path="/login" component={Login} />
            <Route exact path={["/", "/dashboard"]} component={Dashboard} />
            <Route exact path="/car-choice" component={CarChoice} />
            <Route exact path="/mes-voitures" component={MesVoitures} />
            <Route
              exact
              path="/vehicule-details/:uid"
              component={VehiculeDetails}
            />
            <Route exact path="/password/reset" component={ResetPassword} />
            <Route exact path="/activer-compte" component={ActiverCompte} />
            <Route exact path="/activer-choix" component={ActiverCompteChoix} />
            <Route exact path="/ajouter-mdp" component={NewPassword} />
            <Route exact path="/rendez-vous" component={HistoriqueRdv} />
            <Route exact path="/documents" component={HistoriqueDocuments} />
            {/* <Route exact path="/forfait" component={Forfait} /> */}
            <Route exact path="/espace-fidelite" component={EspaceFidelite} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/parametres" component={Parametres} />
            <Route exact path="/rv" component={Rv} />
            <Route exact path="/edit-rv" component={EditRv} />
            <Route exact path="/complaints" component={Complaints} />
            <Route exact path="/add-complaints" component={AddComplaint} />
            <Route exact path="/archive" component={Archive} />
            <Route exact path="/add-archive" component={AddArchive} />
          </Switch>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
