import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/ford-logo.png";
import { ReactComponent as Notification } from "../assets/notification.svg";

const Menu = ({ menuIsActive, setMenuIsActive, color, logo }) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  return (
    <Container menuIsActive={menuIsActive} color={color} className="side-menu">
      <div className="logo-wrapper">
        <NavLink
          to="/"
          onClick={() => setMenuIsActive(!menuIsActive)}
          style={{ background: "#fff", borderRadius: "7px", padding: "12px" }}
        >
          <img
            src={REACT_APP_BACKEND_URL + logo || Logo}
            alt="logo"
            className="logo"
          />
        </NavLink>
      </div>
      <div className="dash-links">
        <NavLink
          exact
          activeClassName="active-link"
          className="dash-link"
          to="/"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
          </svg>
          <span>Dashboard</span>
        </NavLink>
        {/* <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/forfait"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M13 17h4v1h-4v-1zm0-1h4v-1h-4v1zm9-14v22h-20v-22h3c1.23 0 2.181-1.084 3-2h8c.82.916 1.771 2 3 2h3zm-11 1c0 .552.448 1 1 1 .553 0 1-.448 1-1s-.447-1-1-1c-.552 0-1 .448-1 1zm9 1h-4l-2 2h-3.897l-2.103-2h-4v18h16v-18zm-7 9h4v-1h-4v1zm0-2h4v-1h-4v1zm-6.5.077l.386-.355c.449.218.735.383 1.241.745.952-1.081 1.58-1.627 2.748-2.355l.125.288c-.963.841-1.669 1.777-2.686 3.6-.626-.738-1.044-1.208-1.814-1.923zm.098 5l.386-.355c.449.218.735.383 1.241.745.952-1.081 1.58-1.627 2.748-2.355l.125.289c-.963.841-1.669 1.777-2.686 3.6-.627-.739-1.045-1.209-1.814-1.924z" />
          </svg>
          <span>Accessoires, pièces</span>
        </NavLink> */}
        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/rendez-vous"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm1 11.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z" />
          </svg>
          <span>Rendez Vous</span>
        </NavLink>
        {/*
          
        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/documents"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M6 12h10v1h-10v-1zm7.816-3h-7.816v1h9.047c-.45-.283-.863-.618-1.231-1zm-7.816-2h6.5c-.134-.32-.237-.656-.319-1h-6.181v1zm13 3.975v2.568c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-20h9.5c.312-.749.763-1.424 1.316-2h-12.816v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-3.886c-.623.26-1.297.421-2 .475zm4-6.475c0 2.485-2.015 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2.156-.882l-.696-.696-2.116 2.169-.992-.941-.696.697 1.688 1.637 2.812-2.866z" />
          </svg>
          <span>Documents</span>
        </NavLink>
          */}
        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/mes-voitures"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M11 11v-3h1.247c.882 0 1.235.297 1.828.909.452.465 1.925 2.091 1.925 2.091h-5zm-1-3h-2.243c-.688 0-1.051.202-1.377.581-.316.348-.895.948-1.506 1.671 1.719.644 4.055.748 5.126.748v-3zm14 5.161c0-2.823-2.03-3.41-2.794-3.631-1.142-.331-1.654-.475-3.031-.794-.55-.545-2.052-2.036-2.389-2.376l-.089-.091c-.666-.679-1.421-1.269-3.172-1.269h-7.64c-.547 0-.791.456-.254.944-.534.462-1.944 1.706-2.34 2.108-1.384 1.402-2.291 2.48-2.291 4.603 0 2.461 1.361 4.258 3.179 4.332.41 1.169 1.512 2.013 2.821 2.013 1.304 0 2.403-.838 2.816-2h6.367c.413 1.162 1.512 2 2.816 2 1.308 0 2.409-.843 2.82-2.01 1.934-.056 3.181-1.505 3.181-3.829zm-18 4.039c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2zm12 0c-.662 0-1.2-.538-1.2-1.2s.538-1.2 1.2-1.2 1.2.538 1.2 1.2-.538 1.2-1.2 1.2zm2.832-2.15c-.399-1.188-1.509-2.05-2.832-2.05-1.327 0-2.44.868-2.836 2.062h-6.328c-.396-1.194-1.509-2.062-2.836-2.062-1.319 0-2.426.857-2.829 2.04-.586-.114-1.171-1.037-1.171-2.385 0-1.335.47-1.938 1.714-3.199.725-.735 1.31-1.209 2.263-2.026.34-.291.774-.432 1.202-.43h5.173c1.20 0 1.577.385 2.116.892.419.393 2.682 2.665 2.682 2.665s2.303.554 3.48.895c.84.243 1.35.479 1.35 1.71 0 1.196-.396 1.826-1.168 1.888z" />
          </svg>
          <span>Mes Voitures</span>
        </NavLink>
        {/*
           <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/archive"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M1.8 9l-.8-4h22l-.8 4h-2.029l.39-2h-17.122l.414 2h-2.053zm18.575-6l.604-2h-17.979l.688 2h16.687zm3.625 8l-2 13h-20l-2-13h24zm-8 4c0-.552-.447-1-1-1h-6c-.553 0-1 .448-1 1s.447 1 1 1h6c.553 0 1-.448 1-1z" />
          </svg>
          <span>Archive</span>
        </NavLink>
          */}
        {/* <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/espace-fidelite"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M11.383 15.941l-3.758 8.059-.967-2.658-2.658.968 3.517-7.541c.678.216 1.137.162 1.849.162.744.513 1.072.844 2.017 1.01zm3.252-1.009c-.738.506-1.049.831-1.994 1.004l3.759 8.064.967-2.658 2.633.968-3.495-7.549c-.686.202-1.146.171-1.87.171zm-2.635-11.932c-2.205 0-4 1.795-4 4s1.795 4 4 4c2.206 0 4-1.794 4-4s-1.794-4-4-4zm6.926 5.278c.051.146.074.296.074.445 0 .449-.202.883-.615 1.156-1.256.87-1.09.651-1.562 2.067-.198.591-.77.99-1.414.99h-.004c-1.549-.005-1.279-.088-2.528.789-.262.183-.569.275-.877.275s-.615-.092-.876-.275c-1.249-.878-.98-.794-2.528-.789h-.004c-.645 0-1.216-.399-1.413-.99-.473-1.417-.311-1.198-1.562-2.067-.395-.274-.617-.708-.617-1.157 0-.148.024-.298.074-.444.483-1.411.484-1.139 0-2.555-.05-.147-.074-.297-.074-.445 0-.45.202-.883.616-1.157 1.251-.868 1.089-.648 1.562-2.067.197-.591.769-.99 1.413-.99h.004c1.545.005 1.271.095 2.528-.79.262-.182.569-.274.877-.274s.615.091.876.274c1.249.878.98.795 2.528.79h.004c.645 0 1.216.399 1.414.99.473 1.416.307 1.197 1.562 2.067.394.274.616.707.616 1.156 0 .148-.023.299-.074.445-.483 1.411-.485 1.139 0 2.556zm-1.926-1.278c0-2.761-2.238-5-5-5-2.761 0-5 2.239-5 5s2.239 5 5 5c2.762 0 5-2.238 5-5z" />
          </svg>
          <span>Espace Fidélité</span>
        </NavLink> */}
        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/contact"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 24"
          >
            <path d="M19 9.062s-5.188-.333-7 1.938c2-4.896 7-5.938 7-5.938v-2l5 4-5 4.019v-2.019zm-18.974 14.938h23.947l-11.973-11.607-11.974 11.607zm1.673-14l10.291-7.488 3.053 2.218c.712-.459 1.391-.805 1.953-1.054l-5.006-3.637-11.99 8.725v12.476l7.352-7.127-5.653-4.113zm15.753 4.892l6.548 6.348v-11.612l-6.548 5.264z" />
          </svg>
          <span>Contact</span>
        </NavLink>

        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/complaints"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.80001 0C1.32262 0 0.86478 0.189644 0.527212 0.527212C0.189644 0.86478 0 1.32262 0 1.80001V12.6001C0 13.0775 0.189644 13.5353 0.527212 13.8729C0.86478 14.2105 1.32262 14.4001 1.80001 14.4001H6.44005C6.48245 14.1921 6.55765 13.9913 6.66405 13.8049L7.01046 13.2001H1.80001C1.64088 13.2001 1.48827 13.1369 1.37575 13.0244C1.26322 12.9118 1.20001 12.7592 1.20001 12.6001V1.80001C1.20001 1.64088 1.26322 1.48827 1.37575 1.37575C1.48827 1.26322 1.64088 1.20001 1.80001 1.20001H12.6001C12.7592 1.20001 12.9118 1.26322 13.0244 1.37575C13.1369 1.48827 13.2001 1.64088 13.2001 1.80001V8.00327C13.2481 8.06727 13.2929 8.13607 13.3329 8.20647L14.4001 10.0713V1.80001C14.4001 1.32262 14.2105 0.86478 13.8729 0.527212C13.5353 0.189644 13.0775 0 12.6001 0H1.80001Z" />
            <path d="M11.6 7.19986C11.6 7.04073 11.5368 6.88811 11.4243 6.77559C11.3118 6.66307 11.1592 6.59985 11 6.59985H6.6C6.44087 6.59985 6.28826 6.66307 6.17574 6.77559C6.06321 6.88811 6 7.04073 6 7.19986C6 7.35899 6.06321 7.5116 6.17574 7.62413C6.28826 7.73665 6.44087 7.79986 6.6 7.79986H10.1728C10.3588 7.60988 10.5808 7.45894 10.8259 7.35591C11.071 7.25288 11.3342 7.19982 11.6 7.19986Z" />
            <path d="M9.06962 9.59985H6.6C6.44087 9.59985 6.28826 9.66307 6.17574 9.77559C6.06321 9.88811 6 10.0407 6 10.1999C6 10.359 6.06321 10.5116 6.17574 10.6241C6.28826 10.7366 6.44087 10.7999 6.6 10.7999H8.38322L9.06962 9.59985Z" />
            <path d="M3.80001 4.99992C4.01218 4.99992 4.21567 4.91563 4.3657 4.7656C4.51573 4.61557 4.60001 4.41208 4.60001 4.19991C4.60001 3.98773 4.51573 3.78425 4.3657 3.63422C4.21567 3.48419 4.01218 3.3999 3.80001 3.3999C3.58783 3.3999 3.38435 3.48419 3.23432 3.63422C3.08429 3.78425 3 3.98773 3 4.19991C3 4.41208 3.08429 4.61557 3.23432 4.7656C3.38435 4.91563 3.58783 4.99992 3.80001 4.99992Z" />
            <path d="M6.6 3.59985C6.44087 3.59985 6.28826 3.66307 6.17574 3.77559C6.06321 3.88811 6 4.04073 6 4.19986C6 4.35899 6.06321 4.5116 6.17574 4.62413C6.28826 4.73665 6.44087 4.79986 6.6 4.79986H11C11.1592 4.79986 11.3118 4.73665 11.4243 4.62413C11.5368 4.5116 11.6 4.35899 11.6 4.19986C11.6 4.04073 11.5368 3.88811 11.4243 3.77559C11.3118 3.66307 11.1592 3.59985 11 3.59985H6.6Z" />
            <path d="M3.80001 7.99992C4.01218 7.99992 4.21567 7.91563 4.3657 7.7656C4.51573 7.61557 4.60001 7.41208 4.60001 7.19991C4.60001 6.98773 4.51573 6.78425 4.3657 6.63422C4.21567 6.48419 4.01218 6.3999 3.80001 6.3999C3.58783 6.3999 3.38435 6.48419 3.23432 6.63422C3.08429 6.78425 3 6.98773 3 7.19991C3 7.41208 3.08429 7.61557 3.23432 7.7656C3.38435 7.91563 3.58783 7.99992 3.80001 7.99992Z" />
            <path d="M3.80001 10.9999C4.01218 10.9999 4.21567 10.9156 4.3657 10.7656C4.51573 10.6156 4.60001 10.4121 4.60001 10.1999C4.60001 9.98773 4.51573 9.78425 4.3657 9.63422C4.21567 9.48419 4.01218 9.3999 3.80001 9.3999C3.58783 9.3999 3.38435 9.48419 3.23432 9.63422C3.08429 9.78425 3 9.98773 3 10.1999C3 10.4121 3.08429 10.6156 3.23432 10.7656C3.38435 10.9156 3.58783 10.9999 3.80001 10.9999Z" />
            <path d="M11.2864 8.04231C11.5421 7.97335 11.8135 7.99105 12.0581 8.09266C12.3027 8.19427 12.5068 8.37405 12.6384 8.60391L15.8416 14.2024C16.0056 14.4904 16.0384 14.8168 15.9576 15.1136C15.8896 15.3678 15.7394 15.5925 15.5305 15.7527C15.3216 15.9128 15.0656 15.9995 14.8024 15.9992H8.39756C8.06716 15.9992 7.76795 15.8656 7.55115 15.6472C7.36442 15.4612 7.24436 15.2187 7.20964 14.9574C7.17492 14.6961 7.22748 14.4307 7.35915 14.2024L10.5608 8.60311C10.6387 8.46636 10.743 8.34639 10.8675 8.25014C10.992 8.15388 11.1344 8.08325 11.2864 8.04231ZM11.9968 9.99992C11.9968 9.89384 11.9546 9.79209 11.8796 9.71708C11.8046 9.64206 11.7029 9.59992 11.5968 9.59992C11.4907 9.59992 11.389 9.64206 11.3139 9.71708C11.2389 9.79209 11.1968 9.89384 11.1968 9.99992V12.3999C11.1968 12.506 11.2389 12.6078 11.3139 12.6828C11.389 12.7578 11.4907 12.7999 11.5968 12.7999C11.7029 12.7999 11.8046 12.7578 11.8796 12.6828C11.9546 12.6078 11.9968 12.506 11.9968 12.3999V9.99992ZM11.6 14.8C11.7591 14.8 11.9117 14.7367 12.0243 14.6242C12.1368 14.5117 12.2 14.3591 12.2 14.2C12.2 14.0408 12.1368 13.8882 12.0243 13.7757C11.9117 13.6632 11.7591 13.6 11.6 13.6C11.4409 13.6 11.2882 13.6632 11.1757 13.7757C11.0632 13.8882 11 14.0408 11 14.2C11 14.3591 11.0632 14.5117 11.1757 14.6242C11.2882 14.7367 11.4409 14.8 11.6 14.8Z" />
          </svg>
          <span>Réclamations</span>
        </NavLink>

        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/parametres"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="24"
            width="24"
            viewBox="0 0 24 24"
          >
            <g>
              <path d="M0,0h24v24H0V0z" fill="none" />
              <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.20-0.37-0.29-0.59-0.20l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.20-0.08-0.47,0-0.59,0.20L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.20,0.37,0.29,0.59,0.20l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.20,0.08,0.47,0,0.59-0.20l1.92-3.32c0.12-0.20,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
            </g>
          </svg>
          <span>Paramètres</span>
        </NavLink>
      </div>
    </Container>
  );
};

export default Menu;

const Container = styled.div`
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: ${(props) => (props.color ? props.color : "#111")};
  transition: all 0.3s;
  overflow-x: hidden;
  height: 100%;
  width: 240px;
  z-index: 99999;
  .side-menu {
    overflow-y: scroll;
  }

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.7em 0 1.7em 0;
    width: 100%;
    min-width: 90px;
  }

  .logo {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .dash-links {
    padding: 1em 0;
    width: 100%;
  }

  .dash-link {
    padding: 1.1em 1.5em;
    cursor: pointer;
    svg {
      margin-right: 0.75em;
      fill: #abb3c4;
      transition: all 0.4s;
      min-width: 24px;
    }
    span {
      font-size: 15px;
      font-weight: 400 !important;
      color: #abb3c4;
      transition: all 0.4s;
      white-space: nowrap;
    }
    &:hover {
      span {
        color: #fff;
      }
      svg {
        fill: #fff;
      }
    }
  }

  .active-link {
    background: RGBA(159, 162, 180, 0.08);
    border-left: 3px solid #dde2ff;
    span {
      color: #dde2ff;
    }
    svg {
      fill: #dde2ff;
    }
  }

  @media only screen and (max-width: 768px) {
    position: relative;
    width: ${(props) => (props.menuIsActive ? "240px" : "0")};
    min-height: ${(props) =>
      props.menuIsActive ? "calc(100vh - 80px)" : "100vh"};
    top: ${(props) => (props.menuIsActive ? "80px" : "0")};
  }
`;
