import React from "react";
import styled from "styled-components";
import DarkMap from "../NewMap";
import CarDetailBlock from "./CarDetailBlock";
import Car from "../../assets/ford.png";

const FourthStep = ({
  setCity,
  setBrandForCommercial,
  brandForCommercial,
  setSucursale,
  city,
  sucursale,
  values,
  brands,
  color,
  cities,
  sucursales,
  getModel,
  coordinates,
  setCoordinates,
  isLoading,
  isCommercial,
  senarioType,
  modelForCommercial,
  setModelForCommercial,
  isLoadingForModels ,
  models
}) => {
  console.log("This is model", modelForCommercial);
  const selectedC = JSON.parse(localStorage.getItem('selectedCar'));
  return (
    <Container color={color}>
      {isCommercial ? (
        <CarDetailBlock
          values={values}
          img={Car}
          color={color}
          selectedC={selectedC}
          rv
          getModel={getModel}
        />
      ) : null}
      {
        senarioType == "Commercial" ?
        (
          <>
          <h2 className="h2-succursale">Marque et modèle souhaitées :</h2>
  <div className="inputs">
    <div className="select-wrp">
      <select
        className="select width-unset"
        name="brandForCommercial"
        onChange={(e) => {
         const v = e.target.value;
          setBrandForCommercial(JSON.parse(v));
        }}
      >
        {brands.map((item, index) => {
          console.log(item);
          return (
            <option value={JSON.stringify(item)} key={index}>
              {item.Nom}
            </option>
          )
        })}
      </select>
    </div>
    <div className="input-label">
      <select
        className="select width-unset"
        name="modelForCommercial"
        value={modelForCommercial}
        onChange={(e) => {
          setModelForCommercial(e.target.value);
        }}
      >
        {isLoadingForModels ? (
          models.map((item, index) => (
            <option value={item.idModele} key={index}>
              {item.Nom}
            </option>
          ))
        ) : (
          <option value="0">Chargement en cours...</option>
        )}
      </select>
    </div>
  </div>
    </>

        ):null
        
      }

      <h2 className="h2-succursale">Choisir votre succursale</h2>
      <div className="inputs">
        <div className="select-wrp">
          <select
            className="select width-unset"
            name="city"
            onChange={(e) => setCity(e.target.value)}
          >
            {cities.map((item, index) => (
              <option value={item.id} key={index}>
                {item.nom}
              </option>
            ))}
          </select>
        </div>
        <div className="input-label">
          <select
            className="select width-unset"
            name="succursale"
            value={JSON.stringify(sucursale)}
            onChange={(e) => {
              const v = JSON.parse(e.target.value);
              setSucursale(v);
              console.log("this is succ", v);
              setCoordinates({
                ...coordinates,
                lat: parseFloat(v.lat),
                lng: parseFloat(v.lng),
                name: v.nom,
              });
            }}
          >
            {!isLoading ? (
              sucursales.map((item, index) => (
                <option value={JSON.stringify(item)} key={index}>
                  {item.nom}
                </option>
              ))
            ) : (
              <option value="0">Chargement en cours...</option>
            )}
          </select>
        </div>
      </div>

      <DarkMap search={`${city} Autohall`} parent="true" sucursale={sucursale} setSucursale={setSucursale}/>
    </Container>
  );
};

export default FourthStep;

const Container = styled.div`
  iframe {
    margin-top: 2.5em;
    margin-bottom: -2.5em;
  }
  .h2-succursale {
    margin: 0.5em 0;
  }
`;
