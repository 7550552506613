import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CustomHelmet from "../components/elements/CustomHelmet";
import Layout from "../layouts/DefaultLayout";
import HistoryDoc from "../components/HistoryDoc";
import AuthContext from "../contexts/AuthContext";

const HistoriqueDocuments = () => {
  const { auth } = useContext(AuthContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [permenateData, setPermanentData] = useState([]);
  const [docData, setDocData] = useState([]);

  const handleChange = (e) => {
    if (e.target.value != 0 && permenateData) {
      if (e.target.value === "all") {
        return setDocData(permenateData);
      }
      setDocData(permenateData.filter((word) => word.type === e.target.value));
    }
  };
  console.log(docData);

  const filterByDate = (data) => {
    let newArray = [];
    let oldDate = new Date("2021-01-15");
    let newDate = new Date(data);
  

    if (newDate.getTime() === oldDate.getTime()) {
      
      setDocData(permenateData);
    } 
    
  };

  const getDocuments = (userID) => {
    fetch(`${REACT_APP_BACKEND_URL}/api/getDocuments/${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          setDocData(data.data);
          setPermanentData(data.data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };

  useEffect(() => {
    getDocuments(auth.id);
  }, []);

  return (
    <Layout>
      <Container>
        <CustomHelmet title="Documents" />
        <div className="box">
          <div className="nav-row">
            <div className="first-wrap">
              <h5>Filtrer par: </h5>
              <select
                className="select"
                name="type"
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="all">Tous</option>
                <option value="Devis">Devis</option>
                <option value="Facture">Facture</option>
                <option value="Type Autres">Autres</option>
              </select>
            </div>
            <div className="second-wrap">
              <h5>Date :</h5>
              <input
                className="date-input"
                type="date"
                onChange={(e) => {
                  filterByDate(e.target.value);
                }}
              ></input>
            </div>
          </div>

          <HistoryDoc data={docData} date={"ff"} />
        </div>
      </Container>
    </Layout>
  );
};

export default HistoriqueDocuments;

const Container = styled.div`
  .second-wrap {
    display: flex;
  }
  .first-wrap {
    display: flex;
  }
  .nav-row {
    display: flex;
  }
  .box {
    padding: 2em;
    margin: 1em;
    background: white;
    border-radius: 20px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
  }
  h5 {
    font-size: 17px;
    margin-right: 50px;
  }
  .select {
    border: none;
    padding: 6px;
    border-radius: 8px;
    font-size: 14px;
    color: #808080;
    width: 13.5em;
    margin-right: 50px;
    border: 1px #c4c4c4 solid;
    outline: none;
  }
  .date-input {
    width: 13em;
    border: 1px #c4c4c4 solid;
    text-align: center;
    border-radius: 6px;
    padding: 6px;
    color: #808080;
  }
  .date {
    width: 13em;
    text-align: center;
    border-radius: 6px;
  }
  @media only screen and (max-width: 1280px) {
    .nav-row h5 {
      font-size: 13px !important;
      margin-bottom: 10px;
    }
    .first-wrap,
    .second-wrap {
      display: grid;
    }
  }
  @media only screen and (max-width: 520px) {
    .nav-row {
      display: grid;
    }
    .row {
      grid-template-columns: 70% 14%;
    }
    .select {
      margin-bottom: 25px;
    }
  }
  @media only screen and (max-width: 400px) {
    .box {
      padding: 1em;
    }
  }
`;
