import React from "react";
import styled from "styled-components";
import Close from "../assets/close.svg";

const Condition = ({setOpen}) => {
    return (
        <Container>
            <Drop>
                <Top>
                    <img src={Close} onClick={() =>setOpen(false)}/>
                </Top>
                <Content>
                    <p>
                    Le traitement des données personnelles recueillies du site web www.rdv.autohall.ma est effectué conformément à loi n°09-08 relative à la protection des personnes physiques à l’égard des traitements des données à caractère personnel.

Seuls sont collectées les informations personnelles fournies par l’utilisateur avec son consentement explicite.

Outre que le traitement des demandes reçues par l’utilisateur via le site, les données collectées peuvent être utilisées pour des opérations de marketing, des analyses statistiques, le suivi commercial ainsi que pour la prise de contact.

En vertu de cette même loi, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit via :
                    </p>
                    <ul>
                    <li>Le formulaire de contact </li>
                    <li>par mail à contact@autohall.ma </li>
                    <li>par voie postale à AUTOHALL au Km 12, autoroute Casablanca-Rabat, sortie Al Qods, Casablanca 20610 - Maroc</li>
                    </ul>
                 <p>
                 Les sources des informations diffusées sur le site sont réputées fiables. Toutefois, le site se réserve la faculté d'une non-garantie de la fiabilité des sources. Les informations données sur le site le sont à titre purement informatif. Ainsi, l'Utilisateur assume seul l'entière responsabilité de l'utilisation des informations et contenus du présent site.
                 </p>
                 <p>
                 Tout usage du service par l'Utilisateur ayant directement ou indirectement pour conséquence des dommages doit faire l'objet d'une indemnisation au profit du site.
                 </p>
                 <p>
                 Une garantie optimale de la sécurité et de la confidentialité des données transmises n'est pas assurée par le site. Toutefois, le site s'engage à mettre en œuvre tous les moyens nécessaires afin de garantir au mieux la sécurité et la confidentialité des données.

La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.
                 </p>

              </Content>
            </Drop>
        </Container>

    )
}
export default Condition;

const Container = styled.div`
position:absolute;
top:0;
bottom: 0;
left:0;
left:0;
right:0;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;
const Drop = styled.div`
position: fixed;
box-shadow: 0px 0px 7px 0px  #000;
width: 80%;
background: #fff;
border-radius: 20px;
padding: 20px;
`;
const Top = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
padding-bottom: 20px;
img{
    cursor: pointer;
    width: 17px;
    height: 17px;
}
`;
const Content = styled.div`
p{
    line-height: 30px;
}
ul{
    padding: 20px;
    li{
        padding: 5px 0;
      color: red;
    }
}
`