import React from "react";
import styled from "styled-components";

const NavigationButtons = ({
  step,
  setStep,
  handleSubmit,
  setAction,
  selectedTime,
  color,
  senario,
  addRV = { addRV },
  values,
  errEmail,
  senarioType
}) => {
  
  const next = () => {
    console.log(senario);

    switch (step) {
     case 1:
       if(errEmail) {
         break;
       }
      case 2:
        if (senarioType === "Commercial") {
          setStep(4);
        } else {
          handleSubmit();
        }
        break;
      case 5:
        if (selectedTime === null) {
          setAction(true);
        } else {
          handleSubmit();
        }
        break;
      case 6:
        handleSubmit();
        break;
      default:
        handleSubmit();
    }
  };

  const back = () => {
    switch (step) {
      case 4:
        if (senarioType === "Commercial") {
          setStep(2);
        } else {
          setStep(step - 1);
        }
        break;
      default:
        setStep(step - 1);
    }
  };

  return (
    <Container color={color}>
      <hr className="border-bottom" />
      <div className="btns">
        {step !== 1 ? (
          <button type="button" className="btn back-btn" onClick={back}>
            Précédent
          </button>
        ) : null}
        <button className="btn" type="button" onClick={next}>
          {step === 6 ? "Envoyer" : "Suivant"}
        </button>
        {errEmail ? (<span style={{color: 'red', fontWeight: 'bold', marginLeft: '20px'}}>l’email est erroné </span>) : null}
      </div>
      
    </Container>
  );
};

export default NavigationButtons;

const Container = styled.div`
  .border-bottom {
    height: 2px;
    width: 100%;
    background-color: #dfdfe8;
    border: none;
    border-radius: 100px;
    margin: 7em 0 2em 0;
  }
  .btn {
    border-radius: 100px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    background: ${(props) => props.color};
    padding: 8px 30px;
    border: 2px solid ${(props) => props.color};
    margin-left: 1em;
    transition: all 0.5s;
    &:hover {
      color: ${(props) => props.color};
      background: transparent;
    }
  }
  .btns {
    margin-top: 2em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    
  }
  .back-btn {
    background: #b8b8b8;
    color: #fff;
    border: 2px solid #b8b8b8;
    margin-left: 1em;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      color: #b8b8b8;
      background: transparent;
    }
   
  }
  .err{
      color: 'red';
    }
  
`;