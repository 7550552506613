import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AbortController from "abort-controller"
import CustomHelmet from "../components/elements/CustomHelmet";
import styled from "styled-components";
import Car from "../assets/car.png";
import Ford2 from "../assets/ford4.png";
import Spinner from './Spinner';

class CarChoiceCart extends React.Component {
  constructor(props){
    super();
    this.state = {
      imgUrl : '',
      errImg: false,
      isFteched: false,
    }
  }
 

  
   async componentDidMount(){
    const controller = new AbortController()
    const signal = controller.signal
    if(location.pathname.includes('car-choice')){
      
    const {REACT_APP_BACKEND_URL} = process.env;
    const {
      type,
      imtr,
      date,
      logo,
      modele,
      handleClick} = this.props;
    try{
      const resp = await fetch(`${REACT_APP_BACKEND_URL}/api/model/${decodeURI(modele)}`, {
        signal:signal
      })
      const respJson = await resp.json();
      if(respJson.status === 200){
        this.setState({imgUrl : respJson.data.image});
        
      }else{
      
      this.setState({imgUrl :''});
      }
      console.log(respJson, "This is the Resp");
        
      
        
      
     
       
      }catch(e){
        console.log(e.message);
      }
    }else{
      controller.abort();
    }
    this.setState({isFetched: true})
  }
 



  render(){

    const {item ,
      type,
      imtr,
      date,
      logo,
      modele,
      handleClick} = this.props;
      const {imgUrl, errImg} = this.state;
    return (
      <Container onClick={() => handleClick({...item,imgUrl})}>
      <CustomHelmet title="Voiture choix" />
      {/* <Link to="/" className="wrp"> */}
      <div className="img-wrp">
        {
       
       
       this.state.isFetched ?
       imgUrl.length ? 
       (
         <img src={imgUrl} alt="Voiture" className="img" />
       )
       
     
        : (<img src={Ford2} alt="Voiture" className="img" />)
        : (<Spinner />)
           

        
        }
       
      </div>
      <div className="car-name">
        <h4>{type}</h4>
        <div className="modele">
          <p className="title expandable">Version: </p>
          <span className="data">{modele}</span>
        </div>
      </div>
      <div className="info">
        <div className="imtr col">
          <p className="title">Imtr:</p>
          <p className="data">{imtr}</p>
        </div>
        <div className="date col">
          <p className="title">Date mise en circulation :</p>
          <p className="data">{date}</p>
        </div>
        <img src={logo} alt="logo" style={{maxHeight: '200px', maxWidth: '200px'}}/>
      </div>
      {/* </Link> */}
    </Container>
    )
  }

  
 

};

export default CarChoiceCart;

const Container = styled.div`
  background-color: #fff;
  padding: 2em;
  max-width: 700px;
  margin: 1em;
  cursor: pointer;
  width: 100%;
  .car-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modele {
    display: flex;
    span {
      margin: 0 0.25em;
    }
  }
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .wrp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  h4 {
    font-size: 24px;
    margin: 1em 0;
  }
  .img-wrp {
    width: 150px;
    max-height: 150px;
    .img {
      width: 100%;
    }
  }
  .title {
    color: #9a9ca8;
    font-size: 16px;
    font-weight: 500;
  }
  .data {
    font-size: 16px;
    font-weight: 500;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    //testing
    margin: 0 0.5em 0 0;
  }
  @media only screen and (max-width: 576px) {
    padding: 1em 0.5em;
    margin: 1em 0;
    .date {
      display: none;
    }
  }
  @media only screen and (max-width: 460px) {
    .expandable {
      display: none;
    }
  }
`;
