import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Car1 } from "../assets/car1.svg";
import { ReactComponent as Car2 } from "../assets/car2.svg";
import { ReactComponent as Car3 } from "../assets/car3.svg";
import { ReactComponent as Car4 } from "../assets/car3.svg";
import { ReactComponent as Car5 } from "../assets/car4.svg";

const DashCarStatus = ({ data }) => {
  console.log(data);
  const [step, setStep] = useState(3);
  return (
    <Container>
      <h5 className="car-status-title">Statut du véhicule</h5>
      <div className="car-icones">
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_ATTENTE_TRAVAUX"
              ? "car-svg active"
              : "car-svg"
          }
        >
          <Car1 />
        </div>
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_COURS_TRAVAUX"
              ? "car-svg active"
              : "car-svg"
          }
        >
          <Car2 />
        </div>
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_CONTROLE_QUALITE"
              ? "car-svg active"
              : "car-svg"
          }
        >
          <Car3 />
        </div>
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_LAVAGE"
              ? "car-svg active"
              : "car-svg"
          }
        >
          <Car4 />
        </div>
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_ATTENTE_LIVRAISON"
              ? "car-svg active"
              : "car-svg"
          }
        >
          <Car5 />
        </div>
      </div>
      <div className="steps">
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_ATTENTE_TRAVAUX"
              ? "nbr active-step"
              : "nbr"
          }
        ></div>
        <hr
          className={
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_ATTENTE_TRAVAUX") ||
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_EN_COURS_TRAVAUX")
              ? "nbr-hr active-hr"
              : "nbr-hr"
          }
        />
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_COURS_TRAVAUX"
              ? "nbr active-step"
              : "nbr"
          }
        ></div>
        <hr
          className={
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_EN_COURS_TRAVAUX") ||
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_EN_CONTROLE_QUALITE")
              ? "nbr-hr active-hr"
              : "nbr-hr"
          }
        />
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_CONTROLE_QUALITE"
              ? "nbr active-step"
              : "nbr"
          }
        ></div>
        <hr
          className={
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_EN_CONTROLE_QUALITE") ||
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_EN_LAVAGE")
              ? "nbr-hr active-hr"
              : "nbr-hr"
          }
        />
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_LAVAGE"
              ? "nbr active-step"
              : "nbr"
          }
        ></div>
        <hr
          className={
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_EN_LAVAGE") ||
            (data?.code === 200 &&
              data?.data?.statut?.statut === "VEHICULE_EN_ATTENTE_LIVRAISON")
              ? "nbr-hr active-hr"
              : "nbr-hr"
          }
        />
        <div
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_ATTENTE_LIVRAISON"
              ? "nbr active-step"
              : "nbr"
          }
        ></div>
      </div>
      <div className="car-status-content">
        <p
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_ATTENTE_TRAVAUX"
              ? "nbr-p active-p"
              : "nbr-p"
          }
        >
          En attente Démarrage travaux
        </p>
        <p
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_COURS_TRAVAUX"
              ? "nbr-p active-p"
              : "nbr-p"
          }
        >
          Travaux en cours
        </p>
        <p
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_CONTROLE_QUALITE"
              ? "nbr-p active-p"
              : "nbr-p"
          }
        >
          Travaux contrôle qualité
        </p>
        <p
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_LAVAGE"
              ? "nbr-p active-p"
              : "nbr-p"
          }
        >
          En lavage
        </p>
        <p
          className={
            data?.code === 200 &&
            data?.data?.statut?.statut === "VEHICULE_EN_ATTENTE_LIVRAISON"
              ? "nbr-p active-p"
              : "nbr-p"
          }
        >
          Prête pour Livraison
        </p>
      </div>
    </Container>
  );
};

export default DashCarStatus;

const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 1em;
  margin: 1em 0.5em 0.5em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .car-icones {
    display: flex;
    .car-svg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc((100% -2em) / 5);
      transition: all 0.3s ease-in-out;
      opacity: 0.3;
      svg {
        width: 60px;
        height: 60px;
      }
      &.active {
        opacity: 1;
      }
    }
  }
  .steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1em 0;
    .nbr {
      border-radius: 100%;
      border: 4px solid purple;
      padding: 0.25em;
      transition: all 0.3s ease-in-out;
      opacity: 0.3;
      &.active-step {
        border: 5px solid purple;
        opacity: 1;
        padding: 0.3em;
      }
    }
    .nbr-hr {
      width: calc((100% -2em) / 5);
      height: 4px;
      border: none;
      outline: none;
      background: purple;
      transition: all 0.3s ease-in-out;
      opacity: 0.3;
      &.active-hr {
        opacity: 1;
      }
    }
  }
  .car-status-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    .nbr-p {
      font-size: 0.8rem;
      width: calc((100% -2em) / 5);
      margin: 0 0.5em;
      text-align: center;
      color: #9fa2b4;
      transition: all 0.3s ease-in-out;
      &.active-p {
        font-weight: 600;
        color: #000;
      }
    }
  }
  .car-status-title {
    font-size: 18px;
    font-weight: 600;
    color: #252733;
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media only screen and (max-width: 770px) {
    .nbr-p {
      font-size: 0.7rem !important;
    }
  }
  @media only screen and (max-width: 500px) {
    display: grid;
    grid-template-columns: auto 40px auto;
    grid-template-rows: 50px auto;
    min-height: 400px;
    .car-status-title {
      grid-column: 1/4;
    }
    .steps,
    .car-icones,
    .car-status-content {
      flex-direction: column;
      justify-content: space-between;
    }
    .car-svg {
      width: 100% !important;
      height: calc((100% -2em) / 5) !important;
      svg {
        width: 50px !important;
        height: 50px !important;
        margin: 0 0.5em 0 0;
      }
    }
    .steps {
      margin: 0 !important;
    }
    .nbr-hr {
      width: 4px !important;
      height: calc((100% -2em) / 5) !important;
    }
    .nbr-p {
      width: 100% !important;
      height: calc((100% -2em) / 5) !important;
    }
  }
`;
