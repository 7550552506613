import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";
import jwt from "jsonwebtoken";
import AuthContext from "../contexts/AuthContext";
import styled from "styled-components";
import Logo from "../assets/ford-logo.png";
import { ReactComponent as Password } from "../assets/padlock.svg";

const NewPasswordForm = ({ logo, userData, token }) => {

  const { REACT_APP_BACKEND_URL, REACT_APP_JWT_TOKEN } = process.env;
  const { auth, setAuth } = useContext(AuthContext);
  const [errorsAuth, setErrorsAuth] = useState(false);
  const [err, setErr] = useState("");
  let history = useHistory();
  const location = useLocation();
  
  console.log("Thisis dt for" , location);

  const updatePassword = async(newPassword, id) => {
    try{
      const response = await fetch(`${REACT_APP_BACKEND_URL}/activateAccount`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...location.data,
          username : location.data.PrenomClt + " " + location.data.NomClt,
          fname : location.data.PrenomClt,
          lname : location.data.NomClt,
          address : location.data.Adresse,
          email : location.email,
          phone: location.phone,
          pwd : newPassword,
          postcode: location.data.CodePst,
          nationality : location.data.Pays,
          city: location.data.Ville,
          birthdate : location.data.DateNais,
          user_status: "1"
        }),
      });
      const respJson =await response.json();
      if(respJson.change){
        history.push("/login")
      }else{
        setErr("Erreur ")

      }
    }catch(e){
      console.log(e.message);
      setErr(e.message);
    }
  }

  const updatepassword   = async (newpassword) => {
    try{
      const response  = await fetch(`${REACT_APP_BACKEND_URL}/api/updatepassword`, {
        method: "POST",
        headers:{
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password : newpassword,
          id: location.data.Custno
        })
      });
      const responseJson = await response.json();
      if(responseJson.change){
      
        history.push("/login");
      }
    }catch(e){
      console.log(e.message);
    }
  }

  const activateAccountForBoMobile = async (mdp, isExist) =>{
    try{
      const resp = await fetch(`${REACT_APP_BACKEND_URL}/mobile/active`, {
        method: "POST",
        headers: {
          'Content-type':'application/json'
        },
        body: JSON.stringify({
          id : location.data.Custno,
          password : mdp,
          email : location.email,
          phone : location.phone
        })
      })
      const respJson = await resp.json();
      console.log(respJson);
      if(respJson.code === 200){
        if(isExist){
         await updatepassword(mdp)
        }else{
          await updatePassword(mdp, location.data.Custno);
        }
        
      }else{
        setErr("Erreur Exist");
      }
      
    }catch(e){
      console.log(e);
    }
  }


  const activateAccount = async (data, newpassword) => {
    // cin,nationality,
    //   profession,
    const email = data.Email.length !== 0 ? data.Email : "";
    const objClient = {
      username: data.IDClient,
      fname: data.PrenomClt,
      lname: data.NomClt,
      phone: data.Tel1,
      birthdate: data.DateNais,
      cin: null,
      city: data.Ville,
      address: data.Adresse,
      nationality: null,
      profession: null,
      postcode: data.CodePst,
      email: email,
      pwd: newpassword,
      IDClient: data.IDClient,
      Custno: data.Custno,
    };
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/activateAccount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objClient),
      });
      const result = await response.json();
      if (result.message === "Succes") {
        jwt.verify(result.token, REACT_APP_JWT_TOKEN, (err, decoded) => {
          if (decoded) {
            setAuth(decoded);
            history.push({
              pathname: "/login",
              // state: { userdata: objClient },
            });
          }
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const togglePassword = (password) => {
    const input = document.getElementById(password);
    if (input) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  };
  return (
    <Container>
      <img src={logo ? logo : Logo} alt="logo" className="logo" />
      {err ? <p style={{color: "red", fontSize: "20px"}}>{err}</p>: null}
      <Formik
        initialValues={{
          pwd: "",
          newpwd: "",
        }}
        validationSchema={Yup.object({
          pwd: Yup.string().required("Champ requise"),
          newpwd: Yup.string().required("Champ requise"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          if (data.pwd === data.newpwd) {
            if(location.isExist){
              if(!location.isActive){
                setSubmitting(true);
               activateAccountForBoMobile(data.pwd, true)       
                setSubmitting(false);
              }
            }else{
              setSubmitting(true);
              activateAccountForBoMobile(data.pwd, false)
              setSubmitting(false);
            }
            
           
            
          } else {
            setErrorsAuth(true);
          }
        }}
      >
        {({ handleChange, values, errors, isSubmitting }) => (
          <Form>
            <div className="input-wrp">
              <input
                className="input"
                name="pwd"
                id="pwd"
                type="password"
                placeholder="Mot de passe"
                value={values.pwd}
                onChange={handleChange}
              />
              <Password
                onClick={() => {
                  togglePassword("pwd");
                }}
              />

              {errors ? <div className="error">{errors.pwd}</div> : null}
            </div>
            <div className="input-wrp">
              <input
                className="input"
                name="newpwd"
                id="newpwd"
                type="password"
                placeholder="Confirmation de mot de passe"
                value={values.newpwd}
                onChange={handleChange}
              />
              <Password
                onClick={() => {
                  togglePassword("newpwd");
                }}
              />

              {errors ? <div className="error">{errors.newpwd}</div> : null}
              {errorsAuth ? (
                <div className="error">
                  error les mots de passe ne correspondent pas
                </div>
              ) : null}
            </div>
            <button type="submit">
              {isSubmitting ? "Loading..." : "Changer"}
            </button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default NewPasswordForm;

const Container = styled.div`
  z-index: 1;
  background: #fff;
  border-radius: 20px;
  padding: 3.5em 2em;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  height: fit-content;
  width: 100%;
  .logo {
    margin-bottom: 2em;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .input {
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 0.75em 0;
  }
  .input-wrp {
    position: relative;
    width: 100%;
  }
  button {
    background: #f0f5fd;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin-top: 2em;
    color: #919192;
  }
  svg {
    position: absolute;
    right: 0.75em;
    top: 1.7em;
    width: 20px;
    height: 20px;
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  @media only screen and (max-width: 992px) {
    padding: 3.5em 1em;
  }
`;
