import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Gift from "../assets/gift.png";
import Flat from "../assets/flat.png";
import DetailButton from "./elements/DetailButton";

const DashFidelite = ({ points }) => {
  return (
    <Container>
      <div className="top-row">
        <h5>Fidélité</h5>
        <Link to="/espace-fidelite">
          <DetailButton title="Détail" />
        </Link>
      </div>
      <div className="responsive-wrp">
        <div className="row">
          <img src={Gift} alt="icon" className="icon" />
          <h6>
            {new Intl.NumberFormat("de-DE").format(points.lpoints)}{" "}
            <span className="extra">points</span>
          </h6>
        </div>
        <h5 className="responive-h5">Mes chèques</h5>
        <div className="row">
          <img src={Flat} alt="icon" className="icon" />
          <h6>
            {points.data ? points.data.length : null}{" "}
            <span className="extra">Chèques</span>
          </h6>
        </div>
      </div>
    </Container>
  );
};

export default DashFidelite;

const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 1em;
  margin: 0.25em 0.5em 0.5em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0;
    margin-bottom: 1em;
  }
  .row {
    margin: 0.5em 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .icon {
      margin: 0.5em 0;
    }
  }
  img {
    margin-left: 1em;
  }
  h6 {
    margin-left: 0.75em;
    font-size: 14px;
    font-weight: 600;
  }
  @media only screen and (max-width: 576px) {
    padding: 1em;
    h5 {
      font-size: 16px;
    }
    h4 {
      font-size: 14px;
    }
    .responive-h5 {
      display: none;
    }
    .responsive-wrp {
      display: flex;
    }
  }
`;
