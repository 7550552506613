import React from "react";
import styled from "styled-components";
import temp from "../assets/tmp.jpg";
import walk from "../assets/walk.png";

const ContactAssistant = ({ title, adresse }) => {
  return (
    <Container>
      <div className="component-title">
        <h4>{title ? title : "..."}</h4>
      </div>
      <div className="contact-wrp">
        <div className="contact-wrp-left-">
          <p>{adresse ? adresse : "..."}</p>
          <div className="contact-number-wrp">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                fill="#2d3c85"
                d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"
              />
            </svg>
            <div>
              <h4 className="assitant-title">Responsable SAV</h4>
              <h5 className="assitant-number">0675001250</h5>
            </div>
          </div>
          <div className="contact-number-wrp">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                fill="#2d3c85"
                d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"
              />
            </svg>
            <div>
              <h4 className="assitant-title">Commercial</h4>
              <h5 className="assitant-number">0675001250</h5>
            </div>
          </div>
        </div>
        <div className="contact-wrp-right">
          <div className="succursale-img">
            <img src={temp} alt="image" />
          </div>
          <div className="distance">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path d="M23.5 7c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202zm-1.441 3.506c.639 1.186.946 2.252.946 3.666 0 1.37-.397 2.533-1.005 3.981v1.847c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1h-13v1c0 .552-.448 1-1 1h-1.5c-.552 0-1-.448-1-1v-1.847c-.608-1.448-1.005-2.611-1.005-3.981 0-1.414.307-2.48.946-3.666.829-1.537 1.851-3.453 2.93-5.252.828-1.382 1.262-1.707 2.278-1.889 1.532-.275 2.918-.365 4.851-.365s3.319.09 4.851.365c1.016.182 1.45.507 2.278 1.889 1.079 1.799 2.101 3.715 2.93 5.252zm-16.059 2.994c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm10 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm2.941-5.527s-.74-1.826-1.631-3.142c-.202-.298-.515-.502-.869-.566-1.511-.272-2.835-.359-4.441-.359s-2.93.087-4.441.359c-.354.063-.667.267-.869.566-.891 1.315-1.631 3.142-1.631 3.142 1.64.313 4.309.497 6.941.497s5.301-.184 6.941-.497zm2.059 4.527c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-18.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2z" />
            </svg>
            <p>15 min</p>
          </div>
          <div className="distance">
            <img src={walk} alt="walk" />
            <p>35 min</p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactAssistant;

const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 1em 1em 0.5em 1em;
  margin: 0.25em 0.5em 0.5em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    color: #252733;
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .component-title {
    margin-bottom: 1em;
  }
  .contact-wrp {
    display: grid;
    grid-template-columns: 60% 40%;
    p {
      font-size: 0.8rem;
    }
    .succursale-img {
      width: 90%;
      max-width: 200px;
      height: 100px;
      border-radius: 7px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .assitant-title,
    .assitant-number {
      font-size: 0.9rem;
    }
    .assitant-number {
      color: #9fa2b4;
    }
    .contact-number-wrp {
      margin: 1em 0;
      display: flex;
      align-items: center;
      > div {
        padding-left: 0.5em;
        border-left: 2px solid #dfe0eb;
      }
      svg {
        margin-right: 0.5em;
      }
    }
    .distance {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0.75em 0;
    }
  }
  .contact-wrp-right,
  .contact-wrp-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
