import React from "react";
import styled from "styled-components";

const Button = ({
  title,
  width,
  type,
  margin,
  radius,
  padding,
  bg,
  color,
  setActiveTab,
  activeTab,
  handleClick,
  disabled,
}) => {
  return (
    <Container
      width={width}
      margin={margin}
      radius={radius}
      padding={padding}
      bg={bg}
      color={color}
    >
      <button type={type} onClick={handleClick} disabled={disabled}>
        {title}
      </button>
    </Container>
  );
};

export default Button;

const Container = styled.div`
  width: ${(props) =>
    props.width === "large" ? "100%" : props.width ? props.width : "auto"};
  font-family: inherit;
  margin: ${(props) => (props.margin ? props.margin : "0.25em 0")};
  button {
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    padding: ${(props) => (props.padding ? props.padding : "10px 32px")};
    border-radius: ${(props) => (props.radius ? props.radius : "100px")};
    cursor: pointer;
    transition: all 0.5s;
    margin-top: ${(props) => (props.margin ? props.margin : "2.5em")};
    width: ${(props) => (props.width === "large" ? "100%" : "auto")};
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: ${(props) => (props.bg ? props.bg : "#f3f3f3")};
    color: ${(props) => (props.color ? props.color : "#000")};
    &:disabled {
      background-color: #b5b5b5;
      cursor: not-allowed;
      border: none;
    }
  }
`;
