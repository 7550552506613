import React from "react";
import styled from "styled-components";
import FideliteAvantageDetailsCart from "../components/FideliteAvantageDetailsCart";

const FediliteAvantage = ({ img }) => {
  return (
    <Container>
      <FideliteAvantageDetailsCart img={img} />
    </Container>
  );
};
export default FediliteAvantage;

const Container = styled.div`
  padding: 1em;
  background-color: white;
  height: 350px;
  .offer {
    width: 100%;
    object-fit: fill;
    border-radius: 12px;
  }
  @media only screen and (max-width: 1300px) {
    height: 300px;
  }
`;
