import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AuthenticationContainer from "../components/elements/AuthenticationContainer";
import CustomHelmet from "../components/elements/CustomHelmet";
import ActiverCompteChoixForm from "../components/ActiverCompteChoixForm";
import Button from "../components/elements/Button";
import ThemeContext from "../contexts/ThemeContext";
import Car from "../assets/ford.png";
import { ReactComponent as CallIcone } from "../assets/headset.svg";

const ActiverCompteChoix = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const [errors, setErrors] = useState({ value: false, message: "" });

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Container>
      <CustomHelmet title="Activer compte" />
      <AuthenticationContainer
        img={Car}
        slogan={theme?.slogan}
        color={theme?.color}
      >
        {!errors.value ? (
          <ActiverCompteChoixForm
            logo={REACT_APP_BACKEND_URL + theme?.logo}
            errors={errors}
            setErrors={setErrors}
          />
        ) : (
          <div className="call-support">
            <h4>Erreur, {errors.message} n'existe pas</h4>
            <p className="info-p">
              il semble que le choix de l'envoit du message par {errors.message}{" "}
              n'est pas possible. veuillez appeler ce numéro
            </p>
            <p className="align-to-right">
              <CallIcone className="call-svg" />
              0656874534
            </p>
            <Button
              handleClick={() =>
                setErrors({ ...errors, value: false, message: "" })
              }
              margin="1em 0.25em 0 0.25em"
              type="submit"
              color="#fff"
              title="Ressayer"
              bg={theme?.color}
            />
            
          </div>
        )}
      </AuthenticationContainer>
    </Container>
  );
};

export default ActiverCompteChoix;

const Container = styled.div`
  .call-support {
    z-index: 1;
    background: #fff;
    border-radius: 20px;
    padding: 3.5em 2em;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
    height: fit-content;
    width: 100%;
    h4 {
      margin: 1em 0;
      color: red;
    }
    .info-p {
      color: #808080;
      font-size: 14px;
      margin: 1em auto 1em 0;
      line-height: 2em;
    }
    .align-to-right {
      cursor: pointer;
      margin: 0 1.5em 0.25em auto;
      position: relative;
      svg {
        width: 20px;
        height: 20px;
        position: absolute;
        right: -35px;
        top: 0;
      }
    }
  }
`;
