import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import CustomHelmet from "../components/elements/CustomHelmet";
import Carousel from "react-multi-carousel";
import FediliteAvantage from "../components/FediliteAvantage";
import MyPoints from "../components/MyPoints";
import FediliteDetails from "../components/FediliteDetails";
import Layout from "../layouts/DefaultLayout";
import FediliteOffre from "../components/FediliteOffre";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import Offer from "../assets/offer.png";
import Offer2 from "../assets/offer-2.png";
import OffreImg from "../assets/pub.png";

const EspaceFidelite = () => {
  const { theme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const [fedilteDetails, setFedeliteDetails] = useState({});
  const { REACT_APP_BACKEND_URL } = process.env;

  const getPoints = (userID) => {
    fetch(`${REACT_APP_BACKEND_URL}/api/getPoints/${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          setFedeliteDetails(data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };

  const getHistoriqueFidelite = (userID) => {
    fetch(`${REACT_APP_BACKEND_URL}/api/getHistoriqueFidelite/${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          console.log(data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };
  const getPartenairesFidelite = (userID) => {
    fetch(`${REACT_APP_BACKEND_URL}/api/getPartenairesFidelite/${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          console.log(data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };

  useEffect(() => {
    getPoints(auth.id);
    getHistoriqueFidelite(auth.id);
    getPartenairesFidelite(auth.id);
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1700 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1700, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Layout>
      <Container>
        <CustomHelmet title="Espace fidélité" />
        <div className="column">
          <h5 className="titles"> Vous avantages fidélité</h5>
          <div className="row">
            <div className="carousel-container1">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                arrows={true}
                responsive={responsive}
                ssr={true}
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                customTransition="all 1.5s"
                transitionDuration={1000}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <FediliteAvantage img={Offer} />
                <FediliteAvantage img={Offer2} />
              </Carousel>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="bottom-row-container">
            <div className="second-carousel">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                arrows={true}
                responsive={responsive}
                ssr={true}
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                customTransition="all 1.5s"
                transitionDuration={1000}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                <FediliteOffre img={OffreImg} />
                <FediliteOffre img={OffreImg} />
              </Carousel>
            </div>
            <MyPoints points={fedilteDetails.lpoints} />
          </div>
        </div>
        <div className="row">
          <h5 className="titles check">Mes Chèques Récents</h5>
          <FediliteDetails data={fedilteDetails.data} color={theme?.color} />
        </div>
      </Container>
    </Layout>
  );
};

export default EspaceFidelite;

const Container = styled.div`
  padding: 1em;
  width: 100%;
  .row {
    display: flex;
    flex-direction: column;
    margin: 0.75em 0;
  }
  .carousel-container1 {
    background-color: white;
    border-radius: 12px;
  }
  .react-multi-carousel-list {
    border-radius: 12px;
  }
  .column {
    display: flex;
    flex-direction: column !important;
  }
  .row-2 {
    display: grid;
    grid-template-columns: 60% 40%;
  }
  .titles {
    font-size: 18px;
    font-weight: 600;
    margin: 1.5em 0 1em 0;
  }
  .testing {
  }
  .bottom-row-container {
    display: grid;
    grid-template-columns: 60% 40%;
  }
  .second-carousel {
    //margin-left: 1em;
  }

  @media only screen and (max-width: 1100px) {
    .row-2 {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 2, 1fr;
    }
    .carousel-container1 {
      margin-bottom: 1em;
    }
  }
  @media only screen and (max-width: 880px) {
    .bottom-row-container {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 2, 1fr;
    }
    .second-carousel {
      margin: 1em 0 1em 0;
      grid-row: 1/2;
    }
    .check {
      display: none;
    }
  }
`;
