import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CustomHelmet from "../components/elements/CustomHelmet";
import Layout from "../layouts/DefaultLayout";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import Button from "../components/elements/Button";

const Complaints = () => {
  const userId = 1;
  const { theme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [complaints, setComplaints] = useState([]);
  const [initialComplaints, setInitialComplaints] = useState([]);
  const [search, setSearch] = useState("");

  const getReclam = async () => {
    try {
      const resp = await fetch(
        `${REACT_APP_BACKEND_URL}/reclams/${auth.Custno}`
      );
      const respJosn = await resp.json();

      if (respJosn.status === 200) {
        setComplaints(respJosn?.data);
        console.log(respJosn?.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (auth !== null) {
      getReclam();
    }
  }, [auth]);

  const newRecl = complaints.length
    ? complaints.filter((com) => com.message.includes(search))
    : [];

  return (
    <Layout>
      <Container>
        <CustomHelmet title="Réclamations" />
        <div className="top">
          <div className="row">
            <h4>Liste des Réclamations</h4>
            <div className="input-wrp">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="#ADB3BC"
              >
                <path d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z" />
              </svg>
              <input
                type="text"
                placeholder="Recherche par text"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <Link to="/add-complaints">
              <Button
                handleClick={null}
                title="Ajouter"
                radius="7px"
                margin="0"
                font="14px"
                padding="8px 22px"
                bg={theme.color}
                color="#fff"
              />
            </Link>
          </div>
        </div>{" "}
        <div className="table">
          {newRecl?.length !== 0 ? (
            <div className="grid">
              {/*<h6>Nom</h6>
              <h6>Email</h6>
              <h6>Téléphone</h6>
              <h6>Sujet</h6>*/}
              <h6>Message</h6>
              <h6>Statut</h6>
              {/* <h6 /> */}
              {newRecl.map((item, index) => {
                console.log(item);
                return (
                  <Fragment key={index}>
                    {/*<h5 className="name">{item.nom}</h5>
                    <h5>{item.email}</h5>
                    <h5>{item.telephone} </h5>
                    <h5>{item.sujet} </h5>*/}
                    <h5>{item.message} </h5>
                    <h5>
                      <button
                        className={`status ${
                          item.statut === "Réclamation envoyée"
                            ? "orange-btn"
                            : item.statut === "Réclamation traitée"
                            ? "green-btn"
                            : item.statut === "Réclamation initialisée"
                            ? "blue-btn" 
                            : item.statut === "Réclamation clôturée"
                            ?  "green-btn"
                            : "black-btn"
                        }`}
                      >
                        {item.statut === "Réclamation envoyée"
                          ? "Envoyée"
                          : item.statut === "Réclamation traitée" ? "Traitée"
                          :
                          item.statut === "Réclamation initialisée" ? "Initialisée"
                          : 
                           item.statut === "Réclamation clôturée" ? "Clôturée"
                          : `${item.statut}`
                          }
                      </button>
                    </h5>
                    {/* <h5 className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        onClick={() => deleteClient(item.complaint_id)}
                      >
                        <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                      </svg>
                    </h5> */}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <p className="p-tags">
              <span className="red">*</span>pas de données.
            </p>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default Complaints;

const Container = styled.div`
  padding: 1em 2em;
  margin: 1em;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .p-tags {
    color: #5a616a;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 1em;
    .red {
      color: red;
      margin: 0 0.5em;
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 500;
    color: #726e6e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    font-weight: 600;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      fill: #f84b60;
      cursor: pointer;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1em 0em;
  }
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    margin: 0.5em 0;
  }
  input {
    padding: 9px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    background-color: #fff;
    padding-left: 35px;
    font-weight: 400;
    color: #2d4185;
  }
  .input-wrp {
    position: relative;
    margin-bottom: 0.5em;
  }
  input::placeholder {
    color: #adb3bc;
  }
  svg {
    position: absolute;
    top: 13px;
    left: 10px;
    cursor: pointer;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 2em;
    margin: 0.5em 2em 0.5em 0;
  }
  button {
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 5px 0;
    border-radius: 7px;
    width: 110px;
  }
  .red-btn {
    background-color: rgb(248, 75, 96);
    color: #fff !important;
  }
  .green-btn{
    background-color:#28a745;
    color: #fff !important;
  }
  .blue-btn{
    background-color:#17a2b8;
    color: #fff;
  }
  .orange-btn{
    background-color: #FFB319;
    color: #fff;
  }
  .black-btn{
    background-color: #000;
    color: #fff;
  }
  .rv-title-row {
    border-bottom: 1px solid #dfe0eb;
    padding-bottom: 0.5em;
    margin-top: 2em;
  }
  .attent {
    background-color: #2d4185;
  }
  .green {
    background: #36870d;
  }
`;
