import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import DashCarDetailsCart from "./DashCarDetailsCart";
import "react-multi-carousel/lib/styles.css";
import Car from "../assets/car.png";
import Ford2 from "../assets/ford4.png";
import Ford3 from "../assets/ford5.png";

const DashCarDetails = () => {
  const history = useHistory();
  let i = 0;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1700 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1700, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [userCars, setUserCars] = useState([]);
  const [currentCar, setCurrentCar] = useState(null);
  let data = JSON.parse(localStorage.getItem("selectedCar"));

  const outOfTheApp = () => {
    localStorage.removeItem("auth");
    history.push("/login");
  };
  return (
    <Container>
      {data ? (
        <DashCarDetailsCart
          img={data.imgUrl}
          name={data.MrqVehi}
          matr={data.NumImmatri}
          marque={data.MrqVehi}
          dateMEC={data.AnneeVeh}
          modele={data.ModelVehi}
          version={data.VersionVehi}
        />
      ) : (
        outOfTheApp()
      )}
    </Container>
  );
};

export default DashCarDetails;

const Container = styled.div`
  width: 50%;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 309px;
  background: #fff;
  border-radius: 12px;
  padding: 1em 0;
  margin: 0.25em 0.5em 0.5em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .react-multiple-carousel__arrow--left {
    left: 0 !important;
  }
  .react-multiple-carousel__arrow--right {
    right: 0 !important;
  }
  .react-multiple-carousel__arrow {
    background: none;
    opacity: 1;
    margin-top: 1.25em;
    &:hover {
      background: #777;
    }
  }
  .react-multiple-carousel__arrow::before {
    color: #23a4b8;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media only screen and (max-width: 1450px) {
    min-height: unset;
  }
`;
