import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Layout from "../layouts/DefaultLayout";
import CustomHelmet from "../components/elements/CustomHelmet";
import ThemeContext from "../contexts/ThemeContext";
import Photo from "../assets/avatar.png";
import CustomInput from "../components/elements/CustomInput";
import Button from "../components/elements/Button";
import AuthContext from "../contexts/AuthContext";
import File from "../assets/file.svg";

const AddArchive = () => {
  const { auth } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [uploading, setUploading] = useState(0);
  const [image, setImage] = useState(null);
  const { REACT_APP_BACKEND_URL } = process.env;
  const history = useHistory();

  const uploadAvatar = async (file) => {
    setUploading(1);
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/upload", {
        method: "POST",
        body: formData,
      });
      const res = await response.json();
      console.log(res);
      if (res.path) {
        setImage(REACT_APP_BACKEND_URL + "/" + res.path);
        setUploading(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const add = async (name) => {
    try {
      const secondRes = await fetch(REACT_APP_BACKEND_URL + "/docs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          id: auth.id,
          url: image !== null ? image.replace(REACT_APP_BACKEND_URL, "") : null,
        }),
      });
      const res = await secondRes.json();

      if (res.message) {
        history.push("/archive");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <Container>
        <CustomHelmet title="Ajouter archive" />
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Champs Obligatoire"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            await add(data.name);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className="form">
              <h3 className="profil">Ajouter Document</h3>
              <label htmlFor="file-input" className="file-label">
                <div className="loader-doc">
                  <img src={File} />
                  <span className="popover">Ajouter le document</span>
                </div>
                {uploading ? (
                  <div className="loader" title="2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12.819 14.427c.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12h.496c.474 0 .803.173.916.644zm3.091-8.65c2.047-.479 4.805.279 6.09 1.179-1.494-1.997-5.23-5.708-7.432-6.882 1.157 1.168 1.563 4.235 1.342 5.703zm-7.457 7.955h-.546v.943h.546c.235 0 .467-.027.576-.227.067-.123.067-.366 0-.489-.109-.198-.341-.227-.576-.227zm13.547-2.732v13h-20v-24h8.409c4.858 0 3.334 8 3.334 8 3.011-.745 8.257-.42 8.257 3zm-12.108 2.761c-.16-.484-.606-.761-1.224-.761h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.094-.292.094-.597 0-.885zm3.407-.303c-.297-.299-.711-.458-1.199-.458h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.554-.659.586-2.035-.063-2.693zm3.701-.458h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784z" />
                    </svg>
                  </div>
                ) : null}
                {/* <img src={image || Photo} alt="avatar" className="avatar" /> */}
                <span className="popover">Ajouter le document</span>
              </label>
              <input
                type="file"
                name="file"
                id="file-input"
                onChange={(e) => uploadAvatar(e.target.files[0])}
              />
              <div className="wrp">
                <CustomInput
                  margin="0 0.5em"
                  label="Nom d'archive"
                  name="name"
                  id="name"
                  type="text"
                />
              </div>
              <div className="btn-wrp">
                <Button
                  handleClick={handleSubmit}
                  title={isSubmitting ? "Enregister..." : "Enregister"}
                  type="submit"
                  radius="7px"
                />
                <br></br>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default AddArchive;

const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 650px;
  margin: auto;
  margin-top: 2em;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .avatar {
    width: 200px;
    height: 150px;
    border-radius: 12px;
    object-fit: cover;
  }
  .right-side {
    max-width: 750px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 1em;
  }

  input[type="email"],
  input[type="password"] {
    width: 100%;
  }
  input,
  textarea {
    width: 100% !important;
  }
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
    padding: 3em 2em 0.5em 2em;
    border-bottom: 1px solid #e1e5eb !important;
  }
  .profil {
    padding: 1.5em 2em 0.5em 2em;
  }
  form {
    width: 100%;
  }
  #file-input {
    display: none;
  }
  .file-label {
    margin-top: 1em;
  }
  .btn {
    position: relative;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border: 1px solid #f84b60;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    color: #f84b60;
    cursor: pointer;
    svg {
      margin-left: 5px;
    }
  }
  p {
    padding: 0.75em 1em;
    text-align: justify;
    color: #5a6169;
  }
  label {
    position: relative;
  }
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -65px;
    top: -5px;
    display: none;
    &:before {
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .active {
    display: block;
  }
  .hidden {
    width: 0;
    height: 0;
    position: absolute;
  }
  .error {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .avatar:hover + span {
    display: block;
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -110px;
    top: 35px;
    display: none;
    width: 100px;
    line-height: 1.5em;
    text-align: justify;
    &:before {
      top: 18px;
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
  .wrp {
    padding: 0 2.5em 0 2em;
    width: 100%;
  }
  @media only screen and (max-width: 576px) {
    .sub-form {
      padding: 0 1em;
    }
  }
`;
