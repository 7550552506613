import React, { createContext, useState, useEffect } from "react";
import jwt from "jsonwebtoken";

const querystring = require("querystring");
// const GetToken = async () => {
//   try {
//     const response = await fetch(
//       "https://no-cors-proxy-app.herokuapp.com/http://wixautwso2-api.autohall.ma:8280/token",
//       {
//         method: "POST",
//         headers: {
//           Authorization:
//             "Basic aDZOSXdFWVhxbEV4MV9UemFkNjROaUY3cDlBYTplZUxwc0oyVERXODlqOHRXRTBpZld4R2VXWDhh",
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//         body: querystring.stringify({ grant_type: "client_credentials" }),
//       }
//     );

//     const res = await response.json();
//     res.expires_in = res.expires_in * 1000 + Date.now();
//     return res;
//   } catch (err) {
//     console.log(err);
//   }
// };

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { REACT_APP_NEW_BACKEND_URL, REACT_APP_JWT_TOKEN } = process.env;
  const [auth, setAuth] = useState({});
  const [apiToken, setApiToken] = useState({});

  const checkUser = () => {
    if (localStorage.getItem("auth") !== null) {
      try {
        const userToken = JSON.parse(localStorage.getItem("auth"));
        jwt.verify(userToken.token, REACT_APP_JWT_TOKEN, (err, decoded) => {
          if (decoded) {
            setAuth({...decoded});
          }
        });
      } catch (err) {
        console.log(err.message);
      }
    }
  };
  const updateUser = (update) => {
    const Obj = { ...auth, ...update };
    console.log(JSON.stringify(Obj));
    const token = jwt.sign(Obj, REACT_APP_JWT_TOKEN);
    localStorage.removeItem("auth");
    localStorage.setItem("auth", JSON.stringify({ token }));
    setAuth({ ...auth, ...Obj });
  };
  const authContext = {
    auth,
    setAuth,
    apiToken,
    setApiToken,
    updateUser,
  };

  useEffect(() => {
    checkUser();
    // const checkToken = async () => {
    //   if (sessionStorage.getItem("apiToken") === null) {
    //     const token = await GetToken();
    //     setApiToken(token);
    //     sessionStorage.setItem("apiToken", JSON.stringify(token));
    //     // sessionStorage.setItem("apiToken", token);
    //   }
    //   if (sessionStorage.getItem("apiToken") !== null) {
    //     const t = JSON.parse(sessionStorage.getItem("apiToken"));
    //     if (t.expires_in - 60000 >= Date.now()) {
    //       sessionStorage.removeItem("apiToken");
    //       const newToken = await GetToken();
    //       setApiToken(newToken);
    //       sessionStorage.setItem("apiToken", JSON.stringify(newToken));
    //     }
    //     setApiToken(t);
    //   }
    // };
    // checkToken();
  }, []);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
