import React from "react";
import styled from "styled-components";
import Car from "../assets/ford.png";

const CarInfo = ({ imtr, Nbr, modele, year, status, img, color }) => {
  const [imgUlr, setImgUrl] = React.useState();
  const { REACT_APP_BACKEND_URL } = process.env;

  React.useEffect(() => {
    fetch(`${REACT_APP_BACKEND_URL}/api/model/${modele}`)
      .then((resp) => resp.json())
      .then((respJson) => setImgUrl(respJson.data.image))
      .then((n) => console.log(imgUrl))
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <Container color={color}>
      <h2>Informations Générales</h2>
      <div className="info-row">
        <div className="info-row-content">
          <p className="label">Plaque d'Immatriculation: </p>
          <p className="data">{imtr}</p>
          <p className="label">Numéro de Chassis: </p>
          <p className="data">{Nbr}</p>
          <p className="label">Modèle: </p>
          <p className="data">{modele}</p>
          <p className="label">Année modèle: </p>
          <p className="data">{year}</p>
        </div>
        <div className="car">
          {imgUlr ? <img src={imgUlr} className="img" /> : null}
        </div>
      </div>
    </Container>
  );
};

export default CarInfo;

const Container = styled.div`
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  background-color: #fff;
  margin: 2em;
  padding: 2em;
  border-radius: 20px;
  .info-row {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 25px;
  }
  .info-row-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .data {
    color: ${(props) => (props.color ? props.color : "#2d4185")};
    font-size: 16px;
    font-weight: 500;
    padding: 0.5em 0;
    text-align: right;
  }
  .label {
    padding: 0.5em 0;
    font-size: 16px;
  }
  h2 {
    margin-bottom: 0.75em;
  }
  .car {
    display: grid;
    justify-items: right;
  }
  img {
    width: 80%;
  }
  @media only screen and (max-width: 1200px) {
    .info-row {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .car {
      display: flex;
      margin-bottom: 2em;
    }
    img {
      width: 100%;
    }
  }
  @media only screen and (max-width: 576px) {
    margin: 2em 0.5em;
    padding: 2em 0.5em;
    p {
      font-size: 14px !important;
    }
  }
`;
