import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Layout from "../layouts/DefaultLayout";
import CustomInput from "../components/elements/CustomInput";
import Button from "../components/elements/Button";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import Message from "../components/elements/Message";

const AddComplaint = () => {
  const { theme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const { REACT_APP_BACKEND_URL } = process.env;
  const store = localStorage.getItem("selectedCar");
  const selectedCar = JSON.parse(store);

  const postComplaint = async (data) => {
    const { email, name, phone, subject, message } = data;

    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/reclame`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idReclamation: Date.now(),
          idClient: auth.Custno,
          source: "espace client",
          modele: selectedCar.ModelVehi,
          marque: selectedCar.MrqVehi,
          vin:selectedCar.NumSerie,
          nom: name,
          email: email,
          telephone: phone,
          sujet: subject,
          message: message,
        }),
      });

      const res = await response.json();
      console.log(res);

      setOpen(true);
      if (res.status == 400) {
        setError("error");
      }else{
        setError(false);
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setOpen(true);
    }
  };

  return (
    <Layout>
      <Container>
        <Formik
          initialValues={{
            name: auth.fname,
            email: auth.email,
            phone: auth.user_phone,
            subject: "",
            message: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Champs Obligatoire"),
            email: Yup.string()
              .email("Invalid email adddress")
              .required("Champs Obligatoire"),
            phone: Yup.string().required("Champs Obligatoire"),
            subject: Yup.string().required("Champs Obligatoire"),
            message: Yup.string().required("Champs Obligatoire"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            postComplaint(data);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className="form">
              <h3 className="border">Réclamation Form</h3>
              <div className="sub-form">
                <CustomInput
                  margin="0 0.5em"
                  label="Name"
                  name="name"
                  id="name"
                  bg="#f2f2f2"
                  type="text"
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Email"
                  name="email"
                  id="email"
                  type="email"
                  bg="#f2f2f2"
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Telephone"
                  name="phone"
                  id="phone"
                  type="text"
                  bg="#f2f2f2"
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Sujet"
                  name="subject"
                  id="subject"
                  type="text"
                  bg="#f2f2f2"
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Message"
                  name="message"
                  id="message"
                  type="text"
                  bg="#f2f2f2"
                  textarea
                />
                {open ? <Message error={error} setOpen={setOpen} recl /> : null}
                <div className="btn-wrp">
                  <Button
                    handleClick={handleSubmit}
                    title={isSubmitting ? "Enregister..." : "Enregister"}
                    type="submit"
                    radius="7px"
                    bg={theme?.color}
                    color="#fff"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default AddComplaint;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  width: 100%;
  max-width: 600px;
  margin: 3em auto;
  padding: 1em 0 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-name {
    font-size: 18px;
    font-weight: 600;
    display: none;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
  }
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
    padding: 0 1em;
  }
  .border {
    border-bottom: 1px solid #e1e5eb !important;
    padding: 0.8em 1em;
  }
  form {
    width: 100%;
  }
  input {
    width: 100%;
  }
  .sub-form {
    padding: 0 1em;
  }
  textarea {
    width: 100%;
  }
`;
