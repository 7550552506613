import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Photo from "../assets/ford.png";
import Ford2 from "../assets/ford4.png";
import Spinner from "./Spinner";

const DashCarDetailsCart = ({
  img,
  name,
  matr,
  marque,
  dateMEC,
  modele,
  version,
}) => {
  const {REACT_APP_BACKEND_URL} = process.env;
  const [imgF, setImgF] = useState(null);
  const [isFetched, setIsFetched]  = useState(false);
  useEffect(() => {
    const fetchImg = async () => {
    
      try{
        const resp  = await fetch(`${REACT_APP_BACKEND_URL}/api/model/${decodeURI(modele)}`);
        const respJson = await resp.json();
         if(respJson.status === 200){
          setImgF (respJson.data.image)
          setIsFetched(true);
          localStorage.setItem("imgCar", respJson.data.image);
        }
        
        
      }catch(e){
        console.log(e.message);
      }
      
    }
    fetchImg();
  }, [])
  return (
    <Container>
      <h4>{name}</h4>
      <div className="row">
        <div className="img-wrp">
          {
            isFetched ?
            imgF ? <img src={imgF} alt="icon" className="car" /> : <img src={Ford2} alt="icon" className="car" />
            : <Spinner />
          } 
        </div>
        <div className="infos">
          <div className="row info-row">
            <h6>Immatriculation</h6>
            <h5>{matr}</h5>
          </div>
          <div className="row info-row">
            <h6>Marque</h6>
            <h5>{marque}</h5>
          </div>
          <div className="row info-row">
            <h6>Date de mise en circulation</h6>
            <h5>{dateMEC}</h5>
          </div>
          <div className="row info-row">
            <h6>Modèle</h6>
            <h5>{modele}</h5>
          </div>
          <div className="row info-row">
            <h6>Version</h6>
            <h5>{version}</h5>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DashCarDetailsCart;

const Container = styled.div`
  width: 100%;
  padding: 0 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .img-wrp,
  .infos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    width: 100%;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 1.25em;
    text-align: center;
    font-weight: 600;
  }
  .car {
    width: 100%;
    border-radius: 12px;
  }
  .info-row {
    justify-content: space-between;
    padding: 0.25em 0;
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    white-space: nowrap;
  }
  h6 {
    font-size: 14px;
    font-weight: 500;
    color: #726e6e;
  }
  img {
    max-height: 160px;
    object-fit: contain;
  }
  @media only screen and (max-width: 450px) {
    .row {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .infos {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    padding: 0 0.5em;
  }
`;
