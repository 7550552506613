import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import DashCarDetails from "../components/DashCarDetails";
import DashFidelite from "../components/DashFidelite";
import DashRv from "../components/DashRv";
import CustomHelmet from "../components/elements/CustomHelmet";
import DarkMap from "../components/NewMap";
import Banner from "../assets/banner.png";
import DashEntretienDetail from "../components/DashEntretientDetail";
import DashCarStatus from "../components/DashCarStatus";
import UserContracts from "../components/UserContracts";
import ContactAssistant from "../components/ContactAssistance";
import Carousel from "react-multi-carousel";
import Layout from "../layouts/DefaultLayout";
import "react-multi-carousel/lib/styles.css";
import Support from "../assets/support.svg";
import Spinner from "../components/Spinner";

const Dashboard = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1700 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1700, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const brandID = 1;
  const userID = 1;
  const { REACT_APP_BACKEND_URL, REACT_APP_API_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const [scenarios, setScenarios] = useState([]);
  const [pointsData, setPointsDate] = useState({});
  const [offreData, setOffreDate] = useState([]);
  const [rdvList, setRdvList] = useState([]);
  const [rvsError, setRvsErrors] = useState(false);
  const [entretien, setEntretien] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);
  const [carStatus, setCarStatus] = useState({});
  const [phone, setPhone] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [newKilometrage, setNewKilometrage] = useState(null);
  const [isKiloLoaded, setIsKiloLoaded] = useState(false);
  const [errKilo, setErrKilo] = useState(null);
  const themeSelected = JSON.parse(localStorage.getItem("theme"));

  const getRvScenario = async () => {
    try {
      const result = await fetch(`${REACT_APP_BACKEND_URL}/rvScenarios`, {
        method: "GET",
        headers: { "content-type": "application/json" },
      });
      const res = await result.json();
      let array = [];
      res.map((item) => {
        array.push({
          name:
            item.rvScenario_name === "Comercial"
              ? "Commercial"
              : item.rvScenario_name,
          value: item.rvScenario_name,
        });
      });
      setScenarios(array);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCarStatus = async (id) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/client-car-status/${parseInt(id)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setCarStatus(result);
    } catch (err) {
      console.log(err);
    }
  };
  const getAssistatPhone = async (id) => {
    try {
      const resp1 = await fetch(
        `${REACT_APP_BACKEND_URL}/getbrandid/${themeSelected.name}
        `
      );
      const respJson1 = await resp1.json();

      const resp2 = await fetch(
        `${REACT_APP_BACKEND_URL}/getassistantphonr/${respJson1.data.idMarque}`
      );
      const respJson2 = await resp2.json();

      console.log(respJson1);
      console.log(respJson2);

      if (respJson2.telassistance) {
        setPhone(respJson2.telassistance);
      }

      setIsFetched(true);
    } catch (e) {
      console.log(e.message);
    }
  };

  const getEntretien = async (km) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/getEntretien/${km}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setEntretien(result?.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getRvList = async () => {
    if (typeof auth.user_phone !== "undefined") {
      try {
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/api/getRvList/${auth.user_phone}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();
        let arr = result.data;

        arr = arr.filter((item) => item.date !== null);
        arr = arr.sort((a, b) => {
          return b.date - a.date;
        });
        setRdvList(arr);
        setRvsErrors(false);
      } catch (err) {
        console.log(err.message);
        setRvsErrors(true);
      }
    }
  };

  const getOffers = () => {
    fetch(`${REACT_APP_BACKEND_URL}/api/getOffres/${brandID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          setOffreDate(data.data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };

  const getPoints = () => {
    fetch(`${REACT_APP_BACKEND_URL}/api/getPoints/${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          setPointsDate(data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };

  useEffect(() => {
    getRvScenario();
    // getPoints();
    getOffers();
  }, []);

  useEffect(() => {
    if (themeSelected) {
      getAssistatPhone(themeSelected.id);
    }
  }, []);

  useEffect(() => {
    let timer;
    try {
      let car = JSON.parse(localStorage.getItem("selectedCar"));
      timer = setInterval(() => {
        getCarStatus(car?.Carno);
      }, 50000);
    } catch (err) {
      console.log(err.message);
    }

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    let car = JSON.parse(localStorage.getItem("selectedCar"));
    getCarStatus(car?.Carno);
  }, []);

  useEffect(() => {
    const fetchKilometrage = async (id) => {
      try {
        const resp = await fetch(
          `${REACT_APP_BACKEND_URL}/show-kilometrage/${id}`,
          {
            method: "GET",
          }
        );
        const respJson = await resp.json();
        if (respJson.code === 200) {
          setNewKilometrage(respJson.data.kilometrage_actuel);
          localStorage.setItem("odoValue", respJson.data.kilometrage_actuel);
          setIsLoaded(true);
        } else {
          setIsLoaded(true);
          setErrKilo("ID voiture non trouvé");
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    if (selectedCar) {
      fetchKilometrage(selectedCar.Carno);
    }
  }, [selectedCar]);

  useEffect(() => {
    getRvList();
    if (localStorage.getItem("selectedCar")) {
      try {
        const t = JSON.parse(localStorage.getItem("selectedCar"));
        setSelectedCar(t);
        getEntretien(t?.KlmVeh);
      } catch (error) {
        console.log(error.message);
      }
    }
  }, [auth.user_phone]);

  return (
    <Layout>
      <Container>
        <CustomHelmet title="Dashboard" />
        <div className="row-1">
          <DashCarDetails />
          <DashEntretienDetail
            color={theme?.color}
            className="alpha"
            km={entretien}
            kmactuel={newKilometrage ? newKilometrage : null}
            IDVehicule={selectedCar?.Carno}
            setIsLoaded={setIsLoaded}
            isLoaded={isLoaded}
            setIsKiloLoaded={setIsKiloLoaded}
            isKiloLoaded={isKiloLoaded}
            setNewKilometrage={setNewKilometrage}
            errKilo={errKilo}
          />
        </div>
        <div className="row-4">
          {/* <DashFidelite points={pointsData} /> */}
        </div>
        <div className="row row-2">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            arrows={true}
            responsive={responsive}
            ssr={true}
            autoPlay={false}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="all 1.5s"
            transitionDuration={1000}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {offreData.map((item, index) => {
              return (
                <a href={item.link} target="_blank" key={index}>
                  <div className="offre-elements">
                    <img
                      src={item.image ? item.image : Banner}
                      alt="Banner"
                      className="banner"
                    />
                  </div>
                </a>
              );
            })}
          </Carousel>
        </div>
        <DashCarStatus data={carStatus} />
        <div className="row-3">
          <DashRv scenarios={scenarios} rdvList={rdvList} rvsError={rvsError} />
          <div className="map-wrp" style={{ backgroundColor: theme.color }}>
            <h5>Assistance</h5>
            <div className="contact-wrp">
              <div className="img-c">
                <img src={Support} />
              </div>
              {isFetched ? (
                phone ? (
                  <p>{phone}</p>
                ) : (
                  <p>Le numéro est indisponible</p>
                )
              ) : (
                <p>Loading</p>
              )}
            </div>
            {/* <div className="map-wrp-styled">
              <DarkMap
                search="Auto Hall Agadir I"
                minhieght={"500px"}
                height={"100%"}
                margin="0px"
                parent="dash"
              />
            </div> */}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Dashboard;

const Container = styled.div`
  padding: 0em 1em 0 1em;
  width: 100%;
  .row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
    .car-status {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 12px;
      padding: 1em 0;
      margin: 0.25em 0.5em 0.5em 0;
      box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
        rgb(237 239 247 / 47%) 0px 6px 6px;
    }
  }
  .row-3 {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .row-4 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .testing-grids {
    display: grid;
    grid-template-columns: 60% 40%;
  }
  .row {
    width: 100%;
    display: flex;
  }
  .bottom-row {
    display: grid;
    grid-template-columns: 70% 30%;
  }
  .banner {
    width: 100%;
    border-radius: 15px;
    object-fit: contain;
    height: 100%;
    margin: 0.5em 0;
  }
  .page-name {
    font-size: 18px;
    font-weight: 600;
    display: none;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
  }
  .offre-elements {
    margin: 0.5em 0;
    width: calc(100vw - 2em - 250px);
    cursor: pointer;
    height: calc((100vw - 240px - 2.5em) / 3.178);
  }
  .react-multi-carousel-list {
    width: 100% !important;
    height: 100% !important;
    li {
      margin-right: 0.25em;
    }
  }
  .map-wrp {
    background: #fff;
    border-radius: 12px;
    padding: 1em;
    margin: 1em 0.5em 0.75em 0;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-c {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 120px;
      margin: 20px 0;
    }
    img {
      height: 100%;
    }
    h5 {
      font-size: 18px;
      padding: 0em 0.5em 0.75em;
      font-weight: 600;
      color: #fff;

      text-align: center;
      white-space: nowrap;
      overflow: hidden;
    }
    p {
      text-align: center;
      color: #fff;
      font-weight: bold;
      margin-top: 20px;
      margin: 0;
      letter-spacing: 3px;
      align-self: stretch;
      font-size: 25px;
    }
    .map-wrp-styled {
      border-radius: 12px;
      overflow: hidden;
    }
  }
  .assitant-title,
  .assitant-number {
    font-size: 0.8rem !important;
    padding-left: 0px !important;
    margin-left: 2rem !important;
  }
  .assitant-number {
    color: #9fa2b4 !important;
  }
  .contact-number-wrp {
    margin: 1em 0;
    display: flex;
    align-items: center;
    > div {
      padding-left: 0.5em;
      border-left: 2px solid #dfe0eb;
    }
    svg {
      margin-right: 1em !important;
    }
  }
  //temprary-div
  .temprary-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    margin: 0.25em 0.5em 0.5em 0;
  }
  @media only screen and (max-width: 1450px) {
    .row-1 {
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
    }
    .row-4 {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      .entretien-compoenent {
        grid-column: 1/3;
      }
    }
    .testing-grids {
      display: grid;
      grid-template-columns: 65% 35%;
    }
  }
  @media only screen and (max-width: 1200px) {
    .row-3 {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 1fr auto;
    }
    .row-4 {
      grid-template-columns: 100%;
      grid-template-rows: auto;
      .entretien-compoenent {
        grid-column: 1/2;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .banner {
      object-fit: cover;
    }
    .offre-elements {
      width: calc(100vw - 3em);
      height: 200px !important;
    }
    .page-name {
      display: block;
    }
  }
  @media only screen and (max-width: 500px) {
    .bottom-row {
      display: grid;
      grid-template-columns: 100%;
    }
    .offre-elements {
      height: 150px !important;
    }
  }
  @media only screen and (max-width: 576px) {
    padding: 0 0.5em;
    .banner {
      width: calc(100vw - 2.5em);
    }
    .testing-grids {
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
    }
  }
`;
