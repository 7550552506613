import React, { useState } from "react";
import styled from "styled-components";
import Car from "../../assets/ford.png";
import Condition from "../Condition";

const SixthStep = ({
  values,
  selectedTime,
  handleBlur,
  handleChange,
  date,
  senario,
  intervention,
  sucursale,
  sucursales,
  models,
  model,
  getModel,
  senarioType,
  accepted,
  setaccepted,
  msg,
}) => {
  const getSucursale = () => {
    let tempVar = null;
    sucursales.map((item) => {
      if (item.id == sucursale.id) {
        tempVar = item.nom;
      }
    });

    return tempVar;
  };
  const selectedC = JSON.parse(localStorage.getItem("selectedCar"));
  const timeArr = selectedTime.split(":");
  const imgCar = localStorage.getItem("imgCar");
  const [open, setOpen] = useState(false);
  return (
    <Container>
      {open ? <Condition setOpen={setOpen} /> : null}
      <div className="grid">
        <div className="card">
          <h5>Vos Informations</h5>
          <hr className="bottom-border" />
          <div className="grid-info">
            <h6>Prénom: </h6>
            <h6>{values.fname}</h6>
            <h6>Nom: </h6>
            <h6>{values.lname}</h6>
            <h6>Email: </h6>
            <h6>{values.email}</h6>
            <h6>Téléphone: </h6>
            <h6>{values.phone}</h6>
          </div>
        </div>

        {senarioType !== "Commercial" ? (
          <div className="card">
            <h5>Immatriculation et modèle</h5>
            <hr className="bottom-border" />
            <div className="car-incon-cart">
              <div className="grid-info">
                <h6>Matricule: </h6>
                <h6 className="mtr">
                  {`   ${values.mtr1}-${values.mtr2}-${values.mtr3}`}
                </h6>
                <h6>№ série:</h6>
                <h6 className="h6-nbr">{values.nbr}</h6>
                <h6>Modèle:</h6>
                <h6>{selectedC.ModelVehi}</h6>
                <h6 className="blank"> </h6>
                <h6 className="blank"> </h6>
              </div>
              <div className="car-icon-wrp">
                <img
                  src={imgCar ? imgCar : Car}
                  className="car-icon"
                  alt="car"
                />
              </div>
            </div>
          </div>
        ) : null}

        {senarioType !== "Commercial" ? (
          <div className="card">
            <h5>Type d'intervention</h5>
            <hr className="bottom-border" />
            <div className="grid-info">
              <h6>Type:</h6>
              <h6>{intervention.rvIntervention_name}</h6>
            </div>
          </div>
        ) : null}

        <div className="card">
          <h5>Concessionnaire le plus proche</h5>
          <hr className="bottom-border" />
          <div className="grid-info">
            <h6>Succursale : </h6>
            <h6>{sucursale.nom}</h6>
          </div>
        </div>

        <div className="card">
          <h5>Date et l'heure de rendez-vous</h5>
          <hr className="bottom-border" />
          <h6 className="date">
            {date + " à " + timeArr[0] + "h:" + timeArr[1] + "min"}{" "}
          </h6>
        </div>
      </div>

      <div className="checkbox-wrp">
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              setaccepted(true);
            } else {
              setaccepted(false);
            }
          }}
          checked={accepted}
        />
        <h6 className="h6-checkbox">
          J'ai lu et j'accepte{" "}
          <span className="lien" onClick={() => setOpen(!open)}>
            les conditions générales d'utilisation
          </span>
          , notamment la mention relative à la protection des données
          personnelles.
        </h6>
      </div>
      {!accepted ? (
        <p style={{ display: "block", color: "red" }}>{msg}</p>
      ) : null}
    </Container>
  );
};

export default SixthStep;

const Container = styled.div`
  margin-top: 2em;
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
    display: grid;
  }
  .car-incon-cart {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .car-icon-wrp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    .car-icon {
      max-width: 180px;
      margin-bottom: 1em;
    }
  }
  h2 {
    font-size: 28px;
    color: ${(props) => props.color};
  }
  .bottom-border {
    width: 60px;
    height: 4px;
    border-radius: 4px;
    margin-bottom: 1em;
    margin-top: 0.25em;
    background-color: ${(props) => props.color};
    border: none;
  }
  h5 {
    font-size: 20px;
    text-transform: unset !important;
    color: ${(props) => props.color};
  }
  h6 {
    font-weight: 400;
    font-size: 17px;
    color: #0a0c50;
    text-transform: unset !important;
    margin: 0.15em 0;
  }
  .date {
    text-transform: capitalize !important;
  }
  .grid-info {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
  }
  .blank {
    min-height: 27px;
  }
  .checkbox-wrp {
    margin-top: 2.7em;
    display: flex;
  }
  input[type="checkbox"] {
    margin-right: 1em;
    margin-top: 0.5em;
    width: unset;
  }
  .h6-checkbox {
    font-size: 15px;
    text-align: justify;
    max-width: 900px;
    .lien {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 992px) {
    .grid {
      display: block;
    }
    .car-icon-wrp {
      padding: 0.5em 0;
    }
    h5 {
      margin-top: 2em;
    }
  }
  @media only screen and (max-width: 576px) {
    .car-incon-cart {
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      .car-icon-wrp {
        grid-row: 1/2;
        width: 250px;
      }
      .grid-info {
        grid-row: 2/3;
      }
    }
  }
`;
