import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import AuthContext from "../contexts/AuthContext";
import * as Yup from "yup";
import styled from "styled-components";
import Logo from "../assets/ford-logo.png";
import LogoAuoHall from '../assets/autohall.png';

const LoginForm = ({ logo }) => {
  let history = useHistory();
  const { auth, setAuth } = useContext(AuthContext);
  const [errorsAuth, setErrorsAuth] = useState(false);
  const [isActivated, setIsActivated] = useState(true);
  const { REACT_APP_BACKEND_URL, REACT_APP_JWT_TOKEN } = process.env;

  // const login = async (email, pwd) => {
  //   if (email && pwd) {
  //     try {
  //       const response = await fetch(`${REACT_APP_BACKEND_URL}/login/`, {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           email,
  //           pwd,
  //         }),
  //       });

  //       const res = await response.json();
  //       console.log(res);

  //       if (res.token) {
  //         // getClientInfo(1);
  //         history.push("/car-choice");
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  const login2 = async (email, pwd) => {
    if (email && pwd) {
      try {
        const response = await fetch(`${REACT_APP_BACKEND_URL}/login2/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email,
            pwd,
          }),
        });

        const res = await response.json();
        console.log("Ops", res);

        if (res.token) {
          getClientInfo2(email);
        } else if (res.msg) {
          setErrorsAuth(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  //temporary fix, getting one user from the db for testing only
  // const getClientInfo = (id) => {
  //   fetch(`${REACT_APP_BACKEND_URL}/getClient/${id}`, {
  //     method: "GET",
  //     headers: { "content-type": "application/json" },
  //   })
  //     .then((response) => {
  //       response.json().then(function (data) {
  //         localStorage.setItem("auth", JSON.stringify(data));
  //         jwt.verify(data.token, REACT_APP_JWT_TOKEN, (err, decoded) => {
  //           if (decoded) {
  //             setAuth(decoded);
  //             history.push("/car-choice");
  //           }
  //         });
  //       });
  //     })
  //     .catch(function (err) {
  //       console.log("Fetch Error :-S", err);
  //     });
  // };
  const getClientInfo2 = (id) => {
    fetch(`${REACT_APP_BACKEND_URL}/getClient2/${id}`, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((response) => {
        response.json().then(function (data) {
          localStorage.setItem("auth", JSON.stringify(data));
          jwt.verify(data.token, REACT_APP_JWT_TOKEN, (err, decoded) => {
            if (decoded.user_status) {
              setAuth(decoded);
              
              history.push({
                pathname: "/car-choice",
                state: { userdata: decoded },
              });
            }else{
              setIsActivated(false);
            }
          });
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };
  const handleSubmit = async (email, pass) => {
    login2(email, pass);
  };
  const togglePassword = () => {
    const input = document.getElementById("pwd");
    if (input) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  };
  return (
    <Container className="login-form">
      <img src={LogoAuoHall} alt="logo" className="logo" />
      <Formik
        initialValues={{
          email: "",
          pwd: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().required("Champ obligatoire"),
          pwd: Yup.string().required("Champ obligatoire"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(data.email, data.pwd);
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, isSubmitting }) => (
          <Form>
            <div className="input-wrp">
              <input
                className="input"
                name="email"
                id="email"
                type=" email"
                placeholder="Identifiant client"
                value={values.email}
                onChange={handleChange("email")}
              />
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#7f7f7f"
              >
                <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
              </svg>
              {errors ? <div className="error">{errors.email}</div> : null}
            </div>
            <div className="input-wrp">
              <input
                className="input"
                name="pwd"
                id="pwd"
                type="password"
                placeholder="Mot de passe"
                value={values.pwd}
                onChange={handleChange("pwd")}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.875"
                height="14"
                viewBox="0 0 18.875 14"
                onClick={() => togglePassword()}
              >
                <g id="eye" transform="translate(-0.5 -3.5)">
                  <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M1,10.5S4.25,4,9.938,4s8.937,6.5,8.937,6.5S15.625,17,9.938,17,1,10.5,1,10.5Z"
                    fill="none"
                    stroke="#7f7f7f"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <circle
                    id="Ellipse_18"
                    data-name="Ellipse 18"
                    cx="3"
                    cy="3"
                    r="3"
                    transform="translate(7 7.5)"
                    strokeWidth="1"
                    stroke="#7f7f7f"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  />
                </g>
              </svg>
              {errors ? <div className="error">{errors.pwd}</div> : null}
              {errorsAuth ? (
                <div className="error">
                  error dans le mot de passe ou l'identifiant
                </div>
              ) : null}
              {!isActivated ? (
                <div className="error">
                  Vous deverez activer votre compte
                </div>
              ) : null}
            </div>
            <Link to="/password/reset">
              <p>Mot de passe oublié ?</p>
            </Link>
            <button type="submit">
              {isSubmitting ? "Loading..." : "Se Connecter"}
            </button>
            <Link to="/activer-choix">
              <p>Activer mon compte ?</p>
            </Link>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LoginForm;

const Container = styled.div`
  z-index: 1;
  background: #fff;
  border-radius: 20px;
  padding: 3.5em 2em;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  height: fit-content;
  width: 100%;

  .logo {
    margin-bottom: 2em;
  }
  p {
    color: #808080;
    font-size: 13px;
    margin: 0 0 0.25em auto;
  }
  .align-to-right {
    margin: 0 0 0.25em auto;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .input {
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 0.75em 0;
  }
  button {
    background: #f0f5fd;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 2em 0;
    color: #919192;
  }
  .input-wrp {
    position: relative;
    width: 100%;
  }
  svg {
    position: absolute;
    right: 0.75em;
    top: 1.7em;
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  @media only screen and (max-width: 992px) {
    padding: 3.5em 1em;
  }
`;
