import React from "react";
import styled from "styled-components";

const DetailButton = ({ title, width, type, margin, radius, padding }) => {
  return (
    <Container
      width={width}
      margin={margin}
      radius={radius}
      padding={padding}
      //onClick={isSelected}
    >
      <button type={type} /*className={selected ? "selected" : "close"}*/>
        {title}
      </button>
    </Container>
  );
};

export default DetailButton;

const Container = styled.div`
  width: ${(props) => (props.width === "large" ? "100%" : "auto")};
  font-family: inherit;
  button {
    font-family: inherit;
    padding: ${(props) => (props.padding ? props.padding : "10px 32px")};
    border-radius: ${(props) => (props.radius ? props.radius : "10px")};
    cursor: pointer;
    transition: all 0.5s;
    width: ${(props) => (props.width === "large" ? "100%" : "auto")};
    cursor: pointer;
    font-size: 14px;
    color: #3751ff;
    background-color: #fff;
    font-weight: 500;
    padding: 10px;
    border-radius: 7px;
  }
`;
