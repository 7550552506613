import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { alphabets } from "../../utils/arabeAlphabets";
import CarDetailBlock from "./CarDetailBlock";
import Mrc from "../../assets/mrc.png";
import CustomInput from "./CustomInput";
import Car from "../../assets/ford.png";
import Mt from "../../assets/matricule.png";

const ThirdStep = ({
  handleChange,
  handleBlur,
  values,
  errors,
  color,
  parentBrand,
  alphabets,
  brands,
  models,
  brand,
  model,
  setBrand,
  setModel,
  getVehicule,
  brVehicule,
  senario,
  step,
  setMtrMiddle,
  senarioType
}) => {
  const alphbts = {
    ا: "A",
    ب: "B",
    د: "D",
    ه: "E",
    و: "H",
    WW: "",
  };

  const [mtr, setMtr] = useState(null);
  const [showCarInfo, setShowCarInfo] = useState(0);
  const [showInputs, setShowInputs] = useState(1);

  const checkMtr = async () => {
    console.log("mtr");
    console.log(mtr);
    const res = await getVehicule(mtr.mtr1, mtr.mtr2, mtr.mtr3);
    if (res) {
      setShowInputs(0);
      setShowCarInfo(1);
    } else {
      setShowInputs(1);
      setShowCarInfo(0);
    }
  };
  return (
    <Container color={color}>
      <h2>{senarioType === "Commercial" ? "Marque et modèle souhaitées" : "Votre Immatriculation et modèle"} </h2>
      <div className="inputs">
        <div className="mtr-label-wrp">
          <div className="top">
          <label>
            Matricule <span className="red">*</span>
          </label>
          <span className="info-bulle">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              fill="#c6c6c6"
              viewBox="0 0 24 24"
              class="infobulle"
            >
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"></path>
            </svg>
            <img src={Mt} />
          </span>
          </div>
          <div className="mtr-input-wrp">
            <input
              className="mtr-input mtr-1"
              type="text"
              name="mtr1"
              onChange={handleChange}
              value={values.mtr1}
            />
            <select
              className="mtr-input mtr-2"
              name="mtr2"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setMtr({ ...mtr, mtr2: values.mtr2 });
              }}
              value={values.mtr2}
            >
             
              {alphabets.map((alph, index) => {
                console.log(alph);
                return (
                  <option value={alphbts[alph.rvAlphabets_alphabet]} key={index}>
                    {`${alph.rvAlphabets_alphabet} - ${
                      alphbts[alph.rvAlphabets_alphabet]
                    }`}
                  </option>
                );
              })}
            </select>

            <input
              className="mtr-input mtr-3"
              type="text"
              name="mtr3"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                setMtr({ ...mtr, mtr3: values.mtr3 });
                if (e.target.value && values.mtr1 !== "") {
                  checkMtr();
                }
              }}
              value={values.mtr3}
            />
            <img src={Mrc} alt="maroc" className="country" />
          </div>
          {errors.mtr1 || errors.mtr2 || errors.mtr3 ? (
            <div className="error">
              {errors.mtr1 || errors.mtr2 || errors.mtr3}
            </div>
          ) : null}
        </div>
        {showInputs ? (
          <>
            <CustomInput
              label="Numéro de série"
              name="nbr"
              id="nbr"
              type="text"
              placeholder=""
              required
              infos
              disabled
            />
            <CustomInput
              label="Marque"
              name="marque"
              id="marque"
              type="text"
              value={brVehicule.MrqVehi}
              required
              disabled
            />
            <CustomInput
              label="Modèle"
              name="model"
              id="model"
              type="text"
              value={brVehicule.ModelVehi}
              required
              disabled
            />

            <CustomInput
              label="Description "
              name="descr"
              id="descr"
              type="text"
              placeholder=""
            />
          </>
        ) : null}
      </div>
      {showCarInfo ? (
        <CarDetailBlock values={values} img={Car} color={color} />
      ) : null}
    </Container>
  );
};

export default ThirdStep;

const Container = styled.div`
  .mtr-input-wrp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    margin-top: 0.5em;
    padding: 4px 0;
    max-width: 470px;
    input {
      margin: 0 !important;
    }
  }

  .info-wrp-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .row {
    display: grid;
    grid-template-columns: 50% 50%;
    h6 {
      font-weight: 400;
      font-size: 17px;
      color: #0a0c50;
      text-transform: unset !important;
      margin: 0.15em 0;
    }
  }

  .country {
    position: absolute;
    right: 0.5em;
  }
  .mtr-input {
    padding: 6px 10px;
    font-size: 14px;
    margin-bottom: 0.35em;
  }
  .mtr-1 {
    text-align: right;
  }
  .mtr-2 {
    border: none;
    border-radius: 0px;
    border-right: 2px solid rgba(0, 0, 0, 0.05);
    border-left: 2px solid rgba(0, 0, 0, 0.05);
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0px;
    text-overflow: "";
    color: #222;
    font-weight: 500;
    margin-bottom: 0 !important;
  }
  .mtr-label-wrp {
    
    margin-top: 1.1em;
    label {
      margin-top: 0.55em !important;
    }
    .info-bulle{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &:hover{
        img{
          display: block;;
        }
      }
      svg{
        cursor: pointer;
      }
      img{
        z-index: 30;
        top: -50px;
        right: 0;
        position: absolute;
        display: none;
      }
        }
  }
  .date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4em;
    margin-bottom: 2em;
    flex-wrap: wrap;
    select {
      max-width: 170px;
      min-width: unset;
    }
    h2 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .mtr-input-wrp {
    input:focus::placeholder {
      color: transparent;
    }
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
