import React from "react";
import styled from "styled-components";

const FediliteAvantage = ({ img }) => {
  return (
    <Container>
      <div className="test">
        <img src={img} alt="offer" className="offer" />
      </div>
    </Container>
  );
};
export default FediliteAvantage;

const Container = styled.div`
  padding: 1em;
  background-color: white;
  height: 100% !important;
  .test {
    height: 100%;
  }

  .offer {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 12px;
  }
`;
