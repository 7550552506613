import React, { useEffect, useState } from "react";
import styled from "styled-components";

const UserContracts = () => {
  const [contracts, setContracts] = useState([]);
  const [errors, setEroors] = useState({ status: null, message: null });
  useEffect(() => {
    try {
      const selectedCar = JSON.parse(localStorage.getItem("selectedCar"));
      const { NumSerie } = selectedCar;
      console.log(NumSerie);
      fetch(`${process.env.REACT_APP_BACKEND_URL}/contract/${NumSerie}`, {
        method: "GET",
        headers: { "content-type": "application/json" },
      })
        .then((resp) => resp.json())
        .then((respJon) => {
          if (respJon.data) {
            setContracts(respJon.data.data);
          } else {
            setEroors({ status: 1, message: "Pas de contrats" });
          }
          console.log(respJon);
        })
        .catch((e) => {
          console.log(e.message);
          setEroors({ status: 1, message: "Pas de contrats" });
        });
    } catch (error) {
      console?.log(error.message);
      setEroors({ status: 1, message: "Pas de contrats" });
    }
  }, []);
  const dummyData = [
    {
      name: "SAFIR CLUB",
      dt: "offres supplémentaires",
      date: "02/01/2021",
    },
    {
      name: "Grentie de base",
      date: "02/01/2021",
    },
    {
      name: "Contrat d'assurance",
      date: "02/01/2021",
    },
    {
      name: "SAFIR CLUB",
      dt: "offres supplémentaires",
      date: "02/01/2021",
    },
    {
      name: "Grentie de base",
      date: "02/01/2021",
    },
    {
      name: "Contrat d'assurance",
      date: "02/01/2021",
    },
  ];
  console.log(contracts);

  return (
    <Container>
      <div className="component-title">
        <h5>Mes contrats et garentis</h5>
      </div>
      <div className="contracts-wrp table">
        {contracts.length
          ? contracts.map((item, index) => {
              return (
                <div className="contracts-row">
                  <div className="left">
                    <h4>Nom contrat ou offre</h4>
                    <h5>
                      {item?.NOMCONTRAT_OU_OFFRE
                        ? item?.NOMCONTRAT_OU_OFFRE
                        : "..."}
                    </h5>
                  </div>
                  <div className="right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"
                        fill="#2d3c85"
                      />
                    </svg>
                    <div>
                      <h4 className="gray">Date d'expiration</h4>
                      <h5 className="gray">
                        {item?.DATEDEVALIDITE ? item?.DATEDEVALIDITE : "..."}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        {errors.status && <p>{errors.message}</p>}
      </div>
    </Container>
  );
};

export default UserContracts;

const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 1em;
  margin: 0.25em 0.5em 0.5em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    color: #252733;
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .component-title {
    margin-bottom: 1em;
  }
  .contracts-wrp {
    padding: 1em 0;
    max-height: 200px;
    overflow-y: scroll;
    .contracts-row {
      padding: 0.5em 0;
      display: grid;
      grid-template-columns: 50% 50%;
      border-bottom: 1px solid #dfe0eb;
      .left,
      .right {
        width: 100%;
        .gray {
          color: #9fa2b4;
        }
        h4 {
          width: 100%;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 0.9rem;
        }
        h5 {
          width: 100%;
          color: #aaa;
          font-weight: 500;
          font-size: 0.8rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 150px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        > div {
          padding-left: 0.5em;
          border-left: 2px solid #dfe0eb !important;
        }
        svg {
          margin: 0 0.5em 0 0;
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .contracts-row {
      grid-template-columns: 100% !important;
      .left {
        margin-bottom: 0.75em;
      }
      .gray {
        font-size: 0.8rem !important;
      }
    }
  }
`;
