import React, { useState, useEffect, useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import jwt from "jsonwebtoken";
import styled from "styled-components";
import Button from "../components/elements/Button";
import Layout from "../layouts/DefaultLayout";
import CustomInput from "../components/elements/CustomInput";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import CustomHelmet from "../components/elements/CustomHelmet";
import CustomPopUp from "../components/elements/CustomPopUp";
import Message from "../components/elements/Message";
import { ReactComponent as Avatar } from "../assets/user.svg";
import ResponseStatus from "../components/elements/ResponseStatus";

const Parametres = () => {
  const { theme } = useContext(ThemeContext);
  const { auth, setAuth, updateUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  // const decodeUser = async() => {
  //   try {
  //     jwt.verify(userToken.token, REACT_APP_JWT_TOKEN, (err, decoded) => {
  //       if (decoded) {
  //         setAuth(decoded);
  //       }
  //     });
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // }
  const [uploading, setUploading] = useState(0);
  const [userInfo, setUserInfo] = useState(auth);
  const [popup, setPopup] = useState(false);
  const { REACT_APP_BACKEND_URL, REACT_APP_JWT_TOKEN } = process.env;
  const [msg, setMsg] = useState("");
  console.log(auth);

  const getDate = (data) => {
    let d = null;
    if (data) {
      d = new Date(data).toLocaleDateString("fr-CA");
    }
    return d;
  };

  const uploadAvatar = async (file, id) => {
    setUploading(1);
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/upload", {
        method: "POST",
        body: formData,
      });
      const res = await response.json();

      if (res.path) {
        const picResponse = await fetch(
          `${REACT_APP_BACKEND_URL}/updateProfilePic/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              path: `/${res.path}`,
            }),
          }
        );
        const picResult = await picResponse.json();
        if (picResult.message) {
          const Obj = { profile: `/${res.path}` };
          updateUser(Obj);
          setUploading(0);
          setOpen(true);
        }
      }
    } catch (err) {
      console.log(err);
      setOpen(true);
      setError(err.message);
    }
  };
  const updateProfileInfo = async (data, id) => {
    const newObj = {};
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/updateProfile/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
          }),
        }
      );
      const result = await response.json();
      if (result.message) {
        newObj.fname = data.fname;
        newObj.lname = data.lname;
        newObj.email = data.email;
        newObj.user_phone = data.phone;
        newObj.user_city = data.city;
        newObj.user_birthdate = data.birthdate;
        newObj.user_address = data.address;

        updateUser(newObj);
        await updateForWs(data, auth.Custno)
        
      }
    } catch (err) {
      console.log(err.message);
      setOpen(true);
      setError(err.message);
    }
  };
  const updateCredentials = async (data, id) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/updateCredentials/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...data,
          }),
        }
      );
      const result = await response.json();
      if (result.message) {
        const Obj = {
          user_username: data.username,
        };
        await updateForWs(data, auth.Custno);
      }
      setOpen(true);
    } catch (err) {
      console.log(err.message);
      setOpen(true);
      setError(err.message);
    }
  };
  const updateForWs = async (data, id) => {
    try{
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/update-client-ws`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id_client: String(id),
            addresse : data.adress,
            date_naissance: data.birthdate,

          }),
        }
      );
      const result = await response.json();
      if(result.code == 200){
        setOpen(true);
        setMsg("Les infos sont bien modifiée");
        setTimeout(() =>setOpen(false), 2000);
        
      }else{
        setOpen(true);
        setError("Erreur");
        setTimeout(() =>setOpen(false), 2000);
        
        
      }
    }catch(e){
      console.log(e)
      setOpen(true);
      setError(e.message);
    }
  }
console.log(auth);
  return (
    <Layout>
      <Container>
        <CustomHelmet title="Parametres" />

        <h5 className="page-name">Paramètres</h5>
        <div className="left-side">
          <h3 className="border">Profil</h3>
          <label htmlFor="file-input" className="file-label">
            {uploading ? (
              <div className="loader" title="2">
                <svg
                  version="1.1"
                  id="loader-1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="40px"
                  height="40px"
                  viewBox="0 0 50 50"
                  enableBackground="new 0 0 50 50;"
                >
                  <path
                    fill="#000"
                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                  >
                    <animateTransform
                      attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="0.6s"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              </div>
            ) : null}
            <div className="avatar">
              {auth.user_profile ? (
                <img
                  src={REACT_APP_BACKEND_URL + auth.user_profile}
                  alt="user profile picture"
                />
              ) : (
                <Avatar />
              )}
            </div>
            <span className="popover">Changez votre photo</span>
          </label>
          <input
            type="file"
            id="file-input"
            onChange={(e) => uploadAvatar(e.target.files[0], auth.id)}
          />
          <Formik
            enableReinitialize={true}
            initialValues={{
              username: auth.Custno || "",
              pwd: "",
            }}
            validationSchema={Yup.object({
              username: Yup.string().required("Champ obligatoire"),
              pwd: Yup.string().required("Champ obligatoire"),
            })}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              await updateCredentials(data, auth.id);
              setSubmitting(false);
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className="form first-form">
                <CustomInput
                  margin="0 0.5em"
                  label="Id Client"
                  name="username"
                  id="username"
                  bg="#f2f2f2"
                  type="text"
                />
                <CustomInput
                  margin="0 0.5em"
                  label="Password"
                  name="pwd"
                  id="pwd"
                  bg="#f2f2f2"
                  type="password"
                />
                <div className="btn-wrp">
                  <Button
                    handleClick={handleSubmit}
                    title={isSubmitting ? "Enregister..." : "Enregister"}
                    type="submit"
                    bg={theme?.color}
                    color="#fff"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="right-side">
          <h3 className="border">Informations Personnelles</h3>
          <Formik
            enableReinitialize={true}
            initialValues={{
              fname: auth.fname || "",
              lname: auth.lname || "",
              email: auth.email || "",
              address: auth.user_address || "",
              birthdate: getDate(auth.user_birthdate) || "",
              phone: auth.user_phone || "",
              city: auth.user_city || "",
            }}
            validationSchema={Yup.object({
              fname: Yup.string().required("Champ obligatoire"),
              lname: Yup.string().required("Champ obligatoire"),
              email: Yup.string().required("Champ obligatoire"),
              address: Yup.string().required("Champ obligatoire"),
              birthdate: Yup.string().required("Champ obligatoire"),
              phone: Yup.string().required("Champ obligatoire"),
              city: Yup.string().required("Champ obligatoire"),
            })}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              await updateProfileInfo(data, auth.id);
            
              setSubmitting(false);
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className="form second-form">
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Prénom"
                    name="fname"
                    bg="#f2f2f2"
                    id="fname"
                    type="text"
                    disabled
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Nom"
                    name="lname"
                    bg="#f2f2f2"
                    id="lname"
                    type="text"
                    disabled
                  />
                </div>
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Email"
                    name="email"
                    bg="#f2f2f2"
                    id="email"
                    type="text"
                    disabled
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Date de Naissance"
                    name="birthdate"
                    bg="#f2f2f2"
                    id="birthdate"
                    type="date"
                  />
                </div>
                <div className="form-row">
                  <CustomInput
                    margin="0 0.5em"
                    label="Numéro de téléphone"
                    name="phone"
                    bg="#f2f2f2"
                    id="phone"
                    type="text"
                    disabled
                  />
                  <CustomInput
                    margin="0 0.5em"
                    label="Ville"
                    bg="#f2f2f2"
                    name="city"
                    id="city"
                    type="text"
                  />
                </div>
                <div>
                  <CustomInput
                    margin="0 0.5em"
                    label="Adresse"
                    bg="#f2f2f2"
                    name="address"
                    id="address"
                    type="text"
                  />
                </div>
                <div className="btn-wrp">
                  <Button
                    handleClick={handleSubmit}
                    title={isSubmitting ? "Enregister..." : "Enregister"}
                    type="submit"
                    bg={theme?.color}
                    color="#fff"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {popup ? (
          <CustomPopUp message="Entrer voutre mot de passe pour valider">
            <Formik
              enableReinitialize={true}
              initialValues={{
                pwd: "",
              }}
              validationSchema={Yup.object({
                username: Yup.string().required("Champ obligatoire"),
                pwd: Yup.string(),
              })}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                // await updateProfile(data, id);
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form className="popup-form">
                  <CustomInput
                    margin="0 0.5em"
                    placeholder="Mot de passe"
                    name="pwd"
                    id="pwd"
                    bg="#f2f2f2"
                    type="text"
                  />

                  <div className="btn-wrp">
                    <Button
                      handleClick={handleSubmit}
                      title={isSubmitting ? "Valider..." : "Valider"}
                      type="submit"
                      bg={theme?.color}
                      color="#fff"
                      margin="0.5em"
                    />
                    <Button
                      handleClick={() => {
                        setPopup(false);
                      }}
                      title={isSubmitting ? "Annuler..." : "Annuler"}
                      type="submit"
                      bg="#ff5148"
                      color="#fff"
                      margin="0.5em"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </CustomPopUp>
        ) : null}
        {open ? (
          <ResponseStatus
            message={
              error
                ? `quelque chose de mal s’il vous plaît essayez à nouveau`
                : `Les données ont été mises à jour avec succès`
            }
            code={!error ? 1 : error ? 1 : 0}
          />
        ) : null}
      </Container>
    </Layout>
  );
};

export default Parametres;

const Container = styled.div`
  background: #f9f9f9;
  width: 100%;
  min-height: calc(100vh - 70px);
  padding: 1em 1em 1em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-name {
    font-size: 18px;
    font-weight: 600;
    display: none;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
  }
  .body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1em;
  }
  .left-side {
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    min-width: 350px;
    max-width: 420px;
    margin-bottom: 1em;
    height: fit-content;
    margin-right: 1.5em;
  }
  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    object-fit: cover;
    overflow: hidden;
    margin-bottom: 1em;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50px;
    }
  }
  .right-side {
    max-width: 750px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 1em;
  }
  input,
  textarea {
    width: 100% !important;
  }
  h3 {
    width: 100%;
    color: #868e96 !important;
    font-weight: 500;
    font-size: 16px;
  }
  .borders {
    border-bottom: 1px solid #e1e5eb !important;
    border-top: 1px solid #e1e5eb !important;
    padding: 0.8em 1em;
  }
  .border {
    border-bottom: 1px solid #e1e5eb !important;
    padding: 0.8em 1em;
  }
  form {
    padding: 0em 1em 2em 1em;
  }
  .first-form {
    margin-top: -2em;
  }
  .second-form {
    margin-top: 1.5em;
  }
  .popup-form {
    width: 100%;
    padding: 0;
  }
  #file-input {
    display: none;
  }
  .file-label {
    margin-top: 1em;
  }
  .btn {
    position: relative;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border: 1px solid #f84b60;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    color: #f84b60;
    cursor: pointer;
    svg {
      margin-left: 5px;
    }
  }
  p {
    padding: 0.75em 1em;
    text-align: justify;
    color: #5a6169;
  }
  label {
    position: relative;
  }
  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 120px;
    height: 120px;
    svg path,
    svg rect {
      fill: #f84b60;
    }
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -65px;
    top: -5px;
    display: none;
    &:before {
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .active {
    display: block;
  }
  .hidden {
    width: 0;
    height: 0;
    position: absolute;
  }
  .error {
    color: red;
    font-weight: 600;
    font-size: 12px;
  }
  .avatar:hover + span {
    display: block;
  }
  .popover {
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    font-family: "Helvetica", sans-serif;
    padding: 7px 10px;
    z-index: 4;
    position: absolute;
    left: -110px;
    top: 35px;
    display: none;
    width: 100px;
    line-height: 1.5em;
    text-align: justify;
    &:before {
      top: 18px;
      border-left: 7px solid rgba(0, 0, 0, 0.85);
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      content: "";
      display: block;
      left: 100%;
      position: absolute;
    }
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    justify-content: center;
    //align-items: flex-start;
    .left-side {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    .form-row {
      flex-direction: column;
    }
    .right-side {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 1em 0.5em;

    .page-name {
      display: block;
    }
  }
  @media only screen and (max-width: 576px) {
    form {
      padding: 0em 0.5em 2em 0.5em;
    }
    .left-side {
      min-width: unset;
    }
  }
`;
