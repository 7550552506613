import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useHistory  } from "react-router";
import * as Yup from "yup";
import styled from "styled-components";
import ResponseStatus from "../components/elements/ResponseStatus";
import Logo from "../assets/ford-logo.png";

const ResetPasswordForm = ({ logo }) => {
  const history = useHistory();
  const { REACT_APP_BACKEND_URL } = process.env;
  //default is null, found = 1, notfound = 0, error = -1
  const [feedback, setFeedback] = useState({
    show: null,
    status: null,
  });

  const checkEmail = async (email) => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/chackEmail`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();
      if (result.message === "found") {
        setFeedback({ ...feedback, show: 1, status: 1 });
        setTimeout(() => {
          setFeedback({ ...feedback, show: null, status: null });
        }, 2000);
      } else if (result.message === "notfound") {
        setFeedback({ ...feedback, show: 1, status: 0 });
        setTimeout(() => {
          setFeedback({ ...feedback, show: null, status: null });
        }, 2000);
      }
    } catch (err) {
      console.log(err.message);
      setFeedback({ ...feedback, show: 1, status: -1 });
      setTimeout(() => {
        setFeedback({ ...feedback, show: null, status: null });
      }, 2000);
    }
  };

  return (
    <Container>
      <img src={logo ? logo : Logo} alt="logo" className="logo" />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("email adddress invalide")
            .required("Champ requise"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await checkEmail(data.email);
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, isSubmitting }) => (
          <Form>
            <div className="top-content">
              <p>
                Saisissez votre adresse e-mail, et nous vous enverrons un lien
                pour revenir à votre compte.
              </p>
            </div>
            <div className="input-wrp">
              <input
                className="input"
                name="email"
                id="email"
                type=" email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange("email")}
              />
              {errors ? <div className="error">{errors.email}</div> : null}
            </div>

            <button type="submit">
              {isSubmitting ? "Envoyer..." : "Envoyer"}
            </button>
            <span onClick={() => history.push('/login')}>Retour</span>
            {feedback.show ? (
              <ResponseStatus
                bg="#222"
                code={
                  feedback.status === 1 ? null : feedback.status === 0 ? 0 : 0
                }
                message={
                  feedback.status === 1
                    ? `Nous avons envoyé un e-mail à ${values.email} avec un lien pour  changer votre mot de passe`
                    : feedback.status === 0
                    ? "Aucun utilisateur trouvé"
                    : "Un erreur est survenue, veuillez réessayer plus tard"
                }
                hidSvg={feedback.status === 1 ? true : false}
              />
            ) : null}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ResetPasswordForm;

const Container = styled.div`
  z-index: 1;
  background: #fff;
  border-radius: 20px;
  padding: 3.5em 2em;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  height: fit-content;
  width: 100%;
  .top-content {
    margin: 1em 0;
    font-size: 1rem;
    font-weight: 300;
  }
  .logo {
    margin-bottom: 2em;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .input {
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 0.75em 0;
  }
  .input-wrp {
    position: relative;
    width: 100%;
  }
  button {
    background: #f0f5fd;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin-top: 2em;
    color: #919192;
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  span{
    margin: 1em 0;
    cursor: pointer;
    color: #808080;
  }
  @media only screen and (max-width: 992px) {
    padding: 3.5em 1em;
  }
`;
