import React from "react";
import styled from "styled-components";

const ForfaitCard = ({ img, title, categorie, price, description, color }) => {
  return (
    <Container color={color}>
      <div className="left-content">
        <div className="forfait-icone">
          <img src={img} />
        </div>
      </div>
      <div className="right-content">
        <h5 className="forfait-title">{title}</h5>
        <h5 className="forfait-categorie">{categorie}</h5>
        <p className="p-tags">{description}</p>
        <h5 className="forfait-price"> {price}</h5>
      </div>
    </Container>
  );
};
export default ForfaitCard;

const Container = styled.div`
  border-radius: 12px;
  margin: 0.5em;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  padding: 1em;
  display: grid;
  grid-template-columns: 300px auto;
  .right-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5em 1em;
  }
  .left-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .forfait-icone {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: contain;
    }
  }
  .forfait-title {
    margin: 0.5em 0;
    font-size: 18px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .p-tags {
    font-size: 12px;
  }
  .forfait-price,
  .forfait-categorie {
    margin: 0.5em 0;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .forfait-categorie {
    color: #9fa2b4;
    font-size: 14px;
  }
  .forfait-price {
    color: ${(props) => (props.color ? props.color : "#065cdd")};
    font-size: 16px;
  }

  @media only screen and (max-width: 999px) {
    grid-template-columns: 250px auto;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr !important;
    grid-template-rows: 200px auto;
    .forfait-icone {
      width: fit-content;
      border-radius: 10px;
      overflow: hidden;
      height: 100%;
    }
    .right-content {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      padding: 0.5em 1em;
    }
  }
`;
