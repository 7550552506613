import React from "react";
import styled from "styled-components";
import CustomInput from "./CustomInput";
import AuthContext from "../../contexts/AuthContext";

const FirstStep = ({
  civilities,
  setCivility,
  civility,
  values,
  setErrEmail,
  errEmail,
}) => {
  console.log("values debug", values);
  const { auth } = React.useContext(AuthContext);
  console.log(auth);
  const [email, setEmail] = React.useState();
  const [msg, setMsg] = React.useState("");

  React.useEffect(() => {
    setEmail(values.email);
  }, [values]);

  return (
    <Container>
      <h2>Vos Informations</h2>
      <p className="info">
        Pour modifier vos informations, cliquez sur paramètres à partir de votre
        espace client
      </p>
      <div className="inputs">
        <div className="select-wrp">
          <span className="label">
            Civilité<span className="red">*</span>
          </span>
          <select
            className="select width-unset"
            name="civilité"
            onChange={(e) => {
              setCivility(e.target.value);
              if (!civility.length) {
                setMsg("Champ Obligatoire");
              } else {
                setMsg("");
              }
            }}
          >
            <option disabled selected>
              Civilité
            </option>
            {civilities.map((item) => (
              <option value={item.civility_id} key={item.civility_id}>
                {item.civility_name}
              </option>
            ))}
          </select>
          {!civility.length ? <p>Champ Obligatoire</p> : null}
        </div>
        <CustomInput
          label="Prénom"
          name="fname"
          id="fname"
          type="text"
          value={values.fname}
          required
          disabled={auth?.fname ? true : false}
        />
        <CustomInput
          label="Nom"
          name="lname"
          id="lname"
          type="text"
          required
          disabled
        />
        <CustomInput
          label="Adresse E-mail"
          name="email"
          id="email"
          value={values.email}
          type="email"
          onKeyUp={(e) => {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (e.target.value.match(mailformat)) {
              setErrEmail(false);
            } else {
              setErrEmail(true);
            }
            console.log(errEmail);
          }}
          required
        />
        <CustomInput
          label="Téléphone"
          name="phone"
          id="phone"
          values={values.phone}
          type="text"
          required
          disabled
        />
      </div>
    </Container>
  );
};

export default FirstStep;

const Container = styled.div`
  .label {
    margin-top: 1.5em;
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
  }
  .select-wrp {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 12px;
    color: red;
    font-weight: 600;
  }
  .info {
    color: #4d4d4d;
    font-size: 14px;
    margin-bottom: 0.35em;
  }
`;
