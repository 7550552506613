import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CustomHelmet from "../components/elements/CustomHelmet";
import HistoryRdvTable from "../components/HistoryRdvTable";
import Layout from "../layouts/DefaultLayout";
import SelectOptions from "../components/elements/SelectOptions";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";

const HistoriqueRdv = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const [rdvData, setRdvData] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [selectedS, setSelectedS] = useState("SAV");

  const getRvData = async () => {
    if (typeof auth.user_phone !== "undefined") {
      try {
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/api/getRvList/${auth.user_phone}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();
        let arr = result.data;

        arr = arr.filter((item) => item.date !== null);
        console.log(arr);
        arr = arr.sort((a, b) => {
          return b.date - a.date;
        });

        setRdvData(arr);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const getScenarioData = async () => {
    try {
      const result = await fetch(`${REACT_APP_BACKEND_URL}/rvScenarios`, {
        method: "GET",
        headers: { "content-type": "application/json" },
      });
      const res = await result.json();
      let array = [];
      res.map((item) => {
        array.push({
          name:
            item.rvScenario_name === "Comercial"
              ? "Commercial"
              : item.rvScenario_name,
          value: item.rvScenario_name,
        });
      });
      setScenarios(array);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    getRvData();
    getScenarioData();
  }, [auth]);
  const [activeTab, setActiveTab] = useState(1);
  const data = [
    {
      id: "#Numéro 582185",
      date: "15 Jan, 2020",
      hour: "12H00",
      price: "500DH",
      status: "done",
    },
    {
      id: "#Numéro 582185",
      date: "16 Jan, 2020",
      hour: "12H00",
      price: "600DH",
      status: "revoked",
    },
    {
      id: "#Numéro 582185",
      date: "17 Jan, 2020",
      hour: "12H00",
      price: "700DH",
      status: "done",
    },
    {
      id: "#Numéro 582185",
      date: "18 Jan, 2020",
      hour: "12H00",
      price: "800DH",
      status: "revoked",
    },
    {
      id: "#Numéro 582185",
      date: "19 Jan, 2020",
      hour: "12H00",
      price: "900DH",
      status: "done",
    },
  ];
  const newData = rdvData ? rdvData.filter((rv) => rv.interventionId == 4) : [];
  const newDataSV = rdvData
    ? rdvData.filter((rv) => rv.interventionId != 4)
    : [];

  return (
    <Layout>
      <Container color={theme?.color}>
        <CustomHelmet title="Rendez-vous list" />
        <div className="tabs-row first-row">
          {/* <div className="tabs-row tabs">
            <button
              className={activeTab === 1 ? "active-tab btn" : "btn"}
              onClick={() => setActiveTab(1)}
            >
              Prochaine
            </button>
            <button
              className={activeTab === 2 ? "active-tab btn" : "btn"}
              onClick={() => setActiveTab(2)}
            >
              Historique
            </button>
          </div> */}
          <SelectOptions
            options={scenarios}
            bg="#065cdd"
            margin="0.25em 0.25em"
            padding="9px 0"
            handleChange={() => null}
            setSelectedS={setSelectedS}
            selectedS={selectedS}
          />
          <Link to="/rv" className="wrp" target="_blank">
            <button className="btn">Nouveau rendez-vous</button>
          </Link>
        </div>
        <div>
          <HistoryRdvTable
            color={theme?.color}
            data={
              activeTab === 1
                ? selectedS === "SAV"
                  ? newDataSV
                  : newData
                : null
            }
            activeTab={activeTab}
            type={
              activeTab === 1
                ? " Prochaine Rendez-vous"
                : activeTab === 2
                ? "Ancien Rendez-vous"
                : ""
            }
          />
        </div>
      </Container>
    </Layout>
  );
};

export default HistoriqueRdv;

const Container = styled.div`
  padding: 2em;
  margin: 1em;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;

  .tabs-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select {
    background-color: #2d4185;
    border: none;
    padding: 6px;
    border-radius: 8px;
    font-size: 14px;
    color: white;
  }
  .tabs {
    justify-content: flex-start;
  }
  .btn {
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 16px;
    border-radius: 12px;
    background: #f2f2f2;
    color: #222;
    margin: 0 0.5em;
  }
  .active-tab {
    background-color: ${(props) => (props.color ? props.color : "#2d4185")};
    color: #fff;
  }
  .code {
    cursor: unset;
    color: #222;
  }
  .data-container {
    display: none;
  }
  .active-data {
    display: block;
  }
  @media only screen and (max-width: 1200px) {
    padding: 2em 1em;
  }
  @media only screen and (max-width: 1000px) {
    .tabs-row {
      align-items: center;
    }
  }

  @media only screen and (max-width: 856px) {
    .tabs-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
    }
    .select {
      margin: 20px 0.5em;
    }
  }
  @media only screen and (max-width: 768px) {
    .page-name {
      display: block;
    }
    margin: 1em 0.5em;
    .btn {
      margin: 0 0.25em;
    }
  }
`;
