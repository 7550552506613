import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import CustomHelmet from "../components/elements/CustomHelmet";
import Layout from "../layouts/DefaultLayout";
import MyCarsCart from "../components/MyCarsCart";
import ThemeContext from "../contexts/ThemeContext";
import Logo from "../assets/ford-logo.png";
import Ford1 from "../assets/ford.png";
import Ford2 from "../assets/ford4.png";
import Ford3 from "../assets/ford5.png";
import AutohallLogo from "../assets/autohall.png";
import Car from "../assets/car.png";
import AuthContext from "../contexts/AuthContext";
import Spinner from "../components/Spinner";
const { REACT_APP_BACKEND_URL } = process.env;

const MesVoitures = () => {
  const { theme } = useContext(ThemeContext);
  const { auth, setAuth } = useContext(AuthContext);
  let cars = [Ford1, Ford2, Ford3];

  let i = 0;
  const [carInfo, setCarInfo] = useState([]);
  const [allBrands, setAllbrands] = useState([]);
  const [isLoading, setisLoading] = useState([]);

  const getLogo = async () => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/brands`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result) {
        
        setAllbrands(result);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const filterLogo = (n) => {
    const t = allBrands?.filter((i) => {
      return i?.brand_name.toLowerCase() === n.toLowerCase();
    });
    if (t.length > 0) {
      return REACT_APP_BACKEND_URL + t[0]?.brand_img;
    } else return AutohallLogo;
  };

  useEffect(() => {
    if (auth) {
      getLogo();
    }
  }, [auth]);

  useEffect(() => {
    if (allBrands) {
      setisLoading(true);
      fetch(`${REACT_APP_BACKEND_URL}/api/getClientCars/${auth.Custno}`)
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            throw new Error("une error c'est produits");
          }
          // Examine the text in the response
          response.json().then(function (data) {
            setCarInfo(data.data.data);
            setisLoading(false);
            
          });
        })
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
        });
    }
  }, [allBrands]);
  return (
    <Layout>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          <CustomHelmet title="Voitures list" />
          {carInfo?.length !== 0 ? (
            carInfo?.map((item, index) => {
              i++;
             
              return (
                <MyCarsCart
                one = {carInfo.length === 1 ? true : false}
                  color={theme?.color}
                  img={Car}
                  title={item.MrqVehi}
                  type={item?.ModelVehi}
                  logo={filterLogo(item?.MrqVehi)}
                  key={index}
                  imma={item?.NumImmatri}
                  annee={item?.AnneeVeh}
                  uid={item?.IDVehicule}
                />
              );
            })
          ) : (
            <p>pas de resultat</p>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default MesVoitures;

const Container = styled.div`
  padding: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 2em;
  @media only screen and (max-width: 576px) {
    padding: 1em 0.5em;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }
`;
