import React from "react";
import styled from "styled-components";
import SelectOptions from "../components/elements/SelectOptions";
import Button from "../components/elements/Button";

const ContactHeader = ({ setLocation, location, dealershipList, cityList }) => {
  const options = [
    { name: "Agadir", value: "agadir" },
    { name: "Casablanca", value: "casa" },
    { name: "Marrakech", value: "marrakech" },
  ];

  const options2 = [
    {
      id: 1,
      value: "Ford Dcheira El Jihadia",
      name: "Ford Dcheira El Jihadia",
      position: { lat: 30.388584, lng: -9.51269 },
    },
    {
      id: 2,
      value: "AutoHall 1 Talborjt",
      name: "AutoHall 1 Talborjt",
      position: { lat: 30.420744, lng: -9.595795 },
    },
    {
      id: 3,
      value: "Salon auto souss",
      name: "Salon auto souss",
      position: { lat: 30.415903, lng: -9.548936 },
    },
    {
      id: 4,
      value: "Ford Cité Tilila",
      name: "Ford Cité Tilila",
      position: { lat: 30.390483, lng: -9.513308 },
    },
  ];

  const handleSelectPosition = (e) => {
    setLocation({ ...location, Succursale: e.target.value });
  };
  const handleSelectCity = (e) => {
    setLocation({ ...location, Ville: e.target.value });
  };

  return (
    <Container>
      <h6>Veillez choisir une ville et un succursale :</h6>
      <div className="top-section">
        <SelectOptions
          options={cityList}
          responsive="true"
          handleChange={handleSelectCity}
        />
        <SelectOptions
          responsive="true"
          options={dealershipList}
          handleChange={handleSelectPosition}
        />
        <Button
          margin="0.2em 0 0 0"
          radius="7px"
          padding="9px 29px"
          title="Allez"
          color="#fff"
          bg="#065CDD"
        />
      </div>
    </Container>
  );
};

export default ContactHeader;

const Container = styled.div`
  border-radius: 12px;
  border: 4px solid rgba(0, 0, 0, 0.05);
  padding: 2em;
  margin: 2em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h6 {
    color: #797575;
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .top-section {
    display: flex;
    justify-content: space-between;
    margin: 0.75em 0;
  }
  .bottom-section {
    margin: 0.75em 0;
    display: grid;
    grid-template-columns: 1fr 1fr 90px;
    h6 {
      margin-bottom: 0.5em;
    }
  }
  .input {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 7px;
    width: 90%;
  }
  @media only screen and (max-width: 500px) {
    .top-section {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }
    .bottom-section {
      margin: 0.75em 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
    }
    .input {
      width: 100%;
      margin: 0.75em 0;
    }
    .bottom-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
