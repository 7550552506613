import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import AuthenticationContainer from "../components/elements/AuthenticationContainer";
import CustomHelmet from "../components/elements/CustomHelmet";
import ActiverCompteForm from "../components/ActiverCompteForm";
import ThemeContext from "../contexts/ThemeContext";
import Car from "../assets/ford.png";
import LogoAuoHall from '../assets/autohall.png';

const ActiverCompte = () => {
  let location = useLocation();
  //const { userdata } = location.state.userdata;
  //const { tel } = location.state.value;

  const { REACT_APP_BACKEND_URL, REACT_APP_API_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const [otp, setotp] = useState({});

  const IsUserOTP = (id) => {
    fetch(`${REACT_APP_BACKEND_URL}/api/getUserOTP/1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          setotp(data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };


  return (
    <Container>
      <CustomHelmet title="Activer compte" />
      <AuthenticationContainer
        img={Car}
        slogan={theme?.slogan}
        color={theme?.color}
      >
        <ActiverCompteForm
          logo={REACT_APP_BACKEND_URL + theme?.logo}
          otp={otp}
          userdata={{e:"helo"}}
          //tel={location.state.value}
        />

      </AuthenticationContainer>
    </Container>
  );
};

export default ActiverCompte;

const Container = styled.div``;
