import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Calendar } from "react-date-range";
import CustomHelmet from "../components/elements/CustomHelmet";
import fr from "date-fns/locale/fr";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";
import { useLocation, Link } from "react-router-dom";
import ThemeContext from "../contexts/ThemeContext";
import HourButton from "../components/Rv/HourButton";
import ResponseStatus from "../components/elements/ResponseStatus";
import AuthContext from "../contexts/AuthContext";

const EditRv = () => {
  const { REACT_APP_BACKEND_URL, REACT_APP_API_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  const {
    nomcomplet,
    type,
    date,
    id,
    vin,
    civilite,
    heure,
    date2,
    idville,
    succursale,
    intervention,
  } = location.state.rv;
  console.log(location.state.rv);
  const heureArr = heure ? heure.split(":") : ["00", "00"];
  const [IdHora, setIdHora] = useState(null);
  const [hoursV, setHours] = useState([]);
  

  const [selectedTime, setSelectedTime] = useState(null);
  const [rvdate, setRVDate] = useState(null);
  const [fulldate, setFullDate] = useState(new Date(date2));
  const [oldDate, setOldDate] = useState(new Date(date2));
  const [oldHora, setOldHora] = useState(heure);
  const [pendding, setpeending] = useState({ update: null, cancel: null });
  const [newDate, setDate] = useState(null);
  const { auth } = useContext(AuthContext);
  const selectedCar = JSON.parse(localStorage.getItem('selectedCar'));

  const [feedback, setFeedback] = useState({
    type: null,
    visible: 0,
    error: 0,
  });
  const today = new Date();

  const dateFomat = (date) => {
    const e = new Date(date);
    const month =
      parseInt(e.getMonth() + 1) < 10
        ? `0${e.getMonth() + 1}`
        : e.getMonth() + 1;
    const day = parseInt(e.getDate()) < 10 ? `0${e.getDate()}` : e.getDate();
    const formatedData = `${e.getFullYear()}-${month}-${day}`;
    setDate(formatedData);
    return formatedData;
  };

  const dateFomartWithoutUpdate = (date) => {
    const e = new Date(date);
    const month =
      parseInt(e.getMonth() + 1) < 10
        ? `0${e.getMonth() + 1}`
        : e.getMonth() + 1;
    const day = parseInt(e.getDate()) < 10 ? `0${e.getDate()}` : e.getDate();
    const formatedData = `${e.getFullYear()}-${month}-${day}`;
    return formatedData;
  };

  const handleDate = (d) => {
    console.log(d);
    const newDate = d.toLocaleDateString("fr-Fr", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setRVDate(newDate);
    setFullDate(d);
  };

  const getHours = async (date) => {
    const newDate = await dateFomat(date);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/hours/${newDate}/3/3`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setHours(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => getHours(today), []);

  const changeRv = async (data) => {
    console.log(selectedTime, oldHora);
    if (
      parseInt(oldHora) === parseInt(selectedTime) &&
      dateFomartWithoutUpdate(oldDate) == dateFomartWithoutUpdate(fulldate)
    ) {
      
      setFeedback({ ...feedback, visible: 1, type: -2 });
      setTimeout(() => {
        setFeedback({ ...feedback, visible: 0, type: -2 });
      }, 2000);
      return;
    }
    if (!selectedTime) {
      setFeedback({ ...feedback, visible: 1, type: -2 });
      setTimeout(() => {
        setFeedback({ ...feedback, visible: 0, type: -2 });
      }, 2000);
      return;
    }

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/api/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ville: idville,
          succursale: parseInt(location.state.rv.idSuccursale),
          date: newDate,
          hora: parseInt(IdHora),
          bid: parseInt(data.bid),
          vin: selectedCar?.NumSerie
        }),
      });

      const res = await response.json();
      if (res) {
        if(res.result.includes("errorexist")){
          setFeedback({ ...feedback, visible: 1, type: -3 });
        setTimeout(() => {
          setFeedback({ ...feedback, visible: 0, type: -3 });
        }, 2000);
        }else{
          setFeedback({ ...feedback, visible: 1, type: 1 });
        setTimeout(() => {
          setFeedback({ ...feedback, visible: 0, type: 1 });
        }, 2000);
        }
        
      } else {
        console.log("Erreur");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const calcelBook = async () => {
    setpeending({ ...pendding, cancel: 1 });
    //handle the id data type
    const newId = Number.parseInt(id.split("a")[1]);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/cancelBook/${newId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.message) {
        setpeending({ ...pendding, cancel: 0 });
        setFeedback({ ...feedback, type: 0, visible: 1 });
        setTimeout(() => {
          setFeedback({ ...feedback, type: null, visible: 0 });
        }, 2000);
      }
      setTimeout(() => history.push("/"), 3000);
    } catch (err) {
      setFeedback({ ...feedback, type: -1, visible: 1 });
      setTimeout(() => {
        setFeedback({ ...feedback, type: null, visible: 0 });
      }, 2000);
    }
  };

  const extractDateTime = (d, type) => {
    const odj = {
      date: new Date(d).toTimeString(),
      time: new Date(d).toLocaleTimeString(),
    };
    if (type === "date") {
      return new Date(d).toLocaleDateString();
    } else if (type === "time") {
      return new Date(d).toLocaleTimeString();
    } else if (type === "all") {
      return odj;
    } else {
      return -1;
    }
  };

  return (
    <Layout>
      <Container color={theme?.color}>
        <CustomHelmet title="Modifier rendez-vous" />
        <div className="top">
          <h3>Modifier votre rendez-vous</h3>
          <div className="row">
            <div className="sub-row">
              <h5 className="title">Type:</h5>
              <h5>{vin != "1" ? `SAV ${intervention}` : "Commercial"}</h5>
            </div>
            <div className="sub-row">
              <h5 className="title">Nom:</h5>
              <h5>{nomcomplet}</h5>
            </div>
            <div className="sub-row">
              <h5 className="title">Email:</h5>
              <h5>{auth.email}</h5>
            </div>
            <div className="sub-row">
              <h5 className="title">Téléphone:</h5>
              <h5>{auth.user_phone}</h5>
            </div>
          </div>
          <div className="row">
            <div className="sub-row">
              <h5 className="title">Date:</h5>
              <h5>{date2}</h5>
            </div>
            <div className="sub-row">
              <h5 className="title">Heure:</h5>
              <h5>{`${heureArr[0]}h:${heureArr[1]}min`}</h5>
            </div>
          </div>
          <div className="row">
            <div className="sub-row">
              <h5 className="title">Statut:</h5>
              <h5>
                {type === "pending"
                  ? "En attent"
                  : type === "canceled"
                  ? "Annulé"
                  : "Confirmé"}
              </h5>
            </div>
            <div className="sub-row">
              <h5 className="title">Civilité:</h5>
              <h5>{civilite}</h5>
            </div>
            <div className="sub-row">
              <h5 className="title">Succursale:</h5>
              <h5>{succursale}</h5>
            </div>
          </div>
        </div>
        <div className="content-wrp">
          <div className="clander-wrp">
            <Calendar
              locale={fr}
              date={fulldate}
              onChange={(e) => {
                setFullDate(e);
                getHours(e);
              }}
              minDate={today}
              color="#2d3c85"
            />
          </div>
          <div className="hours-wrp">
            {hoursV.length
              ? hoursV.map((time) => {
                  return (
                    <HourButton
                      time={time.hora}
                      status="free"
                      setSelectedTime={setSelectedTime}
                      selectedTime={selectedTime}
                      key={time.id}
                      color={theme?.color}
                      id={time.id}
                      setIdHora={setIdHora}
                    />
                  );
                })
              : null}
          </div>
        </div>
        <div className="botton">
          <button
            className="valider"
            onClick={() =>
              changeRv({
                ...location.state.rv,
                bid: location.state.rv.id.replace("a", ""),
              })
            }
          >
            {pendding.update === 1 ? "En attente..." : "Modifier"}
          </button>
          <button className="annuler" onClick={calcelBook}>
            {pendding.cancel === 1 ? "En attente..." : "Annuler le rendez-vous"}
          </button>
        </div>

        {feedback.visible ? (
          <ResponseStatus
            message={
              feedback.type === 0
                ? `Votre rendez-vous a été annulé`
                : feedback.type === 1
                ? `Votre rendez-vous a été modifié`
                : feedback.type === -2
                ? `Merci de modifier la date ou l'heure`
                : feedback.type === -3
                ? `Rendez-vous non disponible, merci de choisir une autre date.`
                :`une erreur s'est produit réessayer ultérieurement`
            }
            code={feedback.type === 0 ? 1 : feedback.type === 1 ? 1 : 0}
          />
        ) : null}
      </Container>
    </Layout>
  );
};

export default EditRv;

const Container = styled.div`
  padding: 2em;
  margin: 1em;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .top {
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
    .row {
      display: grid;
      grid-template-columns: 50% 50%;
      h5 {
        font-size: 15px;
        font-weight: normal;
        color: #252733;
        display: inline;
        margin: auto 0;
        text-transform: uppercase;
      }
      .sub-row {
        display: grid;
        grid-template-columns: 30% 70%;
        padding: 0.5em 0;
      }
      .title {
        font-weight: 600;
      }
    }
    h3 {
      font-weight: 400;
      font-size: 24px;
      color: ${(props) => props.color};
      margin: 0.5em 0;
      width: 100%;
      text-align: center;
      letter-spacing: 0.5px;
      span {
        color: #787885;
      }
    }
  }
  .content-wrp {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .clander-wrp {
    display: flex;
    justify-content: center;
  }
  .hours-wrp {
    margin: 1em 0.5em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .botton {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin: 0 0.5em;
      border: none;
      outline: none;
      padding: 10px;
      color: #fff;
      border-radius: 7px;
    }
    .annuler {
      background-color: #999;
    }
    .valider {
      background-color: ${(props) => (props.color ? props.color : "#2d4185")};
    }
  }
  @media only screen and (max-width: 1100px) {
    .content-wrp {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
    }
  }
  @media only screen and (max-width: 600px) {
    .row {
      grid-template-columns: 100% !important;
      grid-template-rows: auto auto;
    }
  }
  @media only screen and (max-width: 400px) {
    padding: 1em 0;
    margin: 1em 0.25em;
  }
`;
