import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ThemeContext from "../contexts/ThemeContext";
import CarChoiceCart from "../components/CarChoiceCart";
import Spinner from "../components/Spinner";
import Car from "../assets/ford.png";
import logo3 from "../assets/opel.png";
import Logo from "../assets/autohall.png";
import Logout from "../assets/logout.svg";
import { useHistory, useLocation } from "react-router";

const CarChoice = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { setTheme } = useContext(ThemeContext);
  const [userCars, setUserCars] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [submittingCars, setSubmittingCars] = useState(false);
  const [noCars, setNoCars] = useState(false);
  const [allBrands, setAllbrands] = useState([]);
  const [img, setImg] = useState('');
  const [step, setStep] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const getBrandTheme = async (b_name) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/brandName/${b_name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.message) {
        console.log(result);
        history.push("/");
      } else {
        const obj = {
          id: result.brand_id,
          color: result.brand_color,
          logo: result.brand_img,
          name: result.brand_name,
          slogan: result.brand_slogan,
        };
        setTheme(obj);
        localStorage.setItem("theme", JSON.stringify(obj));
        history.push("/");
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getLogos = async () => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/brands`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result) {
        // console.log(result);
        setAllbrands(result);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const returnLogo = (name) => {
    const t = allBrands?.filter((i) => {
      return i?.brand_name?.toLowerCase() === name.toLowerCase();
    });

    if (t.length > 0) {
      return REACT_APP_BACKEND_URL + t[0]?.brand_img;
    } else return Logo;
  };


  

  useEffect(() => {
    getLogos();
    fetch(
      `${REACT_APP_BACKEND_URL}/api/getClientCars/${location.state.userdata.Custno}`
    )
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        // Examine the text in the response
        response.json().then(async (data) => {
          setUserCars(data.data.data);
          setIsFetched(true);
          console.log("dddddddd",userCars[0]);
     
        })
         
          
          
        })
   
      .catch(function (err) {
        setSubmittingCars(true);
        console.log("Fetch Error :-S", err);
      });
  }, []);

 

  const handleClick = (item) => {
    getBrandTheme(item.MrqVehi);
    localStorage.setItem("selectedCar", JSON.stringify({...item}));
    history.push('/');
  };

  const logout = () => {
    localStorage.removeItem("auth");
    history.push("/login");
    localStorage.removeItem("theme");
  };
  

  return (
    <Container>
      {isFetched ? (
        userCars?.length ? userCars.length === 1 ?   handleClick(userCars[0]): (
          <>
            <h2>veuillez choisir votre voiture</h2>
            <div className="cart-wrp">
              <div>
                {allBrands?.length !== 0 && 
                  userCars.length !== 0
                  ? userCars.map((item, index) => {
                    console.log("This is the Itel", item);
                      return (
                        <CarChoiceCart
                        item={item}
                          type={
                            item.MrqVehi +

                            " " +
                            item.ModelVehi +
                            " " +
                            item.VersionVehi
                          }
                          imtr={item.NumImmatri}
                          date={item.DateCircul}
                          logo={returnLogo(item?.MrqVehi)}
                          img={Car}
                          modele={item.ModelVehi}
                          handleClick={(Car) => handleClick(Car)}
                          
                        />
                      )
                    })  : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <h2>aucun véhicule trouvé</h2>
            <img
              onClick={() => logout()}
              src={Logout}
              style={{
                width: "3em",
                height: "3em",
                transform: "rotate(180deg)",
                cursor: "pointer",
              }}
            />
          </>
        )
      ) : (
        <Spinner />
      )}
    </Container>
  );
};

export default CarChoice;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  min-height: 100vh;
  h2 {
    margin: 0.5em 0;
    font-weight: 600;
  }
  .cart-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1100px) {
    h2 {
      margin: 1em 0;
    }
    .cart-wrp {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 576px) {
    padding: 1em 0.5em;
  }
`;