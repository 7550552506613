import React, {useState} from "react";
import { Calendar } from "react-date-range";
import styled from "styled-components";
import fr from "date-fns/locale/fr";
import HourButton from "./HourButton";
import CarDetailBlock from "./CarDetailBlock";
import Car from "../../assets/ford.png";

const FifthStep = ({
  setSucursale,
  sucursale,
  setDate ,
  selectedTime,
  setSelectedTime,
  values,
  senario,
  senarioType,
  color,
  sucursales,
  hours,
  getModel,
  setIdHora,
  idHora,
  getHours
}) => {
  const today = new Date();

  const dateFomat = (e )=>{
    const month =  parseInt(e.getMonth()+1) < 10 ? `0${e.getMonth()+1}` : e.getMonth()+1;
    const day = parseInt(e.getDate()) < 10 ? `0${e.getDate()}` : e.getDate();
    const formatedData = `${e.getFullYear()}-${month}-${day}`;
    setDate(formatedData);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return e.toLocaleDateString("fr-FR", options);
  }

  const selectedC = JSON.parse(localStorage.getItem('selectedCar'));
  const [newDate, setNewDate] = useState(new Date());
  const timeArr = selectedTime ? selectedTime.split(":") : ["0", "0"];

  console.log("ggggggggg",senarioType)
  return (
    <Container color={color}>
      <div className="date">
        {senarioType != "Commercial" ? (
          <CarDetailBlock
            values={values}
            selectedC={selectedC}
            rv
            img={Car}
            color={color}
            getModel={getModel}
          />
        ) : null}
        <h2 className="h2-title">Choisissez la date et l'heure disponibles</h2>
        <select
          className="select width-unset"
          name="succursale"
          value={JSON.stringify(sucursale)}
          onChange={(e) => {
           
            const v = JSON.parse(e.target.value);
            setSucursale(v);
          }}
        >
          {sucursales.map((item, index) => (
            <option value={JSON.stringify(item)} key={index}>
              {item.nom}
            </option>
          ))}
        </select>
      </div>
      <div className="datepicker-container">
        <div className="calandar-container">
          <Calendar
            locale={fr}
            date={newDate}
             onChange={(e) => {
               setNewDate(e)
               getHours(e)
              }}
             minDate={today}
            color="#2d3c85"
          />
        </div>
        <div className="hours-container">
        {hours.map((time) => (
            <HourButton
              time={time.hora}
              id={time.id}
              status="free"
              setSelectedTime={setSelectedTime}
              selectedTime={selectedTime}
              key={time.id}
              color={color}
              setIdHora={setIdHora}
            />
          ))}
        </div>
      </div>
      {selectedTime ? (
        <h3>
          <span>Date choisie: </span>
          {`${ newDate ?  dateFomat(newDate) : null} à  ${`${timeArr[0]}h:${timeArr[1]}min`}`}
        </h3>
      ) : null}
    </Container>
  );
};

export default FifthStep;

const Container = styled.div`
  .datepicker-container {
    display: grid;
    grid-template-columns: 340px auto;
  }
  .calandar-container {
    max-width: 340px;
  }
  .hours-container {
    margin: 20px 0 20px 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-rows: 60px 60px;
  }
  .h2-title {
    margin-top: 2em !important;
    margin-bottom: 1em !important;
  }
  @media only screen and (max-width: 992px) {
    .datepicker-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .hours-container {
      width: 100%;
      max-width: 500px;
      margin: 20px 0 20px 0px;
    }
    .date {
      align-items: flex-start;
      flex-direction: column;
      select {
        max-width: unset !important;
        width: 100%;
        margin-top: 2em;
      }
    }
  }
`;
