import React, { createContext, useState, useLayoutEffect } from "react";

const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const [theme, setTheme] = useState({});

  const getTheme = async (b_name) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/brandName/${b_name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result) {
        const obj = {
          id: result.brand_id,
          color: result.brand_color,
          logo: result.brand_img,
          name: result.brand_name,
          slogan: result.brand_slogan,
        };
        console.log(obj);
        localStorage.setItem("theme", JSON.stringify(obj));
        setTheme(obj);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const themeContext = {
    theme,
    setTheme,
  };
  useLayoutEffect(() => {
    const checkTheme = async () => {
      var url = new URL(window.location.href);
      var brand_name = url.searchParams.get("brand");
      if (localStorage.getItem("theme") !== null) {
        try {
          const th = JSON.parse(localStorage.getItem("theme"));
          setTheme(th);
        } catch (err) {
          console.log(err.message);
        }
      }
      if (url.searchParams.get("brand")) {
        getTheme(brand_name);
      }
      if (
        localStorage.getItem("theme") === null &&
        url.searchParams.get("brand") === null
      ) {
        getTheme("Autohall");
      }
    };
    checkTheme();
  }, []);

  return (
    <ThemeContext.Provider value={themeContext}>
      {children}
    </ThemeContext.Provider>
  );
};

export const ThemeConsumer = ThemeContext.Consumer;

export default ThemeContext;
