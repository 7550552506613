import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";
import ForfaitCard from "../components/ForfaitCard";
import CustomHelmet from "../components/elements/CustomHelmet";
import SelectOptions from "../components/elements/SelectOptions";
import ThemeContext from "../contexts/ThemeContext";
import { ReactComponent as SearchIscone } from "../assets/search.svg";

const Forfait = () => {
  const { theme } = useContext(ThemeContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [value, setValue] = useState(0);
  const [filterArgs, setFilterArgs] = useState([]);
  const [forfaitData, setForfaitData] = useState([]);
  const [forfaitData2, setForfaitData2] = useState([]);

  useEffect(() => {
    if (value != 0) {
      if (value === "tous") {
        return setForfaitData(forfaitData2);
      }
      setForfaitData(forfaitData2.filter((word) => word.type === value));
    }
  }, [value]);

  useEffect(() => {
    let newArray = [
      {
        name: "Tous",
        value: "tous",
      },
    ];
    const getAccessoires = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_BACKEND_URL}/api/getAccessoires`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();
        result.data.map((arg, index) => {
          if (!newArray.includes(arg)) {
            newArray.push({ name: arg.type, value: arg.type });
          }
        });
        setFilterArgs(newArray);
        setForfaitData(result.data);
        setForfaitData2(result.data);
      } catch (err) {
        console.log(err.message);
      }
    };
    getAccessoires();
  }, []);

  //search input change handling
  const handlSearchInput = (e) => {
    setForfaitData(
      forfaitData2.filter((word) =>
        word.libelle.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  const selectFilter = (e) => {
    setValue(e.target.value);
  };
  return (
    <Layout>
      <Container>
        <CustomHelmet title="Forfait" />
        <div className="header-wrp">
          <div className="search-wrp">
            <input
              type="text"
              id="sms"
              name="methode"
              onChange={(e) => handlSearchInput(e)}
              placeholder="Rechercher"
              className="searchinput"
            />
            <SearchIscone />
          </div>
          <SelectOptions
            options={filterArgs}
            handleChange={selectFilter}
            responsive={"true"}
          />
        </div>
        <div className="forfait-content">
          {forfaitData.length != 0 ? (
            forfaitData.map((item, index) => {
              return (
                <ForfaitCard
                  color={theme?.color}
                  key={index}
                  img={item.image}
                  title={item.libelle}
                  description={item.description}
                  price={new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "MAD",
                  }).format(parseInt(item.prix.split(" ", 1), 10))}
                  categorie={item.type}
                />
              );
            })
          ) : (
            <p className="no-result-msg">*pas de resultat trouver </p>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default Forfait;

const Container = styled.div`
  margin: 1em;
  padding: 0.5em;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .header-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search-wrp {
    display: flex;
    background-color: #f2f4f8;
    width: 200px;
    height: fit-content;
    padding: 0.5em;
    margin: 0 1.5em 0 0;
    border-radius: 10px;
    input {
      width: 100%;
      margin-right: 0.25em;
      background-color: #f2f4f8;
    }
    svg {
      position: relative;
      right: 0;
    }
  }
  .page-name {
    font-size: 18px;
    font-weight: 600;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .forfait-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(700px, 1fr));
  }
  .no-result-msg {
    color: #808080;
    font-size: 13px;
    margin: 0 auto 0.25em 1em;
    padding: 0.5em;
  }
  @media only screen and (max-width: 1000px) {
    .forfait-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }
  @media only screen and (max-width: 800px) {
    .search-wrp {
      width: 150px;
    }
  }
  @media only screen and (max-width: 500px) {
    .header-wrp {
      flex-wrap: wrap;
    }
    .search-wrp {
      width: 100%;
      margin: 0 0 0.5em 0;
    }
  }
`;
