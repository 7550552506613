import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import NavigationButtons from "../components/Rv/NavigationButtons";
import jwt from "jsonwebtoken";
import CustomHelmet from "../components/elements/CustomHelmet";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import Success from "../components/Rv/Success";
import Popup from "../components/Rv/Popup";
import Header from "../components/Rv/Header";
import Steps from "../components/Rv/Steps";
import FirstStep from "../components/Rv/FirstStep";
import SecondStep from "../components/Rv/SecondStep";
import ThirdStep from "../components/Rv/ThirdStep";
import FourthStep from "../components/Rv/FourthStep";
import FifthStep from "../components/Rv/FifthStep";
import SixthStep from "../components/Rv/SixthStep";

const App = () => {
  const selectedCar = JSON.parse(localStorage.getItem("selectedCar"));
  const immArray = selectedCar.NumImmatri.split("-");
  console.log(immArray);

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
    name: "",
  });
  const [isLoading, setIsLoading] = useState(0);
  const [parentBrand, setParentBrand] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [selectedTime, setSelectedTime] = useState(null);
  const [fulldate, setFullDate] = useState(new Date());
  const [date, setDate] = useState();
  const [formatedDate, setFormatedDate] = useState(null);
  const [brandForCommercial, setBrandForCommercial] = useState('');
  const [modelForCommercial, setModelForCommercial] = useState(''); 
  const [isLoadingForModels, setIsLoadingForModel] = useState(false);
  const [action, setAction] = useState(false);
  const [senario, setSenario] = useState("");
  const [senarioType, setSenarioType] = useState("SAV");
  const [errEmail, setErrEmail] = useState(false);
  const [brandID, setBrandID] = useState(0);
  const [modelID, setModelID] = useState(0);
  const [intervention, setIntervention] = useState({
    rvIntervention_id: 3,
    rvIntervention_name: "Travaux Rapides",
  });
  const [idHora, setIdHora] = useState(null);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [civility, setCivility] = useState("");
  const [civilities, setCivilities] = useState([]);
  const [sucursale, setSucursale] = useState({ lat: 87, lng: 65, id: 3 });
  const [sucursales, setSucursales] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(selectedCar.MrqVehi);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(selectedCar.ModelVehi);
  const [vehicul, setVehicul] = useState("85");
  const [scenarios, setScenarios] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [alphabets, setAlphabets] = useState([]);
  const [hours, setHours] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [accepted, setaccepted] = useState(false);
  const [msg, setmsg] = useState("");
  const [mtrMiddle,setMtrMiddle] = useState("A");

  const dateFomat = (date) => {
    const e = new Date(date);
    const month =
      parseInt(e.getMonth() + 1) < 10
        ? `0${e.getMonth() + 1}`
        : e.getMonth() + 1;
    const day = parseInt(e.getDate()) < 10 ? `0${e.getDate()}` : e.getDate();
    const formatedData = `${e.getFullYear()}-${month}-${day}`;
    setDate(formatedData);
    return formatedData;
  };

  const getSuccursales = async () => {
    setIsLoading(1);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/sucursales/${city}/${intervention.rvIntervention_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setSucursales(res);
      setSucursale(res[0]);
      setIsLoading(0);
      console.log("This is the resp : ", res);
    } catch (err) {
      console.log(err);
    }
  };

  console.log(senario);
  useEffect(() => {
    getBrandID(brand);
  }, [brand]);
  useEffect(() => {
    getModelID(model);
  }, [model]);

  useEffect(() => {
    if (brandForCommercial.idMarque) {
      getModels();
    }
  }, [brandForCommercial]);

  const getModelID = async (name) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/getmodelid/${name}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res.status === 200) {
        setModelID(res.data.idModele);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBrandID = async (name) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/getbrandid/${name}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (res.status === 200) {
        setBrandID(res.data.idMarque);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCities = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/cities/${intervention.rvIntervention_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setCities(res);
      setCity(res[0].id);
    } catch (err) {
      console.log(err);
    }
  };

  const getHours = async (date) => {
    const newDate = await dateFomat(date);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/hours/${newDate}/${intervention.rvIntervention_id}/${sucursale.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setHours(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getBrands = async () => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/api/brands`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setBrands(res);
      setBrandForCommercial(res[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const getModels = async () => {
    setIsLoadingForModel(false);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/models/${brandForCommercial.idMarque}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setModels(res);
      setModelForCommercial(res[0].idModele);
    } catch (err) {
      console.log(err);
    }
    setIsLoadingForModel(true);
  };

  const getVehicule = async (mtr1, mtr2, mtr3) => {
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/vehicule/${mtr1}/${mtr2}/${mtr3}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      setVehicul(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getCivilities = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/civilities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setCivilities(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvAlphabets = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvAlphabets", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setAlphabets(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvScenarios = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvScenarios", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setScenarios(res);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getrvInterventions = async () => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rvInterventions", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setInterventions(res);
    } catch (err) {
      console.log(err);
    }
  };

  const getBrand = async (name) => {
    const { REACT_APP_BACKEND_URL } = process.env;
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/brandName/${name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result) {
        const obj = {
          id: result.brand_id,
          color: result.brand_color,
          logo: result.brand_img,
          name: result.brand_name,
        };

        setTheme(obj);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleDate = (date) => {
    const d = date.toLocaleDateString("fr-Fr", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setDate(d);
    setFullDate(date);
    const fd = date.toLocaleDateString("fr-CA");
    getHours(new Date());
    setFormatedDate(fd);
  };

  useEffect(() => {
    getCivilities();
    getrvInterventions();
    getrvScenarios();
    getrvAlphabets();
    getCities();
    getBrands();
    handleDate(new Date());

    if (typeof window !== undefined) {
      var url = new URL(window.location.href);
      var brand_name = url.searchParams.get("brand");
      if (brand_name !== null) {
        getBrand(brand_name);
      } else {
        getBrand("Autohall");
        setParentBrand(true);
      }
    }
  }, []);

  useEffect(() => {
    getCities();
  }, [intervention]);

  useEffect(() => {
    if (city !== null) {
      getSuccursales();
    }
  }, [city]);

  useEffect(() => {
    if (brandForCommercial.idMarque) {
      getModels();
    }
  }, [brandForCommercial]);
  const addRvToLead = async (data) => {
    try{
      const resp = await fetch(`${REACT_APP_BACKEND_URL}/leads/api-leads`,{
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
           source : "Rendez-vous commercial",
             tags: {
              ville: city,
              email: data.email,
              nom: data.name,
              marque: brandForCommercial?.idMarque,
              origine: "Rendez-vous commercil",
              plateforme: "Web",
              modele:String(modelForCommercial) ,
              telephone: data.phone,
              campagne: "Rendez-vous commercil",
              hora: idHora,
              date: date,
              succursale: sucursale.id,
             }
        })
      });
      const respJson = await resp.json();
      console.log(respJson);

    }catch(e){
      console.log(e.message);
    }
  }

  const addRV = async (data) => {
    if (!accepted) {
      setmsg(
        "Vous devez accepter nos conditions générales pour vous continuer."
      );
      return;
    }
    let alph = null;
    let time = null;

    alphabets.map((item) => {
      if (item.rvAlphabets_alphabet === data.mtr2) {
        alph = item.rvAlphabets_id;
      }
    });
    hours.map((item) => {
      if (item.hora === selectedTime) {
        time = item.id;
      }
    });
    const fullDt = date + "-" + selectedTime;

    const mtrID = {
      A: 1,
      B: 2,
      D: 3,
      E: 4,
      H: 5,
      ww: 6,
    };
    const mtrNum= {
      1: "A",
      2: "B",
      3: "D",
      4: "E",
      5: "H",
      6:"ww"
    }
    const obj = {
      type:
        senarioType === "Commercial"
          ? senarioType
          : intervention.rvIntervention_name,
      brand: senarioType === "Commercial" ? brandForCommercial?.idMarque : brandID,
      modele: senarioType === "Commercial" ? String(modelForCommercial) : modelID,
      imm1: senarioType === "Commercial" ? "" : data.mtr1,
      imm2: senarioType === "Commercial" ? "" : data.mtr3,
      vin: senarioType === "Commercial" ? 1 : data.nbr,
      name: data.fname + " " + data.lname,
      nom: data.lname,
      prenom: data.fname,
      email: data.email,
      phone: data.phone,
      typeinv:
        senarioType === "Commercial" ? 4 : intervention.rvIntervention_id,
      ville: city,
      succursale: sucursale.id,
      date: date,
      hora: parseInt(idHora),
      licenseplate: data.mtr1 + " " + data.mtr2+ " " + data.mtr3,
      lettre: mtrID[data.mtr2],
      civ: parseInt(civility) - 1,
      descr: data.descr,
      fulldate: fullDt,
      localBrand: theme?.id,
      brand_auto: theme?.id,
      source: "Web",
      brandName: brandForCommercial.Nom
    };

    console.log(obj);

    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/rv", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      const res = await response.json();
      if (res.send) {
        setAction("success");
        if(senarioType === "Commercial"){
          await addRvToLead(obj);
        }
      } else {
        setAction("failed");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getModel = () => {
    let tempVar = null;
    models.map((item) => {
      if (item.idModele == model) {
        tempVar = item.Nom;
      }
    });

    return tempVar;
  };
  console.log(step);
  const renderContent = (values, handleChange, handleBlur, errors) => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            civilities={civilities}
            setCivility={setCivility}
            civility={civility}
            values={values}
            setErrEmail={setErrEmail}
            errEmail={errEmail}
          />
        );
      case 2:
        return (
          <SecondStep
            interventions={interventions}
            scenarios={scenarios}
            intervention={intervention}
            senario={senario}
            setSenario={setSenario}
            setSenarioType={setSenarioType}
            senarioType={senarioType}
            setIntervention={setIntervention}
            handleBlur={handleBlur}
            handleChange={handleChange}
            color={theme?.color}
          />
        );
      case 3:
        return (
          <ThirdStep
            values={values}
            alphabets={alphabets}
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            color={theme?.color}
            parentBrand={parentBrand}
            brands={brands}
            setBrand={setBrand}
            brand={brand}
            model={model}
            models={models}
            setModel={setModel}
            getVehicule={getVehicule}
            brVehicule={selectedCar}
            setMtrMiddle={setMtrMiddle}
            senarioType={senarioType}
          />
        );
      case 5:
        return (
          <FifthStep
            handleChange={handleChange}
            handleBlur={handleBlur}
            setSucursale={setSucursale}
            sucursale={sucursale}
            fulldate={fulldate}
            handleDate={handleDate}
            selectedTime={selectedTime}
            setDate={setDate}
            setSelectedTime={setSelectedTime}
            date={date}
            color={theme?.color}
            values={values}
            senario={senario}
            senarioType={senarioType}
            sucursales={sucursales}
            hours={hours}
            getModel={getModel}
            setIdHora={setIdHora}
            idHora={idHora}
            getHours={getHours}
          />
        );
      case 6:
        return (
          <SixthStep
            selectedTime={selectedTime}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            date={date}
            intervention={intervention}
            senario={senario}
            sucursale={sucursale}
            sucursales={sucursales}
            model={model}
            models={models}
            getModel={getModel}
            senarioType={senarioType}
            accepted={accepted}
            setaccepted={setaccepted}
            msg={msg}
          />
        );
      default:
        return;
    }
  };

  return (
    <Container color={theme?.color}>
      <CustomHelmet title="Rendez-vous" />
      <Header theme={theme} />
      <h1>Prendre rendez-vous.</h1>
      <div className="body">
        {step > 2 ? <Steps step={step} color={theme?.color} /> : null}
        <Formik
          enableReinitialize={true}
          initialValues={{
            fname: auth.fname ? auth.fname : "",
            lname: auth.lname ? auth.lname : "",
            phone: auth.user_phone ? auth.user_phone : "",
            email: auth.email ? auth.email : "",
            mtr1: immArray[0],
            mtr2: immArray[1],
            mtr3: immArray[2],
            nbr: selectedCar.NumSerie,
            accepted: true,
            marque: selectedCar.MrqVehi,
            descr: "",
            civility: civility,
          }}
          validationSchema={Yup.object({
            civility: Yup.string().required("Champ Obligatoire"),
            fname: Yup.string().required("Champ Obligatoire"),
            lname: Yup.string().required("Champ Obligatoire"),
            phone: Yup.string()
              .matches(
                /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/gm,
                "Numéro de téléphone est invalide"
              )
              .required("Champ Obligatoire"),
            email: Yup.string()
              .email("Invalid email adddress")
              .required("Champ Obligatoire"),
            nbr:
              step > 2
                ? Yup.string()
                    .min(17, "Doit contenir 17 caractères")
                    .max(17, "Doit contenir 17 caractères")
                : Yup.string(),
            mtr1:
              step > 2 && senarioType != "Commercial"
                ? Yup.string()
                    .matches(
                      /^[0-9]*$/,
                      "chiffre uniquement"
                    )
                    .required("Champ Obligatoire")
                : Yup.string(),
            mtr3:
              step > 2 && senarioType != "Commercial"
                ? Yup.string()
                    .matches(
                      /^[0-9]*$/,
                      "Merci de respecter le format 789999 A 14"
                    )
                    .required("Champ Obligatoire")
                : Yup.string(),
            accepted:
              step > 5
                ? Yup.string().required("Champ Obligatoire")
                : Yup.string(),
          })}
          onSubmit={(data) => {
            if (step === 6) {
              addRV(data);
            } else {
              setStep(step + 1);
            }
          }}
        >
          {({ handleSubmit, values, handleChange, handleBlur, errors }) => (
            <Form className="form">
              {renderContent(values, handleChange, handleBlur, errors)}
              <div className={step === 4 ? "map-wrp" : "map-hide"}>
                <FourthStep
                models={models}
                isLoadingForModels ={isLoadingForModels}
                setBrandForCommercial={setBrandForCommercial}
                modelForCommercial ={modelForCommercial}
                setModelForCommercial={setModelForCommercial}
                  setCity={setCity}
                  setSucursale={setSucursale}
                  city={city}
                  sucursale={sucursale}
                  values={values}
                  color={theme?.color}
                  senario={senario}
                  sucursales={sucursales}
                  cities={cities}
                  getModel={getModel}
                  coordinates={coordinates}
                  setCoordinates={setCoordinates}
                  isLoading={isLoading}
                  senarioType={senarioType}
                  brands={brands}
                />
              </div>
              <NavigationButtons
                step={step}
                setStep={setStep}
                handleSubmit={handleSubmit}
                setAction={setAction}
                selectedTime={selectedTime}
                color={theme?.color}
                senario={senario}
                senarioType={senarioType}
                addRV={addRV}
                values={values}
                errEmail={errEmail}
              />
            </Form>
          )}
        </Formik>
      </div>

      {action && step === 5 ? (
        <Popup
          subject="Avertissement"
          message="Veuillez sélectionner une heure disponible"
          setAction={setAction}
          dft="Annuler"
        />
      ) : null}

      {action === "success" && step === 6 ? (
        <Success
          message="Prière de ne pas dépasser 15 min de retard, sinon votre rdv sera annulé."
          setAction={setAction}
          setStep={setStep}
          color={theme.color}
        />
      ) : null}
      {action === "failed" && step === 6 ? (
        <Success
          message="Rendez vous existe dans la meme journée merci de le modifier."
          setAction={setAction}
          setStep={setStep}
          color={"#dc3545"}
          error
        />
      ) : null}
    </Container>
  );
};

export default App;

const Container = styled.div`
  background: #f7f8fc;
  min-height: 100vh;
  text-transform: unset !important;
  padding-bottom: 3em;
  h1 {
    width: 100%;
    text-align: center;
    color: ${(props) => props.color};
    margin: 3em 0 1em 0;
    letter-spacing: 1px;
  }
  h2 {
    color: ${(props) => props.color};
    margin-top: 2em;
    margin-bottom: 1em;
    letter-spacing: 1px;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .body {
    background: #fff;
    border-radius: 12px;
    padding: 3em 4em;
    margin: auto;
    max-width: 1100px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
  }
  .inputs {
    display: grid;
    grid-column-gap: 2.5em;
    grid-row-gap: 0.5em;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  input {
    width: 100%;
  }
  .select {
    min-width: 320px;
    width: 100%;
    padding: 10px;
    max-width: 470px;
  }
  select {
    font-size: 14px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    outline: none;
    color: #797575;
    cursor: pointer;
  }
  .width-unset {
    max-width: unset;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
  }
  .type-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 630px;
    margin: 4em auto;
  }
  .img {
    cursor: pointer;
    width: 230px;
    height: 180px;
    border-radius: 20px;
  }
  .type-input {
    cursor: pointer;
    width: 244px;
    height: 194px;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    background: transparent;
    border: 2px solid transparent;
    &:hover {
      border: ${(props) =>
        props.color ? `2px solid ${props.color}` : "2px solid #557bfc"};
    }
    span {
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      text-align: center;
    }
  }
  .red {
    color: red;
  }
  label {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
    margin-top: 1.5em;
  }
  .active-type {
    border: ${(props) =>
      props.color ? `2px solid ${props.color}` : "2px solid #557bfc"};
  }
  .city-select select {
    width: unset !important;
  }
  .select-wrp {
    display: flex;
    flex-direction: column;
  }
  h5 {
    font-weight: 700;
  }
  h3 {
    font-weight: 400;
    font-size: 24px;
    color: ${(props) => props.color};
    margin-top: 1em;
    width: 100%;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: -2.5em;
    span {
      color: #787885;
    }
  }
  .map-hide {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    .body {
      padding: 3em 1em;
    }
  }
  @media only screen and (max-width: 576px) {
    .type-inputs {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    .type-input {
      width: 100%;
      max-width: 480px;
      margin-bottom: 1em;
      height: 224px;
      img {
        width: 100%;
        height: 210px;
        object-fit: cover;
      }
    }
    h2 {
      font-size: 20px;
    }
    .body {
      padding: 3em 0.75em;
    }
    .inputs {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
    h6 {
      font-size: 14px !important;
    }
    h3 {
      font-size: 18px;
    }
    .back-btn {
      margin-left: 0px;
    }
    .btn {
      padding: 8px 22px;
    }
  }
`;
