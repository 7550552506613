import React, { useContext } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import AuthenticationContainer from "../components/elements/AuthenticationContainer";
import CustomHelmet from "../components/elements/CustomHelmet";
import ResetPasswordNewForm from "../components/ResetPasswordNewForm";
import ThemeContext from "../contexts/ThemeContext";
import Car from "../assets/ford.png";

const ResetPasswordNew = () => {
  let { token } = useParams();
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  console.log(token);
  return (
    <Container>
      <CustomHelmet title="Restorer mot de passe" />
      <AuthenticationContainer
        img={Car}
        slogan={theme?.slogan}
        color={theme?.color}
      >
        <ResetPasswordNewForm
          logo={REACT_APP_BACKEND_URL + theme?.logo}
          token={token}
        />
      </AuthenticationContainer>
    </Container>
  );
};

export default ResetPasswordNew;

const Container = styled.div``;
