import React from "react";
import styled from "styled-components";

const Header = ({ theme }) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  return (
    <Container color={theme.color}>
      <div className="logo-wrp">
        <img
          src={REACT_APP_BACKEND_URL + theme?.logo}
          alt="car"
          className="logo"
        />
      </div>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  background: ${(props) => props.color};
  width: 100%;
  height: 80px;
  position: relative;
  .logo {
    max-width: 130px;
    max-height: 60px;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .logo-wrp {
    position: absolute;
    left: 5em;
    top: 40px;
    background: #fff;
    padding: 1.5em 2em;
    border-radius: 20px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
  }
`;
