import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";
import styled from "styled-components";
import ResponseStatus from "../components/elements/ResponseStatus";
import Logo from "../assets/ford-logo.png";
import { ReactComponent as CallIcone } from "../assets/headset.svg";
import { Verify } from "crypto";;

const ActiverCompteForm = ({ logo, otp }) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  
  let history = useHistory();
  const MinutesToMiils = (minutes) =>{
    return minutes*1000*60
}
const formatTime = (time) => time > 10 ? time: `0${time}`;

  const location = useLocation();
  const {data, phone, email,isExist, isActive} = location.state;
  const [otpCode, setOtpCode] = useState("");
  const [btnsendmsg, setBtnsendmsg] = useState("Confirmer");
  const [response, setResponse] = useState();
  const [currentTime, setCurrentTime] = useState(MinutesToMiils(3));
  const minutesTotal = Math.floor(currentTime/1000/60)%60;
  const secondes = Math.floor(currentTime/1000)%60;
  const [isPaused, setIsPaused] = useState(true);

  const [errorsAuth, setErrorsAuth] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const interval = React.useRef(null);

 
  const handleSubmit = async (code) => {
    if (otp.data[0].codeverif === parseInt(code)) {
      history.push({ pathname: "ajouter-mdp" , userData});
    } else {
      setFeedback(true);
      setTimeout(() => {
        setFeedback(false);
      }, 3000);
    }
  };



  const SendUserOTP = (tel) => {
    let codeotp = Math.floor(100000 + Math.random() * 900000);
    setOtpCode(codeotp);
    console.log("code", codeotp);
    fetch(`${REACT_APP_BACKEND_URL}/api/sendUserOtp/${tel}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          // setotp(data);
          console.log("This is the dt", data);
        });
      })
      .catch((err) => {
        console.log("Fetch Error :-S", err);
      });
  };

  const deleteCode = async () => {
    try{
      const resp = await fetch(`${REACT_APP_BACKEND_URL}/api/delete/${phone}`);
      const respJson = await resp.json();
      console.log(respJson);
    }catch(e){
      console.log(e.message);
    }

  }

  const VerifyCode = (response) => {
    const {data} = location.state;
    if(response){
      console.log("code verify yes");
      //setBtnsendmsg("Page Login pour se connecter...");
      history.push({ pathname: "ajouter-mdp", data, phone, email,isExist, isActive});
      fetch(`${REACT_APP_BACKEND_URL}/active/${phone}`)
      .then((resp) => {
       if(resp.status === 200){
        deleteCode();
       }
      }).catch(e => console.log(e.message)); 
    } else {
      setBtnsendmsg("Réssayer");
    }
  
     
  }

 const handleSubmitForm = async (code) => {
   try{
    const resp = await fetch(`${REACT_APP_BACKEND_URL}/api/VerifyOtp/${phone}/${code}`);
    const respJson = await resp.json();
    setResponse(respJson.valide);
    VerifyCode(respJson.valide);

    
   }catch(e){
     console.log(e.message);
   }

 }


 

 {
   /*
    const VerifyOtp = () => {

    if (code.value == otpCode) {
      console.log("code verify yes");
      setBtnsendmsg("Page Login pour se connecter...");
      history.push({ pathname: "ajouter-mdp", state: userdata });
      fetch(`${REACT_APP_BACKEND_URL}/active/${tel}`)
      .then((resp) => {
       if(resp.status === 200){
         console.log("Good");
       }
      }).catch(e => console.log("ddd", e.message)); 
    } else {
      setBtnsendmsg("Réssayer");
    }
  };
   */
 }

 const endTime = () => {
  deleteCode();
  history.push('activer-choix');

}

 const countDown = () =>{
  setCurrentTime((time) => {
   if(time === 0) {
    setIsPaused(false);
    endTime();
       return ;
   }
    const timeLeft = time - 1000;
    
   return timeLeft;
  })
}

useEffect(() => {
  if(!isPaused) {
      if(interval.current) clearInterval(interval.current);
      return;
  }
  interval.current = setInterval(countDown, 1000);


  return () => clearInterval(interval.current);
}, [isPaused]);
  return (
    <Container>
      <img src={logo ? logo : Logo} alt="logo" className="logo" />
      <Formik
        initialValues={{
          code: "",
        }}
        validationSchema={Yup.object({
          code: Yup.string().required("Champ requise"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          {/*
            setBtnsendmsg("Loading...");
          setSubmitting(true);
          await handleSubmit(data.code);
          VerifyOtp();
          setSubmitting(false);
          */ }
          handleSubmitForm(data.code)
        }}
      >
        {({ handleChange, values, errors, isSubmitting }) => (
          <Form>
            <p>Veuillez saisir le code Confidentiel :</p>

            <div className="input-wrp">
              <input
                className="input"
                name="code"
                id="code"
                placeholder="Code d’activation"
                value={values.code}
                onChange={handleChange}
              />
               <h2 style={{color: 'red', fontSize: '14px'}}>Vous avez dû recevoir un texto avec un code sur votre téléphone. Ce code contient 6 caractères.
<br />{formatTime(minutesTotal)} : {formatTime(secondes)}</h2>
              {errors ? <div className="error">{errors.code}</div> : null}
              {errorsAuth ? (
                <div className="error">le code est incorrect</div>
              ) : null}
            </div>
            <button type="submit">
              {/* {isSubmitting ? "Loading..." : btnsendmsg} */}
              {btnsendmsg}
            </button>
            <p className="align-to-right">
              <CallIcone className="call-svg" />
              Vous n'avait pas recu le code ?
            </p>
          </Form>
        )}
      </Formik>
      {feedback ? (
        <ResponseStatus message="Le code saisi est incorrect" code={-1} />
      ) : null}
    </Container>
  );
};

export default ActiverCompteForm;

const Container = styled.div`
  //testing
  z-index: 1;
  background: #fff;
  border-radius: 20px;
  padding: 3.5em 2em;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  height: fit-content;
  width: 100%;

  .logo {
    margin-bottom: 2em;
  }
  p {
    color: #808080;
    font-size: 13px;
    margin: 0 auto 0.25em 0;
    cursor: pointer;
  }
  .align-to-right {
    margin: 0 0 0.25em auto;
    position: relative;
    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      left: -25px;
      top: 0;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .input {
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 0.75em 0;
  }
  button {
    background: #f0f5fd;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 2em 0;
    color: #919192;
  }
  .input-wrp {
    position: relative;
    width: 100%;
  }
  .call-svg {
    position: absolute;
    right: 0.75em;
    top: 1.7em;
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  @media only screen and (max-width: 992px) {
    padding: 3.5em 1em;
  }
`;
