
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ThemeContext from "../contexts/ThemeContext";
import Chart from "react-apexcharts";
import Odometer from "react-odometerjs";
import CustomPopUp from "../components/elements/CustomPopUp";
import "odometer/themes/odometer-theme-plaza.css";
import { ReactComponent as QuestionIcone } from "../assets/question.svg";
import Button from "../components/elements/Button";
import NumberFormat from "react-number-format";
import Spinner from "../components/Spinner";

const DashEntretienDetail = ({
  color,
  km,
  errKilo,
  kmactuel,
  IDVehicule,
  setNewKilometrage,
  setIsKiloLoaded,
  isKiloLoaded,
  isLoaded,
}) => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  const selectedCar = localStorage.getItem("selectedCar");
  const [errPi, serErrPi] = useState(null);
  const onSubmit = async (newValue) => {
    //const nn = parseInt(newValue.replace(/,/g, ""));

    try {
      const resp = await fetch(
        `${REACT_APP_BACKEND_URL}/kilometrage/${IDVehicule}/${parseInt(
          newValue
        )}`,
        {
          method: "GET",
        }
      );
      const respJson = await resp.json();
      if (respJson.code === 200) {
        setNewKilometrage(respJson.data.kilometrage_actuel);
        setAction(0);
      } else {
        serErrPi("Voiture non trouvé");
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  //popup tooltip
  const [tooltip, settooltip] = useState(false);
  const [action, setAction] = useState(0);
  const [isVisible, setIsVisible] = useState(1);
  const [newValue, SetNewValue] = useState(0);
  const [newK, setNewK] = useState(0);
  const [InputEvent, setinputv] = useState(0);
  const [progress, setProgress] = useState(0);
  const [err, seterr] = useState(false);
  const [btn, setBtn] = useState(false);
  let dummyvalue = localStorage.getItem("odoValue");
  const selectedC = localStorage.getItem("selectedCar");
  const [cercleGraph, setCercleGraph] = useState({
    options: {
      chart: {
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
            margin: 0,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
          },
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "100%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 0,
              fontSize: "22px",
            },
          },
        },
      },
      colors: ["#F2A026"],
      stroke: {
        lineCap: "round",
      },
    },
  });
  {
    /*
    useEffect(() => {
    if(dummyvalue){
      SetNewValue(dummyvalue);
    
     
    }
  }, [])
   */
  }
  useEffect(() => {
    setProgress((kmactuel * 100) / 200);
  }, [kmactuel]);
  useEffect(() => {
    if (km && kmactuel) {
      setProgress((kmactuel * 100) / km);
    }
  }, [kmactuel, km]);

  const handleSubmit = async (newValue) => {
    console.log(newValue);
    //const nn = parseInt(newValue.replace(/,/g, ""));
    const n = parseInt(kmactuel);
    if (parseInt(newValue) >= n) {
      SetNewValue(newValue);
      //changeGraph(newValue);
      await onSubmit(newValue);
    }
  };
  {
    /*
    const handleBlur = async (e) => {
    if (e.target.value) {
      const nn = parseFloat(e.target.value.replace(/,/g, ""));
      const n = parseFloat(kmactuel);
      console.log(e.target.value);
      if (nn >= n) {
        console.log("number is bigger");
        SetNewValue(e.target.value);
        changeGraph(e.target.value);
        await handleSubmit();
        localStorage.setItem("odoValue", e.target.value);
        
      } else {
        console.log("number is lower");
      }
    } else {
      //settooltip(1);
    }
  };
    */
  }

  const handledisabled = async (v) => {
    const nn = parseFloat(v.replace(/,/g, ""));
    const n = parseFloat(kmactuel);
    if (nn >= n) {
      seterr(false);
      SetNewValue(nn);
    } else {
      seterr(true);
    }
  };

  const changeGraph = (value) => {
    let maxvalue = km;
    setProgress((100 * value) / maxvalue);
  };

  const handleBtnClick = () => {
    setBtn(true);
    setAction(1);
    if (btn) {
      if (!dummyvalue) {
        setAction(1);
      } else {
        SetNewValue(dummyvalue);
      }
    }
  };

  useEffect(() => {
    changeOdometer();
  }, [newValue]);

  const changeOdometer = () => {
    setIsVisible(!isVisible);
  };
  const animateInput = () => {
    if (tooltip === true) {
      settooltip(false);
      setTimeout(() => {
        settooltip(true);
      }, 200);
    } else {
      settooltip(true);
    }
  };

  return (
    <Container className="entretien-compoenent">
      {isLoaded && km ? (
        <>
          <div className="top-row">
            <h5>Prochain Entretien : {km} Km</h5>
          </div>
          <div className="chart-wrp">
            <Chart
              options={cercleGraph.options}
              series={cercleGraph.series}
              type="radialBar"
              height={300}
              series={[km ? parseFloat(progress) : 0]}
            />
          </div>
          <div
            className={!isVisible ? `testing hidden` : `testing`}
            onClick={() => changeOdometer()}
          >
            <Odometer value={newValue} format="(,ddd).dd" duration={500} />
          </div>
          <div
            className={
              !isVisible
                ? `change-odometer-value `
                : `change-odometer-value hidden`
            }
          >
            <ShakeingInput
              className="inputOdo"
              name="num"
              id="num"
              type="text"
              value={kmactuel}
              thousandSeparator={true}
              onBlur={(e) => handleBlur(e)}
              onChange={(e) => handledisabled(e.target.value)}
              placeholder="compteur"
              disabled={true}
            />
            <QuestionIcone />
            <span className="tooltip">Veuillez saisir votre km actuel</span>
          </div>
          {err && (
            <p>le nombre doit être supérieur ou égal à votre km actuel</p>
          )}
          {action != 0 ? (
            <CustomPopUp
              subject="manque d'information"
              message="Veuillez saisir votre km actuel supérieur à "
              setAction={setAction}
              car={JSON.parse(selectedC)}
              dummyvalue={dummyvalue}
              icone
            >
              <div className="input-wrp">
                <ShakeingInput
                  thousandSeparator={true}
                  animate={tooltip}
                  name="popupvalue"
                  type="text"
                  onChange={(e) => handledisabled(e.target.value)}
                  placeholder="compteur"
                />
                {err && (
                  <p>le nombre doit être supérieur ou égal à votre km actuel</p>
                )}
                {errPi && <p>{errPi}</p>}

                <div className="btns-wrp">
                  <Button
                    title="Valider"
                    bg={color}
                    padding="8px 20px"
                    radius="5px"
                    color="#fff"
                    //disabled={err}
                    handleClick={() => {
                      handleSubmit(newValue);
                    }}
                  />
                </div>
              </div>
            </CustomPopUp>
          ) : null}
          <ButtonWrapper>
            <Btn
              disabled={!errKilo ? false : true}
              theme={theme}
              onClick={() => handleBtnClick()}
            >
              Paramétrer
            </Btn>
          </ButtonWrapper>
          {errKilo && <p>{errKilo}</p>}
        </>
      ) : (
        <Spinner />
      )}
    </Container>
  );
};

export default DashEntretienDetail;

const ShakeingInput = styled(NumberFormat)`
  background-color: #f2f2f2;
  padding: 10px 20px;
  border-radius: 7px;
  z-index: 2;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  animation: ${(props) =>
    props.animate
      ? "shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both"
      : null};
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

const Container = styled.div`
  flex: 1;
  align-self: stretch;
  background: #fff;
  border-radius: 12px;
  padding: 1em;
  margin: 0.25em 0.5em 0.5em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  //popup testing styles
  .input-wrp {
    display: flex;
    flex-direction: column;
    width: 100%;
    .btn-input {
      margin: 1.5em auto 0;
      padding: 5px 20px;
      border-radius: 5px;
      color: #fff;
      width: fit-content;
    }
    .btns-wrp {
      display: flex;
      width: 100%;
      padding: 1em 0;
      justify-content: center;
      button {
        margin: 0 0.5em;
      }
    }
  }
  .tooltip-popup {
    padding: 5px 20px;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    color: #fff;
    border-radius: 5px;
    width: fit-content;
    margin-top: 0.5em;
  }
  .inputOdo {
    background-color: #f2f2f2;
    padding: 5px;
    border-radius: 7px;
    width: 100px;
    text-align: center;
    z-index: 2;
    color: #000;
    font-weight: 500;
  }
  .change-odometer-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: -1.5em auto 0 auto;
    position: relative;
    &.hidden {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      margin: 0 0 0 1em;
    }
    svg:hover + span {
      visibility: visible;
    }
    .tooltip {
      visibility: hidden;
      font-size: 12px;
      font-weight: 500;
      font-family: "Helvetica", sans-serif;
      padding: 7px 10px;
      width: 200px;
      background-color: rgba(0, 0, 0, 0.85);
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 2;
      line-height: 1.5em;
      text-align: justify;
      right: 20px;
      top: -36px;
      &.show-tooltip {
        visibility: visible;
      }
    }
  }
  .testing {
    margin-top: -2em;
    display: flex;
    align-items: center;
    justify-content: center;
    &.hidden {
      display: none;
    }
    .odometer-auto-theme {
      font-size: 18px !important;
      background-color: #f3f3f3;
      padding: 0.25em 0.5em;
      border-radius: 12px;
    }
    .odometer-digit {
      color: #000;
      font-weight: 500;
    }
  }
  .input {
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 0.75em 0;
  }
  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 0;
  }
  .row {
    display: flex;
    margin: 1em 0;
    align-items: center;
  }
  h5 {
    font-size: 18px;
    font-weight: 600;
    color: #252733;
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .chart-wrp {
    max-height: 215px !important;
    overflow: hidden;
  }
  svg {
    max-height: 215px !important;
  }
  @media only screen and (max-width: 1800px) {
    .chart-wrp {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 576px) {
    .testing {
      margin-top: -1.5em;
    }
  }
  @media only screen and (max-width: 770px) {
    .chart-wrp {
      max-height: 180px !important;
      overflow: hidden;
    }
    svg {
      max-height: 180px !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
`;
const Btn = styled.button`
  background: ${(props) => (props.theme.color ? props.theme.color : null)};
  color: #fff;
  border-radius: 20px;
  padding: 10px 12px;
`;