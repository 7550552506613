import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Success } from "../../assets/success.svg";
import { ReactComponent as Fail } from "../../assets/fail.svg";

const ResponseStatus = ({ message, code, hidSvg, bg }) => {
  const [isActive, setIsActive] = useState(1);
  setTimeout(function () {
    setIsActive(0);
  }, 3000);
  return (
    <Container isActive={isActive} massage={message} code={code} bg={bg}>
      <span className="status-span">{message}</span>
      {!hidSvg ? (
        <div className="status-icone">
          {code === 1 ? <Success /> : <Fail />}
        </div>
      ) : null}
    </Container>
  );
};

export default ResponseStatus;

const Container = styled.div`
  border-radius: 10px;
  padding: 0.5em;
  display: ${(props) => (props.isActive ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  margin: 2em;
  position: fixed;
  background-color: ${(props) =>
    props.code === 1
      ? "#00DC72"
      : props.code === 0
      ? "rgb(248,75,96)"
      : props.bg};
  bottom: 0;
  right: 0;
  z-index: 2;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .status-span {
    color: #fff;
  }
  .status-icone {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /* svg{
    width: 30px;
    height:30px;
  } */
`;
