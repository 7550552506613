import React, { useEffect, useState } from "react";
import styled from "styled-components";

const HistoryTable = ({ type, data }) => {
  const [history, setHistory] = useState([]);
  const [dynamic, setDynamic] = useState(false);

  return (
    <Container className="table">
      <div className="grid">
        <div className="row title-row">
          <h6 className="tab-1">Opération</h6>
          <h6 className="tab-2">Date</h6>
          <h6 className="tab-3">Type</h6>
          <h6 className="tab-3">Marque</h6>
          <h6 className="tab-4">Prix</h6>
        </div>
        {data ? (
          data?.map((item, index) => {
            return (
              <div className="row service-row" key={index}>
                <h5 className="name data">
                  <span>{item?.name}</span>
                </h5>
                <h5 className="date">{item?.date}</h5>
                <h5 className="price">{item?.type}</h5>
                <img
                  src={item?.marque}
                  alt="marque logo"
                  className="marque-logo"
                />
                <h5 className="price">{item?.price}</h5>
              </div>
            );
          })
        ) : (
          <div className="nocontent-wrp">
            <p className="p-notes">pas de données pour le moment</p>
          </div>
        )}
      </div>
    </Container>
  );
};

export default HistoryTable;

const Container = styled.div`
  border-radius: 12px;
  overflow-x: scroll;
  .nocontent-wrp {
    padding: 1em 0 0 1em;
    margin: 1em 1em 0 1em;
    .p-notes {
      color: #9a9ca8;
      font-size: 14px;
    }
  }
  h5 {
    font-size: 15px;
    color: #252733;
    font-weight: 400 !important;
    span {
      font-weight: 500 !important;
    }
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
    color: #252733;
  }
  .detail-container {
    overflow: hidden;
    margin-right: 0.5em;
  }
  .name {
    white-space: nowrap;
  }
  .grid {
    min-width: 1100px;
  }
  .row {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    padding: 0.75em 0;
  }
  .marque-logo {
    width: 60px;
    height: 25px;
  }
  .title-row {
    border-bottom: 1px solid #dfe0eb;
    padding-bottom: 0.5em;
  }

  .type,
  .date {
    color: #726e6e;
  }
  .blue {
    background: #065cdd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 105px;
    padding: 4px 7px;
  }
  svg {
    fill: #fff;
  }
  @media only screen and (max-width: 768px) {
    h5 {
      font-size: 13px;
    }
    h6 {
      font-size: 13px;
      font-weight: 500;
    }
    button {
      font-size: 12px;
      width: 60px;
    }
    .blue {
      font-size: 12px;
      width: 90px;
    }
  }

  @media only screen and (max-width: 360px) {
    max-width: 340px !important;
    padding: 0 0.5em;
  }
`;
