import React, { useState } from "react";
import styled from "styled-components";
const FediliteOffre = ({ img }) => {
  return (
    <Container>
      <img src={img} />
    </Container>
  );
};

export default FediliteOffre;

const Container = styled.div`
  background-color: #fff;
  margin: 0 0.5em 0 0;
  border-radius: 12px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 290px;
  @media only screen and (max-width: 880px) {
    margin-left: 0;
  }
`;
