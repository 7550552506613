import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HistoryRdvTable = ({ type, data }) => {
  const extractDateTime = (d, type) => {
    const odj = {
      date: new Date(d).toTimeString(),
      time: new Date(d).toLocaleTimeString(),
    };
    if (type === "date") {
      return new Date(d).toLocaleDateString();
    } else if (type === "time") {
      return new Date(d).toLocaleTimeString();
    } else if (type === "all") {
      return odj;
    } else {
      return -1;
    }
  };

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
    return time;
  }

  if (data) {
    return (
      <Container>
        <div className="table">
          <div className="grid">
            <div className="rv-row rv-title-row">
              <h5>Opération</h5>
              <h6>Date</h6>
              <h6>Heure</h6>
              <h6>Statut</h6>
              <h6 />
            </div>
            {data.map((rv, index) => {
              return (
                <div className="rv-row" key={index}>
                  <h5 className="name">{rv.nomcomplet}</h5>
                  <h5 className="date">{rv.date2}</h5>
                  <h5 className="time">{rv.heure}</h5>

                  <button
                    className={`status ${
                      rv.type === "pending"
                        ? "attent"
                        : rv.type === "canceled"
                        ? "red-btn"
                        : "green"
                    }`}
                  >
                    {rv.type === "pending"
                      ? "En attent"
                      : rv.type === "canceled"
                      ? "Annulé"
                      : "Confirmé"}
                  </button>
                  {rv.type !== "canceled" ? (
                    <Link
                      to={{
                        pathname: "edit-rv",
                        state: { rv },
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="#777"
                      >
                        <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z"></path>
                      </svg>
                    </Link>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <div className="nocontent-wrp">
          <p className="p-notes">
            <span className="red">*</span>pas de données pour le moment
          </p>
        </div>
      </Container>
    );
  }
};

export default HistoryRdvTable;

const Container = styled.div`
  border-radius: 12px;
  .table {
    overflow-x: scroll;
  }
  .grid {
    min-width: 700px;
  }
  .nocontent-wrp {
    padding: 1em 0 0 1em;
    margin: 1em 1em 0 1em;
    border-top: 1px solid #dfe0eb;
  }
  h5 {
    font-size: 15px;
    font-weight: 500;
    color: #252733;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
    color: #252733;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .p-notes {
    color: #9a9ca8;
    font-size: 14px;
    .red {
      color: rgb(248, 75, 96);
      margin: 0 0.5em;
    }
  }
  .rv-row {
    display: grid;
    grid-template-columns: 5fr 1.5fr 1fr 1fr 0.5fr !important;
    padding: 0.75em 0;
  }
  button {
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 4px 0;
    border-radius: 7px;
    /* background: #eb0000; */
    width: 90px;
  }
  .red-btn {
    background-color: rgb(248, 75, 96);
    color: #fff !important;
  }
  .rv-title-row {
    border-bottom: 1px solid #dfe0eb;
    padding-bottom: 0.5em;
    margin-top: 2em;
  }
  .attent {
    background-color: #2d4185;
  }
  .green {
    background: #36870d;
  }

  .type,
  .date {
    color: #726e6e;
  }
  .blue {
    background: #065cdd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 105px;
    padding: 4px 7px;
  }
  svg {
    margin: 0 0.5em;
  }
  @media only screen and (max-width: 1050px) {
    .rv-row {
      grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr 30px !important;
    }
    .grid {
      min-width: 500px;
    }
  }
`;
