import React from "react";
import styled from "styled-components";
import Close from "../../assets/closehite.svg";

const Message = ({ error, setOpen, recl }) => {
  return (
    <Container error={error}>
      <span>
        {error
          ? "Quelque chose de mal, s’il vous plaît essayez à nouveau"
          : recl
          ? "Votre réclamation a été envoyé"
          : "Les données ont été mises à jour avec succès"}
      </span>
      <img src={Close} onClick={() => setOpen(false)} />
    </Container>
  );
};
export default Message;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background: ${(props) => (props.error.length ? "#dc3545" : "#28a745")};
  border-radius: 20px;
  margin-bottom: 11px;
  color: #fff;

  img {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
`;
