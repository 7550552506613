import React, { useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import Logo from "../assets/ford-logo.png";
import { ReactComponent as YourSvg } from "../assets/phone-call.svg";
import CustomInput from "./Rv/CustomInput";
import LogoAuoHall from '../assets/autohall.png';

const ActiverCompteForm = ({ logo, errors, setErrors }) => {
  const [inputType, setInputType] = useState("phone");
  const [buttonType, setButtonType] = useState("Loading...");
  const [errID, setErrID] = useState("");
  const [userData, setUserData] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  let history = useHistory();

  // const checkEmail = (userdata, inputvalue) => {
  //   if (userdata.Email.length === 0 || userdata.Email === null) {
  //     setErrors({ ...errors, value: true, message: "Email" });
  //   } else if (userdata.Email !== inputvalue) {
  //     setErrors({ ...errors, value: true, message: "Email" });
  //   } else if (userdata.Email === inputvalue) {
  //     history.push({ pathname: "activer-", state: { userdata } });
  //   }
  // };
   const sendOtpCode = async (data, tel, email,isExist, isActive) => {
     try{
       const resp = await fetch(`${REACT_APP_BACKEND_URL}/api/sendUserOtp/${tel}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
       const respJson = await resp.json();
       if(respJson.send){
         history.push('/activer-compte', {data, phone: tel, email,isExist, isActive});
       }
       

     }catch(e){
      console.log(e.message);
    }
   
  }
  const checkPhone = (userdata, value) => {
    console.log("dkhel l tchecked");
    console.log("value", value);
    console.log("userdata", userdata);
    if (
      (userdata.Tel1.length === 0 || userdata.Tel1 === null) &&
      (userdata.Tel2.length === 0 || userdata.Tel2 === null)
    ) {
      console.log("tele1 and tel2 are missing");
      setErrors({ ...errors, value: true, message: "Le Numéro de téléphone" });
    } else if (
      userdata.Tel1.length !== 0 &&
      userdata.Tel1 !== null &&
      userdata.Tel1 === inputvalue
    ) {
      setErrors({ ...errors, value: true, message: "Le Numéro de téléphone" });
      console.log(" tel1 is missing");
    } else if (
      userdata.Tel2.length !== 0 &&
      userdata.Tel2 !== null &&
      userdata.Tel2 === inputvalue
    ) {
      setErrors({ ...errors, value: true, message: "Le Numéro de téléphone" });
      console.log(" tel2 is missing");
    } else {
      history.push({
        pathname: "activer-compte",
        state: { userdata: userdata, value: value },
      });
    }
  };

  
  const checkAccount = async (id, data) => {
    try{
      const response = await fetch(`${REACT_APP_BACKEND_URL}/checkcustno/${id}/`);
      const responseJson = await response.json();
      
      if(responseJson.exist){
        if(responseJson.isActivated){
          setErrID("Compte déja activé")
        }else{
          await getUserInfo(data,true, false);
        }
      }else{
        await getUserInfo(data,false, false);
      }
    }catch(e){
      console.log(e.message);
    }
  }


  const updateProfile = async (email, id, tel) => {
    try{
      const response = await fetch(`${REACT_APP_BACKEND_URL}/api/updateProfile/${email}/${id}/${tel}`);
      const responseJson = await response.json();
      console.log(responseJson);
    }catch(e){
      console.log(e.message);
    }
  }

  const getUserInfo = async (data,isExist, isActive) => {
    console.log(data);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/checkuserId/${data.username}`,{
          method: "GET",
        }
      );
      const result = await response.json();
      console.log(result);
      if(result.exist){
        {
         
        await updateProfile(data.email,data.username, data.num);
        await sendOtpCode(result.userdata, data.num,data.email,isExist, isActive);
        
        }
       
      }else{
        setErrID("ID Client incorrect")
      }
     // processData(result.data, value, type);
    } catch (err) {
      console.log(err.message);
    }
  };


 {
   /*
    const processData = (userdata, value, type) => {
    console.log(userdata);
    console.log(value);

    if (
      (userdata.Tel1.length === 0 || userdata.Tel1 === null) &&
      (userdata.Tel2.length === 0 || userdata.Tel2 === null)
    ) {
      console.log("tele1 and tel2 are missing");
      setErrors({ ...errors, value: true, message: "Le Numéro de téléphone" });
    } else if (
      userdata.Tel1.length !== 0 &&
      userdata.Tel1 !== null &&
      userdata.Tel1 === value
    ) {
      history.push({
        pathname: "activer-compte",
        state: { userdata: userdata, value: value },
      });
      setErrors({ ...errors, value: true, message: "Le Numéro de téléphone" });
    } else if (
      userdata.Tel2.length !== 0 &&
      userdata.Tel2 !== null &&
      userdata.Tel2 === value
    ) {
      history.push({
        pathname: "activer-compte",
        state: { userdata: userdata, value: value },
      });
      setErrors({ ...errors, value: true, message: "Le Numéro de téléphone" });
      console.log(" tel2 is missing");
    } else {
    }
    // checkPhone(data, value);
    // history.push({
    //   pathname: "activer-compte",
    //   state: { userdata: userdata, value: value },
    // });
    //for now
  };
   */
 }
  return (
    <Container>
      <img src={LogoAuoHall} alt="logo" className="logo" />
      {errID ? <p className="error" style={{fontSize: "17px"}}>{errID}</p> : null}
      <Formik
        initialValues={{
          username: "",
          num: "",
          type: "phone",
        }}
        validationSchema={Yup.object({
          username: Yup.string().required("Champ obligatiore"),
          num:
            inputType === "email"
              ? Yup.string().required("Champ obligatoire")
              : Yup.string()
              .label("Numéro de téléphone")
                  .required("Champ obligatiore"),
          
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await checkAccount(data.username, data)
          //await getUserInfo(data.username, data.type, data.num);
          //await getUserInfo(data);
          
          // setButtonType("Page login pour se connecter");
          setSubmitting(false);
        }}
      >
        {({ handleChange, values, errors, isSubmitting }) => (
          <Form>
            <p>Activer mon compte par : </p>
            <div className="input-wrp">
            <CustomInput
              type="text"
              className="input"
              name="username"
              id="username"
              placeholder="Identifiant client"
              inside
            />
              {errors ? <div className="error">{errors.pwd}</div> : null}
              
            </div>
            <div className="input-wrp">
              <input
                className="input"
                name="num"
                id="num"
                placeholder={
                  values.type === "email"
                    ? "Adresse email"
                    : "Numéro de téléphone"
                }
                value={values.num}
                onChange={handleChange}
              />
              <YourSvg />

              {errors ? <div className="error">{errors.num}</div> : null}
              
              </div>
              <div className="input-wrp">
<input
 className="input"
 name="email"
 id="email"
 placeholder="Adresse email"
 value={values.email}
 onChange={handleChange}
/>
<svg
   width="16"
   height="16"
   viewBox="0 0 24 24"
   xmlns="http://www.w3.org/2000/svg"
   fillRule="evenodd"
   clipRule="evenodd"
   fill="#7f7f7f"
 >
   <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
 </svg>




</div>
             

            <button type="submit">
              {isSubmitting ? buttonType : "Activer"}
            </button>
            <span onClick={() => history.push('/login')}>Retour</span>
            
          </Form>
        
           
        )}
        
      </Formik>
    </Container>
  );
};

export default ActiverCompteForm;

const Container = styled.div`
  //testing
  z-index: 1;
  background: #fff;
  border-radius: 20px;
  padding: 3.5em 2em;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  height: fit-content;
  width: 100%;
  span{
    margin: 1em 0;
    cursor: pointer;
    color: #808080;
  }
  p {
    margin: 0 auto 0.25em 0;
  }
  .radio-wrp {
    margin: 0.5em auto 0.5em 0;
    display: flex;
    align-items: center;
    .radio-input {
      margin: 0 0.5em 0 0 !important;
    }
    label {
      margin: 0 1.5em 0 0 !important;
    }
  }
  input[type="radio"] {
    margin-left: 1.5em;
    margin-right: 0.3em;
  }
  .logo {
    margin-bottom: 2em;
  }
  p {
    color: #808080;
    font-size: 16px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .input {
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 0.75em 0;
  }
  button {
    background: #f0f5fd;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin-top: 2em;
    color: #919192;
  }
  .input-wrp {
    position: relative;
    width: 100%;
  }
  svg {
    position: absolute;
    right: 0.75em;
    top: 1.7em;
    width: 24px;
    height: 24px;
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  @media only screen and (max-width: 992px) {
    padding: 3.5em 1em;
  }
`;
