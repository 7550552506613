import React from "react";
import styled from "styled-components";
import Gift from "../assets/gift-icone.png";

const FediliteDetails = ({ data, color }) => {
  let moneyDefaultValue = 150000;
  if (data) {
    return (
      <Container color={color}>
        <div className="row title-row">
          <h6 className="icon"></h6>
          <h6>Type</h6>
          <h6>Date</h6>
          <h6>Montant</h6>
        </div>
        {data.map((item, index) => {
          return (
            <div className="row service-row" key={index}>
              <div className="img-wrp">
                <img src={Gift} alt="offer" className="icon" />
              </div>
              <h5>Réparation</h5>
              <h5 className="date">{item.date}</h5>
              <h5 className="price">
                {new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "MAD",
                }).format(moneyDefaultValue)}
              </h5>
            </div>
          );
        })}
      </Container>
    );
  } else {
    return (
      <Container>
        <h6>pas de chéques disponible</h6>
      </Container>
    );
  }
};

export default FediliteDetails;

const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 1em 2em;
  min-width: 320px;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  h5 {
    font-size: 15px;
    font-weight: 500 !important;
    color: #252733;
    margin: 0 !important;
  }
  .price {
    font-weight: 600 !important;
    color: ${(props) => (props.color ? props.color : "#4ad504")};
  }
  .row {
    display: grid !important;
    grid-template-columns: 70px repeat(2, 1fr) 0.5fr !important;
    padding: 0.5em 0;
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
    color: #726e6e;
  }
  .service-row {
    padding-bottom: 0.1em;
  }
  button {
    cursor: pointer;
    background: transparent;
    font-size: 16px;
    color: #3751ff;
    font-weight: 500;
  }
  .title-row {
    border-bottom: 1.5px solid #dfe0eb;
    margin-bottom: 0.2em;
  }
  .img-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .service-row {
    padding: 1em 0;
  }

  @media only screen and (max-width: 576px) {
    .img-wrp,
    .icon {
      display: none;
    }
    h5,
    .date {
      font-size: 12px;
    }
    h6 {
      font-size: 14px;
    }
    .montant {
      font-size: 12px !important;
    }
    .row {
      grid-template-columns: 1fr 1fr 0.7fr !important;
    }
  }
`;
