import React from "react";
import styled from "styled-components";

const HistoryDoc = ({ data }) => {
  console.log(data);
  if (data) {
    return (
      <Container className="table">
        <div className="grid">
          <div className="doc-row doc-title-row">
            <h5>Désignation</h5>
            <h6>Modifier le</h6>
            <h6>Type</h6>
            <h6>Action</h6>
          </div>
          {data.map((item, index) => {
            return (
              <div className="doc-row" key={index}>
                <h5 className="name">{item.type}</h5>
                <h5 className="name"></h5> 
                <h5 className="type">{item.link.split(".").pop()}</h5>
                <a href={item.link} target="_blank" download>
                  <button className="status blue">Télécharger</button>
                </a>
              </div>
            );
          })}
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <div className="nocontent-wrp">
          <p className="p-notes">pas de données pour le moment</p>
        </div>
      </Container>
    );
  }
};

export default HistoryDoc;

const Container = styled.div`
  border-radius: 12px;
  overflow-x: scroll;
  .nocontent-wrp {
    padding: 1em 0 0 1em;
    margin: 1em 1em 0 1em;
    border-top: 1px solid #dfe0eb;
    .p-notes {
      color: #9a9ca8;
      font-size: 14px;
    }
  }
  h5 {
    font-size: 15px;
    font-weight: 500;
    color: #252733;
    margin-right: 0 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
    color: #252733;
    margin-right: 0 !important;
  }
  .grid {
    min-width: 600px;
  }
  .doc-row {
    display: grid;
    grid-template-columns: 3fr 1.5fr 1fr 1fr !important;
    padding: 0.75em 0;
  }
  .doc-title-row {
    border-bottom: 1px solid #dfe0eb;
    padding-bottom: 0.5em;
    margin-top: 2em;
  }
  button {
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 4px 0;
    border-radius: 7px;
    background: #eb0000;
    width: 90px;
  }
  .price {
    color: #065cdd;
  }
  .type,
  .date {
    color: #726e6e;
  }
  .blue {
    background: #065cdd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 105px;
    padding: 4px 7px;
  }
  svg {
    fill: #fff;
  }
  @media only screen and (max-width: 768px) {
    .grid {
      min-width: 500px;
    }
    .doc-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr !important;
      padding: 0.75em 0;
    }
  }
`;
