import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";
import CustomHelmet from "../components/elements/CustomHelmet";
import SearchBar from "../components/SearchBar";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import File from "../assets/file.svg";
import { da } from "date-fns/locale";

const Archive = () => {
  const { theme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const { REACT_APP_BACKEND_URL } = process.env;
  // const datadoc = [
  //   { doc_name: "doc1", created_at: "15-10-2021 T 05:15" },
  //   { doc_name: "doc2", created_at: "15-10-2021 T 05:15" },
  //   { doc_name: "doc3", created_at: "15-10-2021 T 05:15" },
  // ];
  const getData = async (id) => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/docs/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setData(res);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDoc = async (id) => {
    try {
      const response = await fetch(REACT_APP_BACKEND_URL + "/docs/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      if (res.message) {
        const arr = data.filter((item) => item.doc_id !== id);
        setData(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // setData(datadoc);
    if (auth.id) {
      getData(auth.id);
    }
  }, [auth]);
  console.log(data);
  return (
    <Layout>
      <Container>
        <CustomHelmet title="Liste De Mes Documents" />
        <SearchBar
          title="Liste De Mes Documents"
          btnText="Ajouter"
          link="/add-archive"
          color="#fff"
          bg={theme?.color}
          noSearchBar
        />
        <div className="table">
          <div className="grid">
            <div className="row title-row">
              <h6 className="tab-1">Name</h6>
              <h6 className="tab-2">Date</h6>
              <h6 className="tab-3">Document</h6>
              <h6 />
            </div>
            {data.length !== 0 ? (
              data?.map((item, index) => {
                return (
                  <div className="row service-row" key={index}>
                    <h5 className="date">{item?.doc_name}</h5>
                    <h5 className="price">{item?.created_at.split("T")[0]}</h5>
                    <h5 className="name data">
                      {item.doc_url.includes("jpg") ||
                      item.doc_url.includes("png") ||
                      item.doc_url.includes("jpeg") ||
                      item.doc_url.includes("svg") ? (
                        <img
                          style={{ width: "3em", objectFit: "contain" }}
                          src={REACT_APP_BACKEND_URL + item?.doc_url}
                        />
                      ) : (
                        <img
                          style={{
                            width: "1.8em",
                            height: "2em",
                            cursor: "pointer",
                          }}
                          src={File}
                        />
                      )}
                    </h5>
                    <h5>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="#f84b60"
                        onClick={() => deleteDoc(item.doc_id)}
                      >
                        <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                      </svg>
                    </h5>
                  </div>
                );
              })
            ) : (
              <div className="nocontent-wrp">
                <p className="p-notes">pas de données pour le moment</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Archive;

const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 1em;
  padding-bottom: 4em;
  margin: 0.25em 0.5em 0.5em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .grid {

    width: 100%;
    display: flex;
    flex-direction: column;

  }
  .card {
    position: relative;
    width: 100%;
  }
  img {
    width: 100%;
    height: calc(100% - 30px);
    object-fit: cover;
    border-radius: 12px;
  }
  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .name {
    white-space: nowrap;
  }
  .type,
  .date {
    color: #726e6e;
  }
  .table {
    overflow-x: scroll;

  }
  .title-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfe0eb;
    padding-bottom: 0.5em;
    h6{
      width: calc(100%/3);
      text-align:center;
    }
  }
  .nocontent-wrp {
    padding: 1em 0 0 1em;
    margin: 1em 1em 0 1em;
    .p-notes {
      color: #9a9ca8;
      font-size: 14px;
    }
  }
  h5 {
    text-transform: capitalize;
    font-size: 15px;
    color: #252733;
    font-weight: 400 !important;
    span {
      font-weight: 500 !important;
    }
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
    color: #252733;
  }

  svg {
    cursor: pointer;
  }

  .service-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
     padding:9px 0;
    h5{
      width: calc(100%/3);
      text-align:center;
    }
  }
`;
