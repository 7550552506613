import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

const MyPoints = ({ points }) => {
  const [pieGraph, setpieGraph] = useState({
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#5FB1DF"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Points"],
    },
  });

  return (
    <Container>
      <div className="row">
        <h4>Mes Points</h4>
      </div>
      <Chart
        options={pieGraph.options}
        series={[points || 0]}
        type="radialBar"
        height={250}
      />
    </Container>
  );
};
export default MyPoints;

const Container = styled.div`
  padding: 0em 1em 0 1em !important;
  margin: 0em 0 0 1em;
  background-color: white;
  border-radius: 12px;
  //
  max-height: 290px;
  .chartjs-render-monitor {
    width: 100%;
    height: 100%;
  }
  canvas {
    max-height: 200px !important;
  }
  .row {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between;
    padding: 0.5em 0;
    height: 46px;
  }
  button {
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    color: #fff;
    background-color: #2d4185;
    font-weight: 500;
    padding: 5px 16px;
    border-radius: 7px;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .point-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .points {
    font-weight: 600;
    font-size: 0.9em;
  }
  .month {
    font-size: 0.7em;
  }
  @media only screen and (max-width: 1100px) {
    margin: 0em !important;
  }
`;
