import React, { useContext } from "react";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";
import CustomHelmet from "../components/elements/CustomHelmet";
import ThemeContext from "../contexts/ThemeContext";
import SearchBar from "../components/SearchBar";

const Notifications = () => {
  const { theme } = useContext(ThemeContext);
  const dummyData = [
    {
      date: "02/07/2021",
      name: "Notification#01",
      type: "Diagnostique",
    },
    {
      date: "02/07/2021",
      name: "Notification#01",
      type: "Diagnostique",
    },
    {
      date: "02/07/2021",
      name: "Notification#01",
      type: "Diagnostique",
    },
    {
      date: "02/07/2021",
      name: "Notification#01",
      type: "Diagnostique",
    },
  ];
  return (
    <Layout>
      <Container>
        <CustomHelmet title="Liste Notifications" />
        <SearchBar title="Liste des notification" />
        <div className="table">
          <div className="grid">
            <div className="row title-row">
              <h6 className="tab-1">Notification</h6>
              <h6 className="tab-2">Date</h6>
              <h6 className="tab-3">Contenu</h6>
            </div>
            {dummyData.length !== 0 ? (
              dummyData?.map((item, index) => {
                return (
                  <div className="row service-row" key={index}>
                    <h5 className="name data">
                      <span>{item?.name}</span>
                    </h5>
                    <h5 className="date">{item?.date}</h5>
                    <h5 className="price">{item?.type}</h5>
                  </div>
                );
              })
            ) : (
              <div className="nocontent-wrp">
                <p className="p-notes">pas de données pour le moment</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Notifications;

const Container = styled.div`
  padding: 2em 1em;
  margin: 1em;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .grid {
    min-width: 700px;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0.75em 0;
  }
  .name {
    white-space: nowrap;
  }
  .type,
  .date {
    color: #726e6e;
  }
  .table {
    overflow-x: scroll;
  }
  .title-row {
    border-bottom: 1px solid #dfe0eb;
    padding-bottom: 0.5em;
  }
  .nocontent-wrp {
    padding: 1em 0 0 1em;
    margin: 1em 1em 0 1em;
    .p-notes {
      color: #9a9ca8;
      font-size: 14px;
    }
  }
  h5 {
    font-size: 15px;
    color: #252733;
    font-weight: 400 !important;
    span {
      font-weight: 500 !important;
    }
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
    color: #252733;
  }
`;
