import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";
import HistoryTable from "../components/HistoryTable";
import CustomHelmet from "../components/elements/CustomHelmet";
import Logo from "../assets/ford-logo.png";
import SearchBar from "../components/SearchBar";

const Historique = () => {
  const dummyData = [
    {
      date: "02/07/2021",
      name: "RD#0123",
      type: "Diagnostique",
      price: 500,
      marque: Logo,
    },
    {
      date: "02/07/2021",
      name: "RD#0123",
      type: "Diagnostique",
      price: 500,
      marque: Logo,
    },
    {
      date: "02/07/2021",
      name: "RD#0123",
      type: "Diagnostique",
      price: 500,
      marque: Logo,
    },
    {
      date: "02/07/2021",
      name: "RD#0123",
      type: "Diagnostique",
      price: 500,
      marque: Logo,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const [initaldata, setInitaldata] = useState(dummyData);
  const [documents, setDocuments] = useState(dummyData);

  const filterByDate = (data) => {
    let newArray = [];
    let oldDate = new Date("2021-01-15");
    let newDate = new Date(data);

    if (newDate.getTime() === oldDate.getTime()) {
      setDocuments(initaldata);
    } else {
      setDocuments([]);
    }
  };

  return (
    <Layout>
      <Container>
        <CustomHelmet title="Liste Opérations" />
        <SearchBar title="Liste des opérations" />

        <HistoryTable data={documents} />
      </Container>
    </Layout>
  );
};

export default Historique;

const Container = styled.div`
  padding: 2em 1em;
  margin: 1em;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .page-name {
    font-size: 18px;
    font-weight: 600;
    display: none;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
  }
  .nav-row {
    display: flex;
    h5 {
      font-size: 17px;
      margin-right: 50px;
    }
    .second-wrap {
      display: flex;
    }
    .first-wrap {
      display: flex;
    }
    .select {
      border: none;
      padding: 6px;
      border-radius: 8px;
      font-size: 14px;
      color: #808080;
      width: 13.5em;
      margin-right: 50px;
      border: 1px #c4c4c4 solid;
      outline: none;
    }
    .date-input {
      width: 13em;
      border: 1px #c4c4c4 solid;
      text-align: center;
      border-radius: 6px;
      padding: 6px;
      color: #808080;
    }
  }

  .tabs-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tabs {
    justify-content: flex-start;
  }
  .btn {
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 16px;
    border-radius: 12px;
    background: #f2f2f2;
    color: #222;
    margin: 0 0.5em;
  }
  .active-tab {
    background-color: #2d4185;
    color: #fff;
  }
  .code {
    cursor: unset;
    color: #222;
  }
  .data-container {
    display: none;
  }
  .active-data {
    display: block;
  }
  @media only screen and (max-width: 1200px) {
    padding: 2em 1em;
    .nav-row h5 {
      font-size: 13px !important;
      margin-bottom: 10px;
    }
    .first-wrap,
    .second-wrap {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 768px) {
    .page-name {
      display: block;
    }
    margin: 1em 0.5em;
    .btn {
      margin: 0 0.25em;
    }
  }
  @media only screen and (max-width: 520px) {
    .nav-row {
      display: grid;
    }
    .row {
      grid-template-columns: 70% 14%;
    }
    .select {
      margin-bottom: 25px;
    }
  }
`;
