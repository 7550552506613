import React, { useContext } from "react";
import styled from "styled-components";
import AuthenticationContainer from "../components/elements/AuthenticationContainer";
import CustomHelmet from "../components/elements/CustomHelmet";
import ResetPasswordForm from "../components/ResetPasswordForm";
import ThemeContext from "../contexts/ThemeContext";
import Car from "../assets/ford.png";

const ResetPassword = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  // let location = useLocation();
  // console.log(location.state);

  return (
    <Container>
      <CustomHelmet title="Restorer mot de passe" />
      <AuthenticationContainer
        img={Car}
        slogan={theme?.slogan}
        color={theme?.color}
      >
        <ResetPasswordForm logo={REACT_APP_BACKEND_URL + theme?.logo} />
      </AuthenticationContainer>
    </Container>
  );
};

export default ResetPassword;

const Container = styled.div``;
