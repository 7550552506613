import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DetailButton from "./elements/DetailButton";
import SelectOptions from "./elements/SelectOptions";
import ThemeContext from "../contexts/ThemeContext";

const DashRv = ({ scenarios, rdvList, rvsError }) => {
  const { theme } = useContext(ThemeContext);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedS, setSelectedS] = useState("SAV");
  const dt = rdvList
    ? selectedS === "SAV"
      ? rdvList.filter((rv) => rv.interventionId != 4)
      : rdvList.filter((rv) => rv.interventionId == 4)
    : [];
  const handleChange = (e) => {
    e.target.value === "prochain" ? setActiveTab(1) : setActiveTab(2);
  };

  const extractDateTime = (d, type) => {
    const odj = {
      date: new Date(d).toTimeString(),
      time: new Date(d).toLocaleTimeString("fr-FR"),
    };
    if (type === "date") {
      return new Date(d).toLocaleDateString("fr-FR");
    } else if (type === "time") {
      return new Date(d).toLocaleTimeString();
    } else if (type === "all") {
      return odj;
    } else {
      return -1;
    }
  };

  return (
    <Container color={theme?.color}>
      <div className="row1 title-row">
        <div className="rdv-title">
          <h5 className="h5-title">Details Rendez-vous</h5>
          <Link to="/rendez-vous" className="wrp">
            <DetailButton title="Détail" />
          </Link>
        </div>
        <div className="rdv-title-btns">
          <SelectOptions
            options={scenarios}
            bg="#065cdd"
            margin="0.25em 0.25em"
            padding="9px 0"
            handleChange={() => null}
            setSelectedS={setSelectedS}
            selectedS={selectedS}
          />
          <Link to="/rv" className="wrp" target="_blank">
            <button className="btn">Nouveau rendez-vous</button>
          </Link>
        </div>
      </div>
      <div className="rv-list-wrp">
        {typeof rdvList !== "undefined" && rdvList ? (
          dt.map((rv, index) => {
            return (
              <div className="row-rdv service-row" key={index}>
                <h5 className="name">{rv.nomcomplet}</h5>
                <h5 className="date">{rv.date2}</h5>
                <h5 className="time">
                  {/* {extractDateTime(Math.floor(rv.date), "time")} */}
                  {rv.heure}
                </h5>
                <button
                  className={`btn status ${
                    rv.type === "pending"
                      ? "attent"
                      : rv.type === "canceled"
                      ? "red"
                      : "green"
                  }`}
                >
                  {rv.type === "pending"
                    ? "En attent"
                    : rv.type === "canceled"
                    ? "Annulé"
                    : "Confirmé"}
                </button>
                {rv.type !== "canceled" ? (
                  <Link
                    to={{
                      pathname: "edit-rv",
                      state: { rv },
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="#777"
                    >
                      <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z"></path>
                    </svg>
                  </Link>
                ) : null}
              </div>
            );
          })
        ) : (
          <p className="p-notes">
            <span className="red-p">*</span>pas de données...
          </p>
        )}
      </div>
      {/* {activeTab === 1 ? (
        
      ) : (
        <p className="p-notes">
          <span className="red">*</span>pas de données...
        </p>
      )} */}
    </Container>
  );
};

export default DashRv;

const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 0.5em 1em 1em 1em;
  margin: 1em 0.5em 0.75em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .rdv-title-btns {
    display: flex;
    flex-wrap: wrap;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    color: #252733;
    display: inline;
    margin: auto 0;
  }
  .row1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 0.5em 0;
  }
  .rv-list-wrp {
    max-height: 180px;
    overflow-y: scroll;
  }
  .rdv-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .h5-title {
      font-size: 18px;
      font-weight: 600;
      color: #252733;
      display: inline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .row-rdv {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 95px 35px !important;
    padding: 0.5em 0;
    svg {
      width: 20px;
      height: 20px;
      margin: auto 0.25em 15px 0.25em;
    }
  }
  .service-row {
    border-bottom: 1px solid #dfe0eb;
  }
  .btn {
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    margin: 0.25em;
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    display: inline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .active-tab {
    background-color: ${(props) => (props.color ? props.color : "#2d4185")};
    color: #fff;
  }
  .more-detail {
    cursor: pointer;
    font-size: 14px;
    color: #3751ff;
    background-color: #fff;
    font-weight: 500;
    padding: 10px;
    border-radius: 7px;
  }
  .select-options {
    outline: none;
    border: none;
    background-color: #f3f3f3;
    font-size: 14px;
    width: fit-content;
    color: black;
    background-color: #f3f3f3;
    font-weight: 500;
    padding: 10px;
    border-radius: 7px;
  }
  .title-row {
    margin-bottom: 1em;
  }
  .p-notes {
    color: #9a9ca8;
    font-size: 14px;
    margin: 10px 0;
    .red-p {
      color: rgb(248, 75, 96);
      margin: 0 1em;
    }
  }
  .red {
    margin: 0 0.5em;
    color: #fff;
    background-color: rgb(248, 75, 96) !important;
  }
  .green {
    background: #36870d;
    padding: 7px !important;
    color: #fff !important;
  }
  .attent {
    background: #2d4185;
    padding: 7px !important;
    color: #fff !important;
  }
  .price {
    color: #065cdd;
    //new changes
    display: none;
  }
  /* .type {
    display: none;
  } */
  .time,
  .date {
    color: #726e6e;
  }
  @media only screen and (max-width: 999px) {
    width: 100%;
    .row1 {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  @media only screen and (max-width: 800px) {
    .top-btn {
      font-size: 12px;
    }
    h5 {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 600px) {
    margin: 1em 0 0.5em 0;
    .row1 {
      flex-wrap: wrap;
    }
  }
  @media only screen and (max-width: 576px) {
    h5 {
      font-size: 13px;
    }
    padding: 1em;
  }
  @media only screen and (max-width: 450px) {
    .status {
      display: none !important;
    }
    .time {
      display: none;
    }
    .row-rdv {
      display: grid;
      grid-template-columns: 1.5fr 0.75fr 30px !important;
    }
    .rdv-title-btns {
      justify-content: space-between;
    }
  }
`;
