import React, { useContext } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import AuthenticationContainer from "../components/elements/AuthenticationContainer";
import CustomHelmet from "../components/elements/CustomHelmet";
import NewPasswordForm from "../components/NewPasswordForm";
import ThemeContext from "../contexts/ThemeContext";
import Car from "../assets/ford.png";

const NewPassword = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  let location = useLocation();
  console.log(location.state);

  return (
    <Container>
      <CustomHelmet title="Restorer mot de passe" />
      <AuthenticationContainer
        img={Car}
        slogan={theme?.slogan}
        color={theme?.color}
      >
        <NewPasswordForm
          logo={REACT_APP_BACKEND_URL + theme?.logo}
          userdata={location.state}
        />
      </AuthenticationContainer>
    </Container>
  );
};

export default NewPassword;

const Container = styled.div``;
