import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Button from "../components/elements/Button";
import CustomInput from "../components/elements/CustomInput";
import ResponseStatus from "../components/elements/ResponseStatus";

const ContactForm = ({ setActiveTab, activeTab, color }) => {
  const [isChecked, setIsChecked] = useState("email");
  const [emailError, setEmailError] = useState({ status: 0, content: "" });
  const [emailSent, setEmailSent] = useState({ status: 0, content: "" });
  const { REACT_APP_BACKEND_URL } = process.env;
  const {auth} = useContext(AuthContext);
  console.log(auth);
  const handleSubmit =  (canalType, categorie, messageContent) => {
    setEmailError({ status: 0, content: "" });
    setEmailSent({ status: 0, content: "" });
    fetch(`${REACT_APP_BACKEND_URL}/sendEmail`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        canalType: canalType,
        categorie: categorie,
        messageContent: messageContent,
      }),
    })
      .then((response) => {
        response.json().then(function (data) {
          console.log(data);
          if (data.mesg === "success") {
            setEmailSent({ status: 1, content: "passed" });
            setEmailError({ status: 0, content: "" });
            console.log("Hello");
          } else if (data.mesg === "erreur") {
            setEmailSent({ status: 0, content: "" });
            setEmailError({ status: 1, content: "failed" });
            console.log("Hello");
          }
        });
      })
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      });
  };

  const sendEmailToContact = async (canalType, categorie, messageContent) => {
    try{
      const resp = await fetch(`${REACT_APP_BACKEND_URL}/sendEmail`, {
        method:'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          canalType: canalType,
          categorie: categorie,
          messageContent: messageContent,
          subject: categorie + ", " +messageContent,
          name: auth.fname + " " + auth.lname,
          userId : auth.IDClient,
          brand_id: "2",
          message: categorie + ", " +messageContent,
          email: auth.email,
          phone: auth.user_phone
        })
      });
      const respJson = await resp.json();
      console.log("This is the respJson", respJson);
      if(respJson.message === "success"){
        setEmailSent({ status: 1, content: "passed" });
        setEmailError({ status: 0, content: "" });

      }else{
        setEmailSent({ status: 0, content: "" });
        setEmailError({ status: 1, content: "failed" });
      }
    }catch(e){
      setEmailSent({ status: 0, content: "" });
      setEmailError({ status: 1, content: "failed" });
      console.log(e.message);
    }

  }
  return (
    <Container>
      <Formik
        initialValues={{
          canal: "email",
          categorie: "Information",
          message: "",
          email: "test@gmail.com",
          phone: "06666666"
        }}
        validationSchema={Yup.object({
          canal: Yup.string().required("Champ obligatoire"),
          categorie: Yup.string().required("Champ obligatoire"),
          message: Yup.string().required("Champ obligatoire"),
        })}
        onSubmit={ (data, { setSubmitting }) => {
        
         console.log(data);
            setSubmitting(true);
            sendEmailToContact(data.canal, data.categorie, data.message)
        
          setSubmitting(false);
        
        }}
      >
        {({ handleChange, values, errors, isSubmitting }) => (
          <Form className="form">
            <div className="radio-wrp">
              <span>Canal de réponse</span>
              <input
                type="radio"
                id="email"
                name="canal"
                value="email"
                onChange={() => {
                  setIsChecked("email");
                  console.log(isChecked);
                }}
                checked={isChecked === "email"}
              />

              <label htmlFor="email">Email</label>
              <input
                type="radio"
                id="sms"
                name="canal"
                className="input"
                value="sms"
                onChange={() => {
                  setIsChecked("sms");
                  console.log(isChecked);
                }}
                checked={isChecked === "sms"}
              />
              <label htmlFor="sms">SMS</label>
            </div>

            <input
              className="extra-input"
              type="text"
              id="sms"
              name="canal"
              value={
                isChecked === "email" ? `${auth.email}` : `${auth.user_phone}`
              }
              onChange={handleChange}
            />
            <div className="select-wrp">
              <select
                placeholder="Catégorie"
                className="bg-select"
                name="categorie"
                onChange={handleChange}
              >
                <option value="Information">Information</option>
                <option value="Autre">Autre</option>
              </select>
            </div>

            <CustomInput
              name="message"
              id="message"
              type="text"
              bg="#f2f2f2"
              placeholder="Objet de message"
              textarea
            />
            <div className="btn-wrp">
              {/* <Button
                handleClick={() => {
                  setActiveTab ? setActiveTab(!activeTab) : null;
                  console.log("clicked");
                }}
                title="Map"
                margin="2.5em 0.25em 0 0.25em"
                type="reset"
                color="#fff"
                bg={color ? color : "#2D4185"}
              /> */}
              <Button
                
                margin="2.5em 0.25em 0 0.25em"
                title={isSubmitting ? "Envoyer..." : "Envoyer"}
                type="submit"
                color="#fff"
                bg={color ? color : "#2D4185"}
              />
            </div>
          </Form>
        )}
      </Formik>
      {emailError.status !== 0 && (
        <ResponseStatus
          code={emailError.content}
          message="Erreur lor de l'envoie de l'email"
          code={-1}
        />
      )}
      {emailSent.status !== 0 && (
        <ResponseStatus code={1} message="Email envoyer" />
      )}
    </Container>
  );
};

export default ContactForm;

const Container = styled.div`
  border-radius: 12px;
  border: 4px solid rgba(0, 0, 0, 0.05);
  padding: 2em;
  margin: 2em 0;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  .extra-input {
    width: 100%;
    background-color: #f2f2f2;
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    outline: none;
    color: #000;
    margin: 0 0 1em 0;
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1em;
  }
  textarea {
    width: 100%;
  }
  select {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    //min-width: 320px;
    width: 100%;
    outline: none;
    color: #797575;
    cursor: pointer;
  }
  .bg-select {
    background-color: #f2f2f2;
  }
  span {
    color: #797575;
    margin-right: 1.5em;
    font-size: 16px;
    margin-left: 0.2em;
    font-weight: 400;
  }
  .radio-wrp {
    margin-bottom: 1.5em;
    display: flex;
    align-items: center;
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  .succee {
    color: green;
    font-size: 12px;
    font-weight: 500;
  }
  input[type="radio"] {
    margin-left: 1.5em;
    margin-right: 0.3em;
  }
  @media only screen and (max-width: 576px) {
    padding: 2em 0.5em;
  }
`;
