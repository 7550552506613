import React, { useState } from "react";
import styled from "styled-components";

const CarTechniqueInfo = ({ color, dt }) => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <Container color={color}>
      <h2>Données Techniques</h2>
      <div className="tabs">
        <button
          className={activeTab === 1 ? "active-tab btn" : "btn"}
          onClick={() => setActiveTab(1)}
        >
          Généralités
        </button>
        <button
          className={activeTab === 2 ? "active-tab btn" : "btn"}
          onClick={() => setActiveTab(2)}
        >
          Version
        </button>
        <button
          className={activeTab === 3 ? "active-tab btn" : "btn"}
          onClick={() => setActiveTab(3)}
        >
          Confort
        </button>
        <button
          className={activeTab === 4 ? "active-tab btn" : "btn"}
          onClick={() => setActiveTab(4)}
        >
          Multimédia
        </button>
        {/* <button
          className={activeTab === 5 ? "active-tab btn" : "btn"}
          onClick={() => setActiveTab(5)}
        >
          Etat
        </button> */}
      </div>
      <div className="tab-containers">
        <div
          className={
            activeTab === 1 ? "tab-container active-container" : "tab-container"
          }
        >
          <p className="label">Code moteur:</p>
          <p className="data">{dt?.moteur ? dt?.moteur : "non disponible"}</p>
          <p className="label">Code transmission:</p>
          <p className="data">{dt?.transmission ?dt?.transmission   : "non disponible"}</p>
          <p className="label">Coleur:</p>
          <p className="data">{dt?.CouleurVeh ? dt?.CouleurVeh : "non disponible"}</p>
        </div>
        <div
          className={
            activeTab === 2 ? "tab-container active-container" : "tab-container"
          }
        >
          {/* <p className="label">Code moteur:</p> */}
          {/* <p className="data">Moteur essence 1.6 Diesel 136</p>
          <p className="label">Code transmission:</p>
          <p className="data">Trans Auto 6 Speed</p>
          <p className="label">Coleur:</p>
          <p className="data">Blue</p> */}
        </div>
        <div
          className={
            activeTab === 3 ? "tab-container active-container" : "tab-container"
          }
        >
          {/* <p className="label">Code moteur:</p> */}
          {/* <p className="data">Moteur essence 1.6 Diesel 137</p>
          <p className="label">Code transmission:</p>
          <p className="data">Trans Auto 6 Speed</p>
          <p className="label">Coleur:</p>
          <p className="data">Blue</p> */}
        </div>
        <div
          className={
            activeTab === 4 ? "tab-container active-container" : "tab-container"
          }
        >
          {/* <p className="label">Code moteur:</p> */}
          {/* <p className="data">Moteur essence 1.6 Diesel 138</p>
          <p className="label">Code transmission:</p>
          <p className="data">Trans Auto 6 Speed</p>
          <p className="label">Coleur:</p>
          <p className="data">Blue</p> */}
        </div>
        {/* <div
          className={
            activeTab === 5 ? "tab-container active-container" : "tab-container"
          }
        >
          <p className="label">Type d’entretient:</p>
          <p className="data">Mécanique, changement de pieces </p>
          <p className="label">Etat de véhicule:</p>
          <p className="data">En bon état</p>
          <p className="label">Autre information:</p>
          <p className="data">Autre information </p>
          <p className="label">Mon prochaine rendez-vous</p>
          <p className="data">Le 19 Avril, 2021</p>
        </div> */}
      </div>
    </Container>
  );
};

export default CarTechniqueInfo;

const Container = styled.div`
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  background-color: #fff;
  margin: 2em;
  padding: 2em;
  border-radius: 20px;
  h2 {
    margin-bottom: 2em;
  }
  .tabs {
    display: flex;
    margin-bottom: 2em;
    align-items: center;
  }
  .btn {
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 100px;
    background: #f2f2f2;
    color: #222;
    margin-right: 1em;
  }
  .active-tab {
    background-color: ${(props) => (props.color ? props.color : "#2d4185")};
    color: #fff;
  }
  .tab-container {
    display: none;
    grid-template-columns: 1fr 1fr;
  }
  .active-container {
    display: grid;
    max-width: 700px;
  }
  .data {
    color: ${(props) => (props.color ? props.color : "#2d4185")};
    font-size: 16px;
    font-weight: 500;
    padding: 0.5em 0;
    text-align: left;
  }
  .label {
    padding: 0.5em 0;
    font-size: 16px;
  }
  @media only screen and (max-width: 850px) {
    .tabs {
      flex-wrap: wrap;
    }
    .btn {
      margin-bottom: 1em;
    }
  }

  @media only screen and (max-width: 992px) {
    padding: 2em 1em;
  }
  @media only screen and (max-width: 576px) {
    margin: 2em 0.5em;
    padding: 2em 0.5em;
    p {
      font-size: 14px !important;
    }
    .tabs {
      flex-wrap: wrap;
    }
    .btn {
      margin-bottom: 1em;
    }
  }
`;
