import React, { useState ,useContext} from "react";
import AbortController from "abort-controller"
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Ford1 from "../assets/ford.png";
import ThemeContext from "../contexts/ThemeContext";
import Spinner from "./Spinner";

const MyCarsCart = ({ img, title, type, logo, imma, annee, color, uid, ModelVehi, one }) => {
  const {REACT_APP_BACKEND_URL} = process.env;
  const [imgUrl, setImgUrl] = useState();
  const history = useHistory();
  const {theme} = useContext(ThemeContext);
  const [isFetched, setIsFetched] = useState(false)
  console.log(theme);


  React.useEffect(() => {
    

   
      fetch(`${REACT_APP_BACKEND_URL}/api/model/${type}`)
    .then(resp => resp.json())
    .then(respJson  => {
      setImgUrl(respJson.data.image);
      setIsFetched(true)
    })
    .then(n => console.log(imgUrl))
    .catch(e => console.log(e.message))
    
    
   }, []);
  return (
    <Container color={color} one={one} onClick={() => history.push(`/vehicule-details/${uid}`)}>

      <div className="imgContainer">
        {
          isFetched ?
          imgUrl ? (<img src={imgUrl} />) :(<img src={Ford1} />)  : (<Spinner />)
        }
      </div>
      <div className="details" style={{backgroundColor: theme.color}}>
          <div className="wrap">
          <div className="text">
          <span>Marque : {title}</span>
          <span>Modéle : {type}, {annee}</span>
          </div>
          <img src={logo} />
        </div>
        <Buttosn theme={theme}>Détails</Buttosn>
          </div>
    </Container>
  );
};

export default MyCarsCart;
const Buttosn = styled.span`
background-color: #fff;
color: ${props => props.theme.color};
border-radius: 10px;
padding: 5px 20px;
align-self:flex-end;
margin-top: 20px;
`;

const Container = styled.div`
width: ${props => props.one ? '70%' : '100%'};
margin: ${props => props.one ? 'auto' : '0'};
display: flex;
flex-direction: ${props => props.one ?"row" : "column"};
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
background-color: #fff;
cursor: pointer;
box-sizing: border-box;
position: relative;
@media screen and (max-width: 1000px){
  flex-direction: column;;
}
.imgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: .5;
  padding: 16px;
  outline: none;
  border:none;
  img{
    height: 100%;
    width: 100%;
  }
}
.details{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: .5;
  color :#fff;
  padding: 25px;
  border-top-left-radius: 70px;
  @media screen and (max-width: 1000px){
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
}
  img{
    max-width: 200px;
  }

  
}
.wrap{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
span{
  display: block;
}

.info{
  margin-left: 20px;
}
  a {
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 1em 2em;
    grid-gap: 3%;
  }

  .title {
    font-size: 30px;
  }
  .logo {
    margin: 1em 0;
    width: 100%;
    max-width: 150px;
  }
  .car-img {
    width: 60%;
    height: 60%;
    max-height: 300px;
    max-width: 200px;
    margin-top: 2em;
  }
  .type {
    display: inline;
    font-weight:bold;
    font-size: ${props => props.one ? '20px' : '16px'};
    color: #000;
    &:hover {
      color: #2d4185;
    }
  }
  button {
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    color: #fff;
    background-color: ${(props) => (props.color ? props.color : "#2d4185")};
    font-weight: 500;
    padding: 5px 16px;
    border-radius: 7px;
    margin-right: 0.5em;
    margin-top: 0.5em;
  }
  .first-column {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    
  }
  .second-column {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 1em;
  }
  @media only screen and (max-width: 576px) {
    a {
      grid-gap: 1%;
      grid-template-columns: 25% 75%;
      padding: 1em;
    }
    .title {
      font-size: 22px;
    }
    .type {
      font-size: 20px;
      font-weight:bold;
    }
    .logo {
      max-width: 50px;
    }
    button {
      font-size: 14px;
    }
  }
`;

