import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcone } from "../../assets/close.svg";
import { ReactComponent as InfoIcone } from "../../assets/info.svg";
import Close from "../../assets/close.svg";

const CustomPopup = ({dummyvalue, message, icone, subject, dft, setAction, children, car }) => {
  console.log(car)
  return (
    <Container>
      <div className="popup-container">
        <img src={Close} onClick={() => setAction(0)}/>
        {icone ? (
          <div className="popup-head">
            <InfoIcone />
          </div>
        ) : null}

        <div className="popup-body">
          <p>{message + dummyvalue}</p>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default CustomPopup;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  .popup-container {
    border: 1px solid rgba(255, 255, 255, 0.18);
    max-width: 400px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    img{
      width: 20px;
      height: 20px;
      margin:9px;
      align-self: flex-end;
      cursor: pointer;
    }
  }
  .popup-head {
    padding: 0.75em 1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: center;
  }
  svg {
    width: 60px;
    height: 60px;
    align-self: center;
    margin-top: 1em;
  }
  h4 {
    font-weight: 600;
    line-height: 1.25;
    font-size: 20px;
    color: #323a45;
  }
  p {
    color: #5a616a;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1em;
  }
  .popup-body {
    min-height: 121px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
  .popup-footer {
    padding: 22px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .popup-btn {
    padding: 10px;
    border-radius: 4px;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    &:hover {
      filter: brightness(95%);
    }
  }
  .btn-1 {
    color: #5a616a;
    background: #e4ebf1;
  }
  .btn-2 {
    color: #fff;
    background: #f44235;
  }
  .btn-3 {
    color: #fff;
    background: #30c78a;
  }
`;
