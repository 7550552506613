import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import LoginForm from "../components/LoginForm";
import AuthenticationContainer from "../components/elements/AuthenticationContainer";
import CustomHelmet from "../components/elements/CustomHelmet";
import Car from "../assets/ford.png";
import ThemeContext from "../contexts/ThemeContext";

const Login = () => {
  const { REACT_APP_BACKEND_URL } = process.env;
  const { theme } = useContext(ThemeContext);
  console.log(theme);
  return (
    <Container className="login">
      <CustomHelmet title="Login" />
      <AuthenticationContainer
        img={Car}
        slogan={theme?.slogan}
        color={theme?.color}
      >
        <LoginForm logo={REACT_APP_BACKEND_URL + theme?.logo} />
      </AuthenticationContainer>
    </Container>
  );
};

export default Login;

const Container = styled.div``;
