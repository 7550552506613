import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router";
import * as Yup from "yup";
import jwt from "jsonwebtoken";
import AuthContext from "../contexts/AuthContext";
import styled from "styled-components";
import Logo from "../assets/ford-logo.png";
import { ReactComponent as Password } from "../assets/padlock.svg";

const ResetPasswordNewForm = ({ logo, userdata, token }) => {
  const { REACT_APP_BACKEND_URL, REACT_APP_JWT_TOKEN } = process.env;
  const { auth, setAuth } = useContext(AuthContext);
  const [errorsAuth, setErrorsAuth] = useState(false);

  let history = useHistory();
  const changePassword = async (pwd) => {
    let email = null;
    jwt.verify(token, REACT_APP_JWT_TOKEN, (err, decoded) => {
      if (decoded) {
        email = decoded;
      }
    });
    if (email !== null) {
      try {
        const response = await fetch(`${REACT_APP_BACKEND_URL}/restorer`, {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({ email, pwd }),
        });
        const result = await response.json();
        if (result.message === "succes") {
          localStorage.setItem("auth", JSON.stringify(result.token));
          jwt.verify(result.token, REACT_APP_JWT_TOKEN, (err, decoded) => {
            if (decoded) {
              history.push("/car-choice");
              setAuth(decoded);
            }
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const togglePassword = (password) => {
    const input = document.getElementById(password);
    if (input) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  };

  return (
    <Container>
      <img src={logo ? logo : Logo} alt="logo" className="logo" />
      <h4 className="h4-header-form">Entrez votre nouveau mot de passe</h4>
      <Formik
        initialValues={{
          pwd: "",
          newpwd: "",
        }}
        validationSchema={Yup.object({
          pwd: Yup.string().required("Champ requise"),
          newpwd: Yup.string().required("Champ requise"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          if (data.pwd === data.newpwd) {
            setSubmitting(true);
            await changePassword(data.pwd);
            setSubmitting(false);
          } else {
            setErrorsAuth(true);
          }
        }}
      >
        {({ handleChange, values, errors, isSubmitting }) => (
          <Form>
            <div className="input-wrp">
              <input
                className="input"
                name="pwd"
                id="pwd"
                type="password"
                placeholder="Mot de passe"
                value={values.pwd}
                onChange={handleChange}
              />
              <Password
                onClick={() => {
                  togglePassword("pwd");
                }}
              />

              {errors ? <div className="error">{errors.pwd}</div> : null}
            </div>
            <div className="input-wrp">
              <input
                className="input"
                name="newpwd"
                id="newpwd"
                type="password"
                placeholder="Confirmation de mot de passe"
                value={values.newpwd}
                onChange={handleChange}
              />
              <Password
                onClick={() => {
                  togglePassword("newpwd");
                }}
              />

              {errors ? <div className="error">{errors.newpwd}</div> : null}
              {errorsAuth ? (
                <div className="error">
                  error les mots de passe ne correspondent pas
                </div>
              ) : null}
            </div>
            <button type="submit">
              {isSubmitting ? "Loading..." : "Changer"}
            </button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ResetPasswordNewForm;

const Container = styled.div`
  z-index: 1;
  background: #fff;
  border-radius: 20px;
  padding: 3.5em 2em;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
    rgb(237 239 247 / 47%) 0px 6px 6px;
  height: fit-content;
  width: 100%;
  .logo {
    margin-bottom: 2em;
  }
  .h4-header-form {
    font-weight: 400;
    margin: 1em 0.25em;
    align-self: flex-start;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .input {
    background-color: #f2f2f2;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin: 0.75em 0;
  }
  .input-wrp {
    position: relative;
    width: 100%;
  }
  button {
    background: #f0f5fd;
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    margin-top: 2em;
    color: #919192;
  }
  svg {
    position: absolute;
    right: 0.75em;
    top: 1.7em;
    width: 20px;
    height: 20px;
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
  @media only screen and (max-width: 992px) {
    padding: 3.5em 1em;
  }
`;
