import React, {useContext, useEffect, useState} from "react";
import ThemeContext from "../../contexts/ThemeContext";
import styled from "styled-components";
import Spinner from "../Spinner";

const CarDetailBlock = ({ values, color, img, getModel, selectedC, rv }) => {
  const {theme} = useContext(ThemeContext);
  const imgCar = localStorage.getItem("imgCar");
  return (
    <Container color={color}>
   
      <div className="info-wrp-right">
      <img src={imgCar ? imgCar : img} className="car-icon" alt="car" />
      </div>
      <div className="details" style={{backgroundColor : theme.color}}>
      <div className="text">
          <span>Matricule : {selectedC.NumImmatri}</span>
          <span>Num de série : {values.nbr}</span>
          <span>Modèle : {rv ? selectedC.ModelVehi : getModel()}</span>
          {values.marque.length !== 0 ? (
            <span>Marque : {values.marque}</span>
        ) : null}
          </div>

      </div>
    </Container>
  );
};
export default CarDetailBlock;

const Container = styled.div`
margin-top: 30px;
width: 95%;
display: flex;
flex-direction: row;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
background-color: #fff;
box-sizing: border-box;
position: relative;
@media screen and (max-width: 620px){
  flex-direction: column;;
}
.info-wrp-right{
  flex :.5;
  display: flex;
  justify-content: center;
  align-items:center;
  padding: 10px;
  img{
    width: 85%;
    height: 100%;
  }
}
.details{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: .5;
  color :#fff;
  padding: 25px;
  border-top-left-radius: 70px;
  @media screen and (max-width: 620px){
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
}
}

span{
  display: block;
  font-size: 20px;
}

`;