import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import ThemeContext from "../contexts/ThemeContext";
import CustomHelmet from "../components/elements/CustomHelmet";
import CarInfo from "../components/CarInfo";
import CarTechniqueInfo from "../components/CarTechniqueInfo";
import Ford1 from "../assets/ford.png";
import Layout from "../layouts/DefaultLayout";
import AuthContext from "../contexts/AuthContext";
import Car from "../assets/car.png";
import Spinner from "../components/Spinner";

const VehiculeDetails = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const { uid } = useParams();
  const { REACT_APP_BACKEND_URL } = process.env;
  const [carInfo, setCarInfo] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const getBrand = async (name) => {
    try {
      const response = await fetch(`${REACT_APP_BACKEND_URL}/brands`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      console.log(result);
      if (result) {
        const r = result.filter((i) => {
          return i.brand_name.toLowerCase() === name.toLowerCase();
        });

        if (r.length === 0) {
          const t = result?.filter((i) => {
            return i.brand_name.toLowerCase() === "autohall";
          });
          const obj = {
            id: t[0]?.brand_id,
            color: t[0]?.brand_color,
            logo: t[0]?.brand_img,
            name: t[0]?.brand_name,
            slogan: t[0]?.brand_slogan,
          };
          console.log(obj);
          setTheme(obj);
          localStorage.setItem("theme", JSON.stringify(obj));
        } else {
          const obj1 = {
            id: r[0]?.brand_id,
            color: r[0]?.brand_color,
            logo: r[0]?.brand_img,
            name: r[0]?.brand_name,
            slogan: r[0]?.brand_slogan,
          };
          setTheme(obj1);
          localStorage.setItem("theme", JSON.stringify(obj1));
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getCarDt = async (id) => {
    setisLoading(true);
    try {
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/api/getClientCars/${auth?.Custno}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const result = await response.json();
      if (result?.data) {
        const r = result?.data?.data.filter((i) => {
          return i.IDVehicule === id;
        });
        console.log(r);
        setCarInfo(r);
        getBrand(r[0]?.MrqVehi);
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCarDt(uid);
  }, [auth]);

  return (
    <Layout>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          <CustomHelmet title="Voiture details" />
          {carInfo?.length !== 0 ? (
            <>
              <CarInfo
                imtr={carInfo[0]?.NumImmatri}
                Nbr={carInfo[0]?.NumSerie}
                modele={carInfo[0]?.ModelVehi}
                year={carInfo[0]?.AnneeVeh}
                status={carInfo[0]?.StatutVeh}
                img={Car}
                color={theme?.color}
              />
              <CarTechniqueInfo color={theme?.color} dt={carInfo[0]} />
            </>
          ) : null}
        </Container>
      )}
    </Layout>
  );
};

export default VehiculeDetails;

const Container = styled.div`
  .page-name {
    font-size: 18px;
    font-weight: 600;
    display: none;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
  }
  @media only screen and (max-width: 768px) {
    .page-name {
      display: block;
    }
  }
`;
