import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import CustomHelmet from "../components/elements/CustomHelmet";
import Layout from "../layouts/DefaultLayout";
import ContactHeader from "../components/ContactHeader";
import ContactForm from "../components/ContactForm";
import ThemeContext from "../contexts/ThemeContext";
import AuthContext from "../contexts/AuthContext";
import Button from "../components/elements/Button";
import DarkMap from "../components/NewMap";

const Contact = () => {
  const { theme } = useContext(ThemeContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  const [activeTab, setActiveTab] = useState(1);
  const [location, setLocation] = useState({
    Ville: "",
    Succursale: "",
  });
  const [cityList, setCityList] = useState([]);
  const [dealershipList, setDealershipList] = useState([]);

  const idbrand = 1;
  const getSuccursale = (brandID) => {
    const newArray = [];
    const newObj = new Object();
    const cityArray = [];
    const cityArray2 = [];

    fetch(`${REACT_APP_BACKEND_URL}/api/getSuccursale/${brandID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem. Status Code: " + response.status
          );
          return;
        }
        response.json().then(function (data) {
          data.data.map((item) => {
            if (!cityArray.includes(item.Ville)) {
              cityArray.push(item.Ville);
              cityArray2.push({ name: item.Ville, value: item.Ville });
            }
          });
          setLocation({ ...location, Ville: cityArray2[0].name });
          setCityList(cityArray2);
          //creating new object
          cityArray.map((item, index) => {
            newObj.city = item;
            newObj.data = [];
            data.data.map((obj, objIndex) => {
              if (item === obj.Ville) {
                newObj.data.push(obj);
              }
            });
            newArray.push(newObj);
          });
          handleData(newArray);
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleData = (data) => {
    const dealershipArray = [];
    data.map((item) => {
      item.data.map((obj) => {
        if (
          dealershipArray.indexOf({
            name: obj.Succursale,
            value: obj.adresse,
          })
        ) {
          dealershipArray.push({
            name: obj.Succursale,
            value: obj.adresse,
          });
        }
      });
    });
    setDealershipList(dealershipArray);
  };
  useEffect(() => {
    getSuccursale(idbrand);
  }, []);

  return (
    <Layout>
      <Container>
        <CustomHelmet title="Contact" />
        <h5 className="page-name">Contact</h5>
        <div className="wrp">
          {/* <ContactHeader
            location={location}
            setLocation={setLocation}
            dealershipList={dealershipList}
            cityList={cityList}
          /> */}
          {/* <div
            className={
              activeTab ? "active-data data-container" : "data-container"
            }
          >
            <div className="map-wrp">
              <DarkMap
                search="Auto Hall Agadir I"
                parent="contact"
                search={`${location.Ville} ${location.Succursale}`}
                margin="0"
              />
            </div>
            <div className="btn-wrp">
              <Button
                handleClick={() => {
                  setActiveTab ? setActiveTab(!activeTab) : null;
                }}
                title="Envoyer un message"
                type="submit"
                color="#fff"
                bg={theme?.color ? theme?.color : "#2D4185"}
              />
            </div>
          </div> */}

          <div
            className={
              !activeTab
                ? "active-data data-container"
                : "active-data data-container"
            }
          >
            <ContactForm
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              color={theme?.color}
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Contact;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .map-item {
    border-radius: 12px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
  }
  .data-container {
    display: none;
  }
  .active-data {
    display: block;
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1em;
  }
  .wrp {
    padding: 2em;
    margin: 2em 1em;
    max-width: 1100px;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
  }

  .page-name {
    font-size: 18px;
    font-weight: 600;
    display: none;
    padding-top: 0.75em;
    margin-left: 0.25em;
    margin-bottom: 1.25em;
  }
  .map-wrp {
    border-radius: 12px;
    overflow: hidden;
  }
  @media only screen and (max-width: 768px) {
    display: block;
    padding: 0.5em;
    .page-name {
      display: block;
    }
    .wrp {
      padding: 1em;
      margin: 2em 0;
    }
  }
  @media only screen and (max-width: 576px) {
    padding: 0.5em 0;
    .wrp {
      padding: 1em 0.5em;
    }
  }
`;
