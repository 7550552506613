import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Photo from "../assets/photo.png";
import { ReactComponent as Avatar } from "../assets/user.svg";
import AuthContext from "../contexts/AuthContext";

const Header = ({ setMenuIsActive, menuIsActive, path, color }) => {
  const [isActive, setIsActive] = useState(0);
  const getPath = () => {
    let title;
    if (path === "/" || path === "/dashboard") {
      title = "Dashboard";
    } else if (path === "/operation") {
      title = "Opérations";
    } else if (path === "/rendez-vous") {
      title = "Rendez-vous";
    } else if (path === "/documents") {
      title = "Documents";
    } else if (path === "/mes-voitures") {
      title = "mes voitures";
    } else if (path === "/vehicule-details") {
      title = "Vehicule details";
    } else if (path === "/espace-fidelite") {
      title = "Espace Fidélité";
    } else if (path === "/contact") {
      title = "Contact";
    } else if (path === "/parametres") {
      title = "Paramètres";
    } else if (path === "/forfait") {
      title = "Référentiel prix accessoire et titre de rechange";
    } else if (path === "/edit-rv") {
      title = "Modification rendez-vous";
    } else if (path === "/complaints") {
      title = "Espace Des Réclamations";
    } else if (path === "/add-complaints") {
      title = "Ajouter des Réclamations";
    } else if (path === "/notifications") {
      title = "Liste des Notifications";
    } else if (path === "/archive") {
      title = "Mes Documents";
    } else if (path === "/liste-operation") {
      title = "Liste des operation";
    } else if (path === "/add-archive") {
      title = "Ajouter un document";
    }

    return title;
  };
  const { auth } = useContext(AuthContext);
  const { REACT_APP_BACKEND_URL } = process.env;
  let history = useHistory();

  return (
    <Container color={color}>
      {menuIsActive ? (
        <svg
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          viewBox="0 0 24 24"
          width="32"
          className="menu-icon"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      ) : (
        <svg
          width="28"
          height="28"
          viewBox="0 0 36 21"
          fill="#fff"
          className="menu-icon"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <rect y="18" width="36" height="3" rx="1.5" fill="white" />
          <rect y="9" width="36" height="3" rx="1.5" fill="white" />
          <rect width="36" height="3" rx="1.5" fill="white" />
        </svg>
      )}

      <h5 className="header-title">{getPath()}</h5>

      <div className="header-right-side">
        <Link to="/parametres">
          <span>{Object.entries(auth).length !== 0 ? auth.fname : null}</span>
        </Link>
        <Link to="/parametres">
          <div className="photo">
            {Object.entries(auth).length !== 0 && auth.profile !== null ? (
              <img
                src={REACT_APP_BACKEND_URL + auth.profile}
                className="photo"
              />
            ) : (
              <Avatar />
            )}
          </div>
        </Link>

        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="logout"
          onClick={() => {
            localStorage.removeItem("auth");
            localStorage.removeItem("selectedCar");
            localStorage.removeItem("theme");
            localStorage.removeItem("odoValue");
            history.push("/login");
          }}
        >
          <path d="M16 2v7h-2v-5h-12v16h12v-5h2v7h-16v-20h16zm2 9v-4l6 5-6 5v-4h-10v-2h10z" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="bell"
          fill="#800080"
          onClick={() => setIsActive(!isActive)}
        >
          <path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
        </svg>

        {isActive ? (
          <div className="notifs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              className="close"
              fill="#888"
              onClick={() => setIsActive(0)}
            >
              <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
            </svg>
            <div className="notif">
              <div className="bg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  className="bell"
                  fill="rgba(128, 0, 128, 0.5)"
                >
                  <path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
                </svg>
              </div>
              <span>Nouvelle Notification Recue</span>
            </div>
            <div className="notif">
              <div className="bg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  className="bell"
                  fill="rgba(128, 0, 128, 0.5)"
                >
                  <path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
                </svg>
              </div>
              <span>Nouvelle Notification Recue</span>
            </div>
            <div className="notif">
              <div className="bg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  className="bell"
                  fill="rgba(128, 0, 128, 0.5)"
                >
                  <path d="M15.137 3.945c-.644-.374-1.042-1.07-1.041-1.82v-.003c.001-1.172-.938-2.122-2.096-2.122s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h20v-1.749c-4.877-1.591-2.195-10.594-6.863-13.306zm-3.137-2.945c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1zm3 20c0 1.598-1.392 3-2.971 3s-3.029-1.402-3.029-3h6z" />
                </svg>
              </div>
              <span>Nouvelle Notification Recue</span>
            </div>
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5em;
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #f7f8fb;

  .photo {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    cursor: pointer;
  }
  span {
    font-size: 14px;
    font-weight: 400 !important;
    margin-right: 1em;
  }
  .menu-icon {
    display: none;
  }
  svg {
    cursor: pointer;
  }
  .header-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h5 {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin-left: -0.5em;
    color: ${(props) => (props.color ? props.color : "#000")};
  }
  svg {
    margin-left: 1em;
  }
  .notifs {
    position: absolute;
    top: 70px;
    right: 2em;
    background: #fff;
    border-radius: 15px;
    box-shadow: rgb(237 239 247 / 47%) 0px 10px 20px,
      rgb(237 239 247 / 47%) 0px 6px 6px;
    padding: 0 1em;
    width: 300px;
  }
  .notif {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 1em 0;
    svg {
      margin: 0;
    }
    span {
      font-size: 13px;
      color: #444;
    }
  }
  .bg {
    border-radius: 100px;
    width: 36px;
    height: 36px;
    margin-right: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
  }
  .close {
    position: absolute;
    top: 0.8em;
    right: 0.8em;
  }
  @media only screen and (max-width: 768px) {
    background: ${(props) => (props.color ? props.color : "#111")};
    width: 100%;
    position: fixed;
    box-shadow: none;
    .menu-icon {
      display: block;
    }
    h5 {
      display: none;
    }
    span {
      color: #fff;
    }
    .logout {
      fill: #fff;
    }
  }
`;
